import React from "react";
import Navbar from "componentsWomen/Navbar.js";
import { Container, Row, Col } from "reactstrap";
import ProfilePageHeader from "componentsWomen/Headers/ProfilePageHeader.js";
// import Register from "componentsWomen/Register";
import Footer from "componentsWomen/Footer.js";
// import Sponsor from "componentsWomen/Sponsor.js";
// import { sponsorsPage } from "./content";
import { Helmet } from "react-helmet";

function RegisterPage() {
  const all_photo1 = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15]; 
  const all_photo2 = [16,17,18,19,20,21,22,23,24,25,26,27,28];
  
  const all_photo3 = [1,2,3,4,5,6,7,8,9,10,11]; 
  const all_photo4 = [12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27];    
  return (
    <>
      <Helmet>
        <meta name="description" content="Event registration form" />
      </Helmet>

      <Navbar />
      <ProfilePageHeader title="Gallery" />
      <div className="section profile-content" style={{ padding: 0 }}>
        
        
      <Container fluid>
        <Row>
          <Col md={6}>
          <iframe
            class="embed-responsive-item"
            style={{ width: '100%', minHeight: '350px' }}
            src="https://www.youtube.com/embed/74nZ00wIHOQ?autoplay=0"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen>
          </iframe>
            {all_photo1.map((single) => (
              <img className="mb-4" key={single} alt="" src={`https://ktuniexpo.com/women-directors/gallery/MEWBD_${single}.jpg`} style={{width: '100%', objectFit: 'contain' }} />
            ))}
            {all_photo3.map((single) => (
              <img className="mb-4" key={single} alt="" src={`https://ktuniexpo.com/women-directors/gallery/MEWBD_${single+28}.jpg`} style={{width: '100%', objectFit: 'contain' }} />
            ))}
            <iframe
                class="embed-responsive-item"
                style={{ width: '100%', minHeight: '350px' }}
                src="https://www.youtube.com/embed/_79AJ3vm46Y?autoplay="
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen>
            </iframe>
          </Col>
          
          <Col md={6}>
            {all_photo2.map((single) => (
              <img className="mb-4" key={single} alt="" src={`https://ktuniexpo.com/women-directors/gallery/MEWBD_${single}.jpg`} style={{width: '100%', objectFit: 'contain' }} />
            ))}
            {all_photo4.map((single) => (
              <img className="mb-4" key={single} alt="" src={`https://ktuniexpo.com/women-directors/gallery/MEWBD_${single+28}.jpg`} style={{width: '100%', objectFit: 'contain' }} />
            ))}
            
          </Col>
        
         </Row>
         </Container> 
      </div>

      <Footer />
    </>
  );
}

export default RegisterPage;
