import React from "react";
import { Container, Row, Col, Card } from "reactstrap";

function TextGrid() {
  let pageHeader = React.createRef();
  // const size = document.body.clientWidth >= 1024 || document.documentElement.clientWidth > 1024 ? true : false;
  return (
    <>
      <div
        style={{
          backgroundImage: "url(" + require("assets/images/Asset4.png") + ")",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundAttachment: "fixed",
        }}
        className="py-5"
        data-parallax={true}
        ref={pageHeader}
      >
        <Container>
          <Row>
            <Col lg={12}>
              <h1
                className="text-left section-title text-900"
                style={{ color: "#373a4a", fontSize: "60px" }}
              >
                <b>OVERVIEW</b>
              </h1>
            </Col>
            <Col lg={6} className="align-self-center">
              <h3 className="text-dark text-400">
                Hosted by Khaleej Times in partnership with Cloud4C and Oracle, this interactive
                roundtable will bring together forward-thinking CIOs, technology leaders, and
                financial industry experts to explore key areas of progress in BFSI. We shall also
                discuss success stories of financial institutions that have aced the cloud
                transformation race, risk-proof.
                <br />
                <br />
                Using the latest cloud technology tackles not only the day-to-day operations, it
                also provides customers with the assurance of how data is handled securely,
                indirectly boosting consumer confidence. A true state-of-the-art infrastructure aims
                to achieve both operational efficiency and real-life business outcomes.
              </h3>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row className="mt-5">
            {content.map((c) => (
              <Col lg={6} className="align-self-center">
                <Card className="p-3" style={{ background: "#0025c8" }}>
                  <h2 className=" text-700 mb-4" style={{ color: "white" }}>
                    {c.title}
                  </h2>
                  <h3 className="text-white text-400">{c.description}</h3>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </>
  );
}

export default TextGrid;
const content = [
  {
    title: "Banking Cloud Strategies",
    description:
      "Explore how leading banks are adopting ‘cloud-first’ and hybrid cloud strategies to enhance agility, scalability, and competitiveness in a dynamic financial landscape. What is the most suitable cloud strategy and solution to create a robust operational infrastructure?",
  },
  {
    title: "Customer-Centric Banking in the Cloud",
    description:
      "How are banks using cloud solutions to provide seamless and personalized services, creating deeper customer relationships and responding to the evolving customer expectations?",
  },
  {
    title: "Data-Driven Financial Insights",
    description:
      "How can financial institutions capitalize on Cloud4C's and Oracle's expertise in leveraging cloud platforms for data-driven insights, enabling smarter decisions, and enhanced risk management.",
  },
  {
    title: "Security and Compliance in Cloud Banking",
    description:
      "We will analyse recommendations regarding cloud security, data protection, and regulatory compliance within the context of Financial Services, ensuring that institutions remain secure while reaping the benefits of the cloud. ",
  },
];
