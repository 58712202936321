import React from "react";
import Navbar from "componentsuaeIndia/Navbar.js";
import ProfilePageHeader from "componentsuaeIndia/Headers/ProfilePageHeader.js";
import Register from "componentsuaeIndia/Register";
import Footer from "componentsuaeIndia/Footer.js";

function RegisterPage() {
  return (
    <>
      <Navbar />
      <ProfilePageHeader title="Request Brochure" bgColor="#fff" />
      <div className="section profile-content" style={{ padding: 0 }}>
        <Register type="BROCHURE" />
      </div>
      <Footer />
    </>
  );
}

export default RegisterPage;
