import React from "react";
import Navbar from "componentsMeta/Navbar.js";
import LandingPageHeader from "componentsMeta/Headers/LandingPageHeader.js";
import LandingPageHeaderMobile from "componentsMeta/Headers/LandingPageHeaderMobile.js";
import TextGrid1Mobile from "componentsMeta/TextGrid1Mobile.js";
import Footer from "componentsMeta/Footer.js";
import TextGrid from "componentsMeta/TextGrid.js";
import TextGrid1 from "componentsMeta/TextGrid1.js";
import TextGrid2 from "componentsMeta/Textgrid2.js";
import TextGrid3 from "componentsMeta/Textgrid3.js";
import Agenda from "componentsMeta/Agenda";

import WhyAttend from "componentsMeta/WhyAttend";

import SpeakerCard from "componentsMeta/SpeakerCard";
import Photos from "componentsMeta/Photos";

import { speakers, sponsorsPage } from "./content";
import Sponsor from "componentsMeta/Sponsor";

function Home() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("profile-page");
    return function cleanup() {
      document.body.classList.remove("profile-page");
    };
  });
  const size =
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024
      ? true
      : false;
  return (
    <>
      <div id="outer-container">
        <Navbar home={true} />
        <section id="home" />
        {size ? <LandingPageHeader /> : <LandingPageHeaderMobile />}
        <main id="page-wrap">
          <section id="about" />
          {size ? <TextGrid1 /> : <TextGrid1Mobile />}
          <TextGrid />
          <WhyAttend />
          <TextGrid2 />
          <TextGrid3 />
          <section id="speakers" />
          <SpeakerCard speakers={speakers} />
          <Agenda />

          <section id="partners" />
          <Sponsor sponsors={sponsorsPage} />
          <Photos />
          {/* <News news={news} /> */}
          <Footer />
        </main>
      </div>
    </>
  );
}

export default Home;
