import React from "react";

// import { Container, Row, Col, Card } from "reactstrap";

function SponsorPage({ sponsors }) {
  // const rows = [
  //   ["DIGITAL PAYMENTS PARTNER", "EXCLUSIVE ASSOCIATE PARTNER"],
  //   ["FINTECH SOLUTION PARTNER", "NETWORKING PARTNER"],
  // ];
  return (
    <>
      {/* <div
        style={{
          backgroundColor: "#624332",
        }}
        className="section section-with-space"
        data-parallax={true}
      >
        <Container>
          {rows.map((row) => (
            <Row>
              {row.map((sponsor) =>
                sponsors[sponsor].map((s) => (
                  <Col lg={s.grid || 4} className={"ml-auto mr-auto pb-3"}>
                    <h3
                      className="text-white text-400 text-uppercase text-center pb-3"
                      style={{ margin: 0, padding: 0 }}
                      dangerouslySetInnerHTML={{
                        __html: s.type,
                      }}
                    ></h3>
                    <a href={`/sponsors/${s.pageLink}`}>
                      <Card
                        className="mx-auto"
                        style={{ borderRadius: 0, maxWidth: s.size }}
                      >
                        <img
                          src={require(`assets/sponsors/${s.image}`)}
                          alt="Sponsor logo"
                          width="100%"
                        />
                      </Card>
                    </a>
                  </Col>
                ))
              )}
            </Row>
          ))}
        </Container>
      </div> */}
    </>
  );
}

export default SponsorPage;
