import React from "react";
import { Container, Row, Col } from "reactstrap";
import Slider from "react-slick";
function TextGrid() {
  let pageHeader = React.createRef();

  const settings = {
    dots: true,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    className: "p-10",
  };
  return (
    <>
      <div
        style={{
          backgroundColor: "#fff",
          padding: "6rem 0",
        }}
        // className="py-5"
        data-parallax={true}
        ref={pageHeader}
      >
        <Container>
          <Row className='justify-content-center'>
            <Col lg={12}>
              <h1 className='section-title my-4' style={{ color: "#051245" }}>
                <b>FOSTERING TIES FOR A SUSTAINABLE FUTURE</b>
              </h1>
            </Col>
            <Col lg={12}>
              <p className='text-400'>
                The traditionally strong bilateral relations between UAE and
                India have been fortified with the frequent visits by leaders of
                both countries. The momentum generated by these regular visits
                at the highest level was sustained and marked the beginning of a
                new strategic partnership between the two countries.
                <br />
                <br />
                The growing India-UAE economic and commercial relations have
                contributed to the stability and strength of a rapidly
                diversifying and deepening bilateral relationship. India-UAE
                trade was valued at US$ 180 million per annum in the 1970s;
                whereas in 2020, US$ 59 billion making UAE, India's third
                largest trading partner for the year 2019-20. The UAE’s
                investment in India is estimated to be around US $ 18 billion of
                which US 11.67 billion is FDI. Indian investments in the UAE are
                estimated to be around US $ 85 billion
                <br />
                <br />
                The UAE-India Investment Forum shall represent a new dawn in the
                multifaceted economic relationship between the harmonious
                countries. The forum, is supported by the Ministry of Economy,
                UAE and the Consulate General of India. Designed to engage and
                influence the people who matter most when it comes to making the
                real decisions.
              </p>
            </Col>
          </Row>
          <Row className='justify-content-center'>
            <Col xs={11} className='mt-5'>
              <Slider {...settings}>
                {content.map((c) => (
                  <a href={c.link} key={c.link}>
                    <img
                      src={require(`assets/banners/${c.image}`)}
                      width='100%'
                      // style={{ maxWidth: "728px" }}
                      alt=''
                    />
                  </a>
                ))}
              </Slider>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

const content = [
  // { link: "https://www.virenxia.com/", image: "main1.png" },
  // { link: "", image: "main2.jpg" },
  // { link: "https://allana.com/", image: "main3.jpg" },
  // { link: "https://www.tatacommunications.com/", image: "4.jpg" },
  // { link: "https://www.poly.com/ae/en", image: "5.jpg" },
  // { link: "https://bit.ly/2p0kYoJ", image: "6.png" },
  // { link: "https://www.teamviewer.com/tensor", image: "7.png" },
  // { link: "http://www.blueprism.com/", image: "8.png" },
];

export default TextGrid;
