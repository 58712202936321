import React from "react";
import Navbar from "componentsMeta/Navbar.js";
import ProfilePageHeader from "componentsMeta/Headers/ProfilePageHeader.js";

import Venue from "componentsMeta/Venue";

import Footer from "componentsMeta/Footer.js";

function Overview() {
  return (
    <>
      <Navbar />

      <ProfilePageHeader
        title='How to get to the UAE-India Investment Forum'
        bgColor='#fff'
      />
      <Venue />

      <Footer />
    </>
  );
}

export default Overview;
