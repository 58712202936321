export const speakers = [
  {
    name: 'HE Shamsa Saleh',
    title: 'CEO',
    company: 'Dubai Women Establishment and Secretary-General of the UAE Gender Balance Council',
    image: 'he-shamsa-saleh.jpg',
    text: [
      `HE Shamsa Saleh began her career with Dubai Women Establishment
      (DWE) in 2008, rising to the position of Director of Strategic
      Development & Corporate Development, followed by Chief Executive
      Officer in 2011. HE is also Secretary-General of the UAE Gender
      Balance Council, which was established to support the UAE’s vision of
      becoming a regional and international leader in gender equality.<br/><br/>
      Under her leadership, DWE has grown from strength-to-strength,
      launching several key projects and initiatives which support the
      Establishment's mission to enhance the crucial role of UAE women as
      contributors to their society. These include Global Women’s Forum
      Dubai, the first Women’s Forum for the Economy & Society event to be
      held in the MENA region, the Arab Women Leadership Forum, Women
      in Boards Initiative, Women Leadership Exchange Program, Emirates
      Leaders Gathering and the National Child Care Centers Project, amongst
      many more.<br/><br/>
      Prior to joining DWE, HE Shamsa Saleh served as Head of Corporate
      Planning & Projects Management – Vice President Corporate Strategy at
      Dubai International Financial Exchange (DIFX) from 2006 to 2008. HE
      also worked as Strategy and Special Project Senior Analyst, as well as
      Acting Manager in the strategy department at TECOM, from 2002 to
      2006.`
    ],
  },
  // {
  //   name: 'Dr. Jennifer Aaker',
  //   title: 'Behavioral Scientist and General Atlantic Professor',
  //   company: 'Stanford Graduate School of Business',
  //   image: 'jennifer-aaker.jpg',
  //   text: [`Behavioral Scientist and General Atlantic Professor @ Stanford GSB. She is a leading expert on how purpose and meaning shape individual choices and how technology can positively impact both human well-being and company growth. Her work has been widely published in leading scientific journals and featured in The Economist, New York Times, Wall Street Journal, and Science. A recipient of the Distinguished Scientific Achievement Award and MBA Professor of the Year, Aaker counts winning a dance-off in the early 1980s among her greatest feats.`],
  // },
  // {
  //   name: 'Naomi Bagdonas',
  //   title: 'Corporate Strategist and Executive Advisor, Lecturer',
  //   company: 'Stanford Graduate School of Business',
  //   image: 'naomi-bagdonas.jpg',
  //   text: [`Corporate Strategist and Executive Advisor, Lecturer @ Stanford GSB. She helps leaders be more creative, flexible and resilient in the face of change by facilitating interactive sessions for Fortune 100 companies and coaching executives and celebrities for appearances ranging from Saturday Night Live to the Today Show. Formally trained at the Upright Citizens Brigade Theatre, Bagdonas performs at comedy venues and teaches improv in San Francisco's county jail. Her constant stream of foster dogs describe her as gullible and full of treats.`],
  // },
  {
    name: 'Ebru Tuygun',
    title: 'Advisory Board Chairwoman',
    company: 'Middle East Women Board Of Directors',
    image: 'ebru-tuygun1.jpg',
    text: [
      `Ebru studied Political Science and International Relations at Boğaziçi University. She has Management Excellence and Leadership Excellence certificates from Harvard Business School. She was recently part of ‘High Potential Women Leaders’ program of Stanford Graduate School of Business. Ebru led Deloitte’s Corporate Marketing and Communications department in Turkey for 7 years and in 2012, joined Accenture to establish Corporate Marketing and Communications department in Turkey. She conducted flagship brand programs and led Diversity & Inclusion agenda. Broad range of her skills and expertise include Reputation Management, Strategic Marketing and Communications, Employee Engagement, Digital Marketing, Brand Strategy, and Account Based Marketing leading to business impact and driving brand awareness. In 2015, she became Regional CMO for Accenture Digital and Accenture Technology in the Middle East, Africa, Russia and Turkey. In 2017, she became the CMO for Accenture Middle East and Turkey regions. She is one of the founding members of “Women Board of Directors” in Turkey. She enabled WEConnect International Turkey Chapter – gathering women entrepreneurs with international firms, had activated the support by Accenture. She participated in Cannes Lions Creative Festival’s CMO Accelerator Program for three years consecutively since 2015; her articles on this program have been published in Campaign and Digital Age magazines. Ebru is a member of various women business associations, supports Women20 (W20) and next generation women leaders. She is selected among Top50 CMOs in Turkey, Middle East’s Women Leaders by World Women Leadership Congress and Women in Martech Vibe Awards. Ebru is married and lives in Dubai.`
    ],
  },
  {
    name: 'Bariş Karakullukçu',
    title: 'Next Generation Entrepreneurship Group President',
    company: 'Türkiye İş Bankası',
    image: 'Bariş-Karakulluk.jpg',
    text: [
      `Baris Karakullukcu is currently the President of the New Generation Entrepreneurship
      Business Line of Türkiye İş Bankası Group. She&#39;s currently fulfilling the roles of Investment
      Committee Member of the related CVCs, and board member of corporate venture building
      and digital startup subsidiaries to support the group’s entrepreneurship strategy agenda.<br /><br />
      She also has multiple NGO Board Member positions, Arena Independent Board Member role
      and advisory board member of multiple University Organizations to support organizations
      with her 25+ years of business experience and 15+ years of C-level experience at leading
      multinational companies- Accenture, IBM, Turkcell .<br /><br />
      She has been listed among the 50 Most Powerful Women in Turkey by Fortune Magazine 7
      times and was studied in an academic case in Harvard Business School as retail CEO and her
      leadership skills.`
    ],
  },
  {
    name: 'Candice D’Cruz',
    title: 'Vice President - Luxury Brands',
    company: 'Marriott International, EMEA',
    image: 'candicedcruz.jpg',
    text: [
      `Candice D’Cruz is Vice President, Brand Marketing &amp; Management, for Marriott International Luxury
      Brands across Europe, the Middle East and Africa. In this role, Candice is responsible for the company’s
      eight distinct luxury brands, including The Ritz-Carlton, Ritz-Carlton Reserve, Bulgari Hotels, St. Regis, W
      Hotels, EDITION, The Luxury Collection and JW Marriott.<br /><br />
      Candice oversees luxury brand management, innovative brand marketing and strategic growth across the
      region which currently includes 155 luxury properties, operating under seven brands. Leading a strong
      post-pandemic recovery, highlights from Marriott International’s luxury brands in the region include the
      exciting arrival of W Hotels in Italy with the opening of W Rome, the launch of The Ritz-Carlton, Amman in
      Jordan and the signing of St. Regis in London. In addition to a highly anticipated pipeline of over 90 luxury
      properties, D’Cruz is spearheading the launch of Marriott International’s eighth luxury brand debut in the
      region with the opening Nujuma, a Ritz-Carlton Reserve, slated to open off the west coast of Saudi Arabia
      in 2023 as part of The Red Sea Project.<br /><br />
      With close to two decades of international experience in the hospitality and luxury space, Candice joined
      Marriott International in 2017 to establish and oversee the luxury discipline for Middle East &amp; Africa. In
      2020 her responsibilities were expanded to also lead Europe. A brand specialist with experience in
      diverse markets such as Australia, Belgium, India, including over a decade in the Middle East alone, her
      strategic leadership plays a pivotal role in positioning Marriott International as a key luxury player across
      Europe, Middle East and Africa, beyond hospitality.<br /><br />
      Offering an unmatched understanding of the harmonious and complex relationship between luxury and
      hospitality, Candice’s Brand Management covers a broad scope including areas such as design,
      development and talent &amp; culture relating to the strength and relevance of Marriott International’s sector-
      defining luxury portfolio. On the Brand Marketing front, Candice drives marketing strategies that
      showcase the breadth and depth of the portfolio, including hotel openings and brand market debuts
      across the region, supported by intrinsically localised global blueprints and new creative campaigns that
      make an impact.<br /><br />
      Candice is a true believer that travel defines us more than our nationality and often turns to her young
      children for inspiration on how to cater to the new generations of ever-demanding luxury consumers in a
      dynamic market. Embodying Marriott’s ‘people first’ culture, Candice is resolute in supporting people by
      helping them realise their full potential, in particular women. Candice is a strong advocate of female
      leadership and the nurturing of young talent. Candice has received many accolades throughout her
      career and is currently ranked among the top 50 storytellers of the region by Forbes Middle East; in 2019,
      Candice was voted Number One in the Hotelier Middle East Power 25 Women Leaders in Hospitality.`
    ],
  },
  {
    name: 'Feyza Narli',
    title: 'General Manager',
    company: 'Manpower Group',
    image: 'feyza-narl.jpg',
    text: [
      `After graduating from Istanbul University in Department of Industrial Engineering, Feyza
      Narli successfully completed her master&#39;s degree in Department of Management Engineering
      at Istanbul Technical University.
      <br /><br />
      Then, in her career journey started with technology consultancy at Oracle Company, she
      managed the business development, strategy and consultancy teams of solutions that
      include different divisions of Corporate and Digital Transformations in Turkey and Italy at
      Innova IT Solutions and Accenture Companies as Group Manager and Director. Lastly, after
      worked as the Country Leader for Digital and CX solutions at Oracle Turkey, as of 2018, she
      started to work as Country Manager in ManpowerGroup Turkey, a new generation business
      solutions company that provides temporary or permanent personnel employment and
      human resources consultancy solutions in 80 countries around the world. She is still working
      as General Manager of ManpowerGroup Turkey. ManpowerGroup Turkey includes the brands
      of Manpower and Experis, which is responsible for IT/Digital recruitments.
      <br /><br />
      Narli, who plays an active role in civil society organization, is one of the strong supporters of
      the &quot;Learning While Working&quot; Entrepreneurship Projects organized by high school and
      university students every year. She is also a Member of the Board of Young Achievement
      Education Foundation, as well as a Board Member of ITU Alumni Association, Founder and
      Ethics Committee Member of Women in Technology Association.
      <br /><br />
      In addition, Narli is a member of the International Investors Association (YASED) and the
      Turkish Industry and Business Association (TÜSİAD), and has been among Turkey&#39;s 50 Most
      Successful Female CEOs for the last 3 years.`
    ],
  },
  {
    name: 'Golnaz Bahmanyar',
    title: 'Partner - Middle East Consumer & Retail practice',
    company: 'Egon Zehnder',
    image: 'golnaz-bahmanyar.jpg',
    text: [
      `Golnaz leads Egon Zehnder’s Consumer and Retail Practice in the Middle East. She is a trusted advisor to regional and multinational clients in consumer-focused industries, including family-owned businesses. She brings extensive experience in working with Boards and CEOs of private and publicly listed companies on their most critical leadership challenges. She guides clients on topics ranging from C-level succession planning to Board assessment and appointments, leadership assessment and development, executive search and top team effectiveness.
      <br /><br />
      Prior to joining Egon Zehnder, Golnaz was a strategy consultant with Booz & Company, based in Dubai. Before that, Golnaz was with the Richemont Group in a variety of operational, business development, and marketing roles, based in Tokyo and New York.
      <br /><br />
      Golnaz earned a Bachelor’s degree in information technology from the Tokyo Institute of Technology and an MBA from INSEAD. She is fluent in English, French, Farsi, Japanese and has working knowledge of Arabic.
      <br /><br />
      She has made Dubai her home since 2008. She lives with her husband and two daughters.`
    ],
  },
  {
    name: 'Diana Wilde',
    title: 'Co-Founder',
    company: 'Aurora50',
    image: 'diana-wilde.png',
    text: [
      `Ms Wilde is a business leader with more than 20 years’ experience in strategy and business 
      development. She co-founded Aurora50 in 2020 with Sheikha Shamma bint Sultan bin Khalifa 
      Al Nahyan with the vision of gender balance in the workplace.
      The UAE-based social enterprise’s key diversity, equity and inclusion (DEI) initiatives are:
      Pathway20, an accelerator for executive women directors; AIM, the Accelerator for Internal 
      Management; and Gateway , which gives executive women the tools to seek out their first 
      board position.
      An impact entrepreneur, Ms Wilde has substantial experience growing businesses in the 
      information services, training and events industries. 
      Ms Wilde is a business and finance graduate from the University of Westminster, and is 
      certified in IDEO’s Advanced Design Thinking. She is also a committee member for the 
      Association of Corporate Treasurers (ACT) Middle East. She has lived in the UAE since 2005.`
    ],
  },
  {
    name: 'Duncan McCulloch',
    title: 'General Manager - Middle East and Africa',
    company: 'Mars',
    image: 'duncan-mcCulloch.jpg',
    text: [
      `Duncan McCulloch is the Regional President for Mars 
      Middle East & Africa, responsible for leading the entire 
      business across the GCC markets, Sub Sahara Africa, North 
      Africa, and Levant. Duncan has worked for Mars for over 20 
      years across the Mars Chocolate, Gum, Mint, Fruity 
      Confections and Pet Care business categories holding many 
      senior positions, spanning across many geographies 
      including UK, Europe, Asia, and Middle East. In addition to 
      Duncan’s current role, he holds additional responsibilities as 
      an Inclusion & Diversity Council Member for Mars Inc. 
      globally as well as being the Project Sponsor for Mars
      Purpose Driven Business Innovation projects.`
    ],
  },
  {
    name: 'Terry Kane',
    title: 'Director - Europe,Middle East,Africa,Turkey',
    company: 'Ex-Meta',
    image: 'terry-kane.jpg',
    text: [
      `Terry is a profitability-driven commercial senior executive with 
      a love for building strategic solutions to business challenges. 
      He has over 20 years-experience in shipping highly effective strategic 
      solutions, building talent dense teams, digitally transforming organisations 
      and leaving exceptionally positive legacies with every appointment. With deep 
      experience in Tech, Strategy, Sales & Marketing Executive roles, Terry has 
      previously been a key driving force behind Meta companies regional growth 
      including Facebook, WhatsApp, Instagram, Oculus over the past 9 years and prior 
      to that, Terry led Digital Strategy for Jumeirah Group sits on multiple boards 
      and has served senior executive roles in the public, private and voluntary sectors.
      Linkedin: https://www.linkedin.com/in/terrypkane/`
    ],
  },
  {
    name: 'Wassim Karkabi',
    title: 'Managing Partner & Board Director - Middle East, Russia & Greater China',
    company: 'Stanton Chase',
    image: 'WassimKarkabi.jpg',
    text: [
      `Wassim Karkabi is a Founder, Investor, CEO & Board Director with experience in and passion for Technology, Professional Services, Leadership Growth & Business Growth. 
      He is a Managing Partner & Shareholder at Stanton Chase & Hofstede Insights, and the Founder at Fluent XP and Coaching Loft, and The Corporate Governance Institute MENA.
      He is the Chair of the Middle East Committee and a Board Member for the APAC Nominations Committee for the AESC (Association of Executive Search & Leadership Consultants).<br /><br />
      Wassim has advised a variety of international, regional and government organizations on their strategic and leadership challenges across Middle East and more specifically 
      in the Gulf countries since 1997. He coaches CEOs, C-Suite Leaders & Board Directors, on Leadership, Organizational Culture, Corporate Governance, and Performance Leadership 
      and sits on a number of boards as a Non-Executive Director and in some cases Chairs the Nominations Committee.<br /><br />
      Wassim has an Executive MBA from Hult International Business School, and a Bachelor of Science in Marketing from the University of La Verne, California. He has a Diploma 
      in Corporate Governance and Certificate in ESG for Directors from the Corporate Governance Institute. He is a Certified Expert Practitioner in Organizational Culture 
      Measurement & Transformation and is certified in a variety of assessment tools.`
    ],
  },
  {
    name: 'Nader Haffar',
    title: 'Chairman & CEO',
    company: 'KPMG Middle East',
    image: 'NaderHaffar.jpg',
    text: [
      `For the past 25 years, Nader Haffar has been on the forefront of the ever-changing and highly demanding world of management consultancy.<br /><br />
      As Chairman and CEO of KPMG in the Lower Gulf (UAE and Oman) since 2018, Nader immediately became the driving force of change within the firm.<br /><br />
      Echoing KPMG’s values of integrity and excellence, Nader introduced firm-wide policies that led to dramatic growth in the last four years with a keen 
      focus on Emiratization and Omanization, corporate social responsibility, and putting employees at the heart of the firm’s efforts.<br /><br />
      Nader’s tenure at KPMG is highlighted with the transformation of the Audit practice and significant investments in quality. Quality remains the number one 
      priority and Integrity remains at the heart of everything the firm does: delivering on the ambition to be the most trusted and trustworthy firm for clients.<br /><br />
      With KPMG’s diverse and skilled talent in mind, Nader has built trust, partnerships, and eminence for the firm. Nader’s vision and ambition to expand the firm’s services 
      to reflect the needs of the UAE market is evident through KPMG’s list of iconic clients. The firm strives to be the firm of choice for its people, partners, and clients, 
      providing service of the highest quality. Under Nader’s vision, the firm continues to inspire confidence and empower change in its clients’ businesses and make 
      an impact for the betterment of society.<br /><br />
      Nader is a firm believer that private sector plays a crucial role, alongside the leading light of the UAE Government’s green initiatives, in its responsibility 
      towards protecting environment. The massive transfer of over 900 KPMG employees to its new One Central offices contributed to a 60% decrease in energy consumption, 
      and a 35% decrease in water consumption.<br /><br />
      In March 2022, under Nader’s directives, KPMG Lower Gulf signed the UAE’s Gender Balance Council “the Pledge” to achieve 30% women representation in Management by 2025. 
      The firm has since focused on woman’s participation in decision-making roles from middle management to the highest levels of leadership. In 2019, he was recognized for his 
      influence by Forbes Middle East as one of the Top 50 Expat CEOs in the UAE.<br /><br />
      Due to his diligence and vision, Nader has been recognized as a distinguished leader in the industry and was voted Chairman of Middle East, South Asia and Caspian Region in 2021, 
      a region that covers more than 30 office locations across 20 countries and territories. The region encompasses a combined talent pool of more than 10,000 professionals and 
      associates.<br /><br />
      Nader Haffar has also led consulting practices and transformative projects across USA, Saudi Arabia, Kuwait and the UAE.<br /><br />
      He is a philanthropist and constantly gives back to the community through participation in various CSR activities such as 100 Billion Meals and the Special Olympics.<br /><br />
      In his spare time, a rare commodity since he became CEO, Nader enjoys listening to jazz and show jumping, and loves riding whenever he can.`
    ],
  },
  {
    name: 'Victoria Ivanova',
    title: 'Senior Advisor',
    company: 'Business Finland',
    image: 'VictoriaIvanova.jpg',
    text: [
      `Victoria is a seasoned professional with over 25 years of experience working for Finnish private and public sectors. She is a highly experienced business development 
      leader and strategist.<br /><br />
      In her position as a Senior Advisor in BusinessFinland, Victoria leads export promotion activities in the Gulf area, supports Finnish ecosystems in their internalization 
      efforts and develops high-level international cooperation opportunities. She mentors start-ups and is an active member of the Nordic business community. She is particularly 
      interested in future technologies and is a strong advocate for circular economy.<br /><br />
      Prior to joining Business Finland, Victoria held various managerial positions in Finnish high-tech companies. She has a broad experience, ranging from product development 
      to leading strategy development and organizational restructuring.<br /><br />
      Business Finland, <a href="http://www.businessfinland.fi/" target="_blank">www.businessfinland.fi</a>,  is agovernment organization, headquartered in Finland, responsible for innovation funding, export, investment and tourism promotion.
      `
    ],
  },
  {
    name: 'Alexis Lecanuet',
    title: 'Senior Managing Director',
    company: 'Middle East Market - Accenture',
    image: 'alexis-lecanuet.jpg',
    text: [
      `
      Alexis Lecanuet is the Regional Senior Managing Director for Accenture in the Middle East. In his role,
      he is responsible for Accenture business in the region, including defining and executing Accenture’s
      strategy, leading the Clients’ portfolio and managing the local operations.
      <br /><br />
      Previously, Alexis led and grew the company’s products portfolio in the Middle East and Turkey region
      and focused on onboarding and retaining the largest names in the retail business. During his 24 years of
      career with Accenture, he has commanded large complex transformation projects across Europe and
      MENA mostly for the consumer and retail industries.
      <br /><br />
      Alexis is a recognized Regional leader, a seasoned speaker in several conferences, ranked one amongst
      the Forbes regional “Global meets local” of top 50 leaders.
      <br /><br />
      He is a board member for the non-profit, Injaz ME.
      <br /><br />
      Alexis holds a Masters in Business Consulting from ESCP Europe Business School and a Business Finance
      degree from SKEMA Business School. He is married with four children and is currently based in the UAE.
      `
    ],
  },
  {
    name: 'Poonam Chawla',
    title: 'Co-Founder',
    company: 'Middle East Women Board of Directors',
    image: 'poonam-chawla.png',
    text: [
      ` `
    ],
  },

];

export const pastspeakers = [
  {
    name: 'HE Shamsa Saleh',
    title: 'CEO',
    company: 'Dubai Women Establishment and Secretary-General of the UAE Gender Balance Council',
    image: 'he-shamsa-saleh.jpg',
    text: [
      `HE Shamsa Saleh began her career with Dubai Women Establishment
      (DWE) in 2008, rising to the position of Director of Strategic
      Development & Corporate Development, followed by Chief Executive
      Officer in 2011. HE is also Secretary-General of the UAE Gender
      Balance Council, which was established to support the UAE’s vision of
      becoming a regional and international leader in gender equality.<br/><br/>
      Under her leadership, DWE has grown from strength-to-strength,
      launching several key projects and initiatives which support the
      Establishment's mission to enhance the crucial role of UAE women as
      contributors to their society. These include Global Women’s Forum
      Dubai, the first Women’s Forum for the Economy & Society event to be
      held in the MENA region, the Arab Women Leadership Forum, Women
      in Boards Initiative, Women Leadership Exchange Program, Emirates
      Leaders Gathering and the National Child Care Centers Project, amongst
      many more.<br/><br/>
      Prior to joining DWE, HE Shamsa Saleh served as Head of Corporate
      Planning & Projects Management – Vice President Corporate Strategy at
      Dubai International Financial Exchange (DIFX) from 2006 to 2008. HE
      also worked as Strategy and Special Project Senior Analyst, as well as
      Acting Manager in the strategy department at TECOM, from 2002 to
      2006.`
    ],
  },
  {
    name: 'Samah Mohamed Al Hajeri',
    title: 'Director of Cooperatives & Strategic Reserve',
    company: 'Ministry of Economy',
    image: 'samah-mohamed.jpg',
    text: [],
  },
  {
    name: 'Margaret Ann Neale',
    title: 'Co-Director, Executive Program in Women’s Leadership',
    company: 'Standford Business',
    image: 'margaret-ann-neale.jpg',
    text: [],
  },
  {
    name: 'Ebru Tuygun',
    title: 'Regional CMO',
    company: 'Accenture Middle East',
    image: 'ebru-tuygun.jpg',
    text: [
      `Ebru studied Political Science and International Relations at Boğaziçi University. She has Management Excellence and Leadership Excellence certificates from Harvard Business School. She was recently part of ‘High Potential Women Leaders’ program of Stanford Graduate School of Business. Ebru led Deloitte’s Corporate Marketing and Communications department in Turkey for 7 years and in 2012, joined Accenture to establish Corporate Marketing and Communications department in Turkey. She conducted flagship brand programs and led Diversity & Inclusion agenda. Broad range of her skills and expertise include Reputation Management, Strategic Marketing and Communications, Employee Engagement, Digital Marketing, Brand Strategy, and Account Based Marketing leading to business impact and driving brand awareness. In 2015, she became Regional CMO for Accenture Digital and Accenture Technology in the Middle East, Africa, Russia and Turkey. In 2017, she became the CMO for Accenture Middle East and Turkey regions. She is one of the founding members of “Women Board of Directors” in Turkey. She enabled WEConnect International Turkey Chapter – gathering women entrepreneurs with international firms, had activated the support by Accenture. She participated in Cannes Lions Creative Festival’s CMO Accelerator Program for three years consecutively since 2015; her articles on this program have been published in Campaign and Digital Age magazines. Ebru is a member of various women business associations, supports Women20 (W20) and next generation women leaders. She is selected among Top50 CMOs in Turkey, Middle East’s Women Leaders by World Women Leadership Congress and Women in Martech Vibe Awards. Ebru is married and lives in Dubai.`
    ],
  },
  {
    name: 'Lynn Al Khatib',
    title: 'Group Head of Communication',
    company: 'Chalhoub Group',
    image: 'lynn-al-khatib.jpg',
    text: [
      `Lynn Al Khatib is Group Head of Communication at
Chalhoub Group, the largest retailer and distributor of
luxury brands in the Middle East and North Africa. She
brings over 15 years of corporate and brand communication experience to her current role,
which includes building the Group’s corporate brand and communication strategy, managing
social media channels, keeping team members informed through internal communications,
crisis management, as well as managing external communications and stakeholder
relationships.<br/><br/>
        Lynn joined Chalhoub Group in 2021 after more than two years as the Global Nutrition, Heath,
and Wellness Communication Manager at Nestlé S.A, where she worked from the world’s
leading food and beverage company’s headquarters in Switzerland on bridging corporate,
scientific, and consumer information. She particularly led the development of a modular
system that allows brands to communicate their purpose and impact in society.
Lynn holds a Master-Degree in Nutrition and a Bachelor of Science Degree in Nutrition and
Dietetics from the American University of Beirut.<br/><br/>
      A proud mother of two boys, Lynn’s personal passion is to help people lead healthier and more
active lives.`
    ],
  },
  {
    name: 'Fariha Salahuddin',
    title: 'SVP, CHRO',
    company: 'PepsiCo',
    image: 'fariha-salahuddin.jpg',
    text: [
      `Fariha brings more than 25 years of global HR leadership experience to PepsiCo, working across
      emerging and developed markets in several industries. Most recently, she served as General Manager,
      HR for ICI Pakistan Limited. Prior to that, she spent 10 years with GlaxoSmithKline, where she held
      several regional and global roles, including VP, Global Talent Business Leader, Consumer Healthcare,
      based in the U.K. Fariha has also held HR leadership roles in Mölnlycke, Citibank, Unilever, and ABN
      AMRO in Sweden, Turkey, Singapore, the U.K., and Pakistan. Additionally, she is a certified professional
      coach from the iPEC coaching institute.`
    ],
  },

];

export const sponsors = [
  {
    name: 'Betty Crockers ',
    pageLink: 'Betty Crockers ',
    type: 'IFTAAR SPONSOR',
    image: 'bettycrocker.jpg',
    text: [],
    link: 'https://www.bettycrocker.com/',
    size: '100%',
    grid: 4,
  },
];

const groupBy = (key) => (array) =>
  array.reduce((objectsByKeyValue, obj) => {
    const value = obj[key];
    objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
    return objectsByKeyValue;
  }, {});

const groupByType = groupBy('type');
export const sponsorsPage = groupByType(sponsors);

export const news = [];
