import React from "react";
import Navbar from "componentsWomen/Navbar.js";
import ProfilePageHeader from "componentsWomen/Headers/ProfilePageHeader.js";
import Organizer from "componentsWomen/Organizer";
import ContactUs from "componentsWomen/ContactUs";

import Footer from "componentsWomen/Footer.js";
import { Redirect } from "react-router";
import Sponsor from "componentsWomen/Sponsor.js";
import { sponsorsPage } from "./content";
import { Helmet } from "react-helmet";

function Overview({ match }) {
  const { type } = match.params;
  if (
    !type ||
    !["show-information", "the-organiser", "contact-us"].includes(type)
  ) {
    return <Redirect to="/overview/show-information" />;
  }
  let title = "";
  let description;
  if (type === "the-organiser") {
    title = "ABOUT KHALEEJ TIMES";
    description = "About Khaleej Times, UAE's leading newspaper";
  }
  if (type === "contact-us") {
    description =
      "Contact us for exhibitor, speaker and sponsorship opportunities ";
  }
  return (
    <>
      {description && (
        <Helmet>
          <meta name="description" content={description} />
        </Helmet>
      )}
      <Navbar />

      <ProfilePageHeader title={title} bgColor="#fff" />
      <div className="section profile-content" style={{ padding: 0 }}>
        {type === "the-organiser" && <Organizer />}
        {type === "contact-us" && <ContactUs />}
      </div>
      <Sponsor sponsors={sponsorsPage} />
      <Footer />
    </>
  );
}

export default Overview;
