import React from "react";
import { Container, Row, Col, Card } from "reactstrap";

function TextGrid() {
  let pageHeader = React.createRef();
  // const size = document.body.clientWidth >= 1024 || document.documentElement.clientWidth > 1024 ? true : false;
  return (
    <>
      <div
        style={{
          backgroundImage: "url(" + require("assets/images/Asset4.png") + ")",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundAttachment: "fixed",
        }}
        className="py-5"
        data-parallax={true}
        ref={pageHeader}
      >
        <Container>
          <Row>
            <Col lg={12}>
              <h1
                className="text-left section-title text-900"
                style={{ color: "#373a4a", fontSize: "60px" }}
              >
                <b>Focus Topics</b>
              </h1>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row className="mt-5">
            {content.map((c) => (
              <Col lg={4} className="align-self-center">
                <Card
                  className="p-3 d-flex"
                  style={{ background: "#E89042", minHeight: 140, justifyContent: "center" }}
                >
                  <h2 className=" text-700 mb-4" style={{ color: "white" }}>
                    {c.title}
                  </h2>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
        <Container className="mt-5">
          <Row className="mt-5">
            <Col lg={12}>
              <h1
                className="text-left section-title text-900"
                style={{ color: "#373a4a", fontSize: "30px" }}
              >
                <b>Top challenges to be addressed for the target segment. </b>
              </h1>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row className="mt-5">
            {content1.map((c) => (
              <Col lg={4} className="align-self-center">
                <Card
                  className="p-3 d-flex"
                  style={{ background: "#E89042", minHeight: 140, justifyContent: "center" }}
                >
                  <h2 className=" text-700 mb-4" style={{ color: "white" }}>
                    {c}
                  </h2>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </>
  );
}

export default TextGrid;
const content = [
  {
    title: "Overview of the current landscape of trade finance in the UAE",
    description: "",
  },
  {
    title: "Adoption rates of digital trade finance",
    description: "",
  },
  {
    title: "Challenges and opportunities for digital trade finance growth in the UAE",
    description: "",
  },
];

const content1 = [
  "Complex Regulatory & Compliance Environment",
  "Legacy System Integration",
  "Secured, Robust, and Scalable platform Concerns.",
  "Lack of Standardization and Interoperability",
  "Change Management and Skill Gap",
];
