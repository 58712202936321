import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "reactstrap";

function TextGrid() {
  let pageHeader = React.createRef();
  const [isDesertVisible, setIsDesertVisible] = useState(false);
  const checkTime = () => {
    const now = new Date();
    const currentMinutes = now.getMinutes();
    // Check for visibility conditions
    // setIsVisible((currentMinutes >= 0 && currentMinutes < 6) || (currentMinutes >= 30 && currentMinutes < 36));
    setIsDesertVisible((currentMinutes >= 6 && currentMinutes < 16) || (currentMinutes >= 36 && currentMinutes < 47))
  };

  useEffect(() => {
    checkTime(); // Check time on mount
    // Set an interval to check every second
    const interval = setInterval(checkTime, 1000);
    return () => clearInterval(interval); // Cleanup on unmount
  }, []);

  return (
    <>
      <div
        style={{
          backgroundColor: "#fff",
        }}
        className='section'
        data-parallax={true}
        ref={pageHeader}
      >
        <Container>
          <Row>
            <Col lg={12} className="text-center">
              <h1 className='text-main text-uppercase mb-4'>
                <img
                  src={require(`assets/images/logo/khaleej-new.png`)}
                  className=""
                  width="100%"
                  style={{ maxWidth: "300px" }}
                  alt="logo"
                />
                {/* <b>About Khaleej Times Events</b> */}
              </h1>
              <h2 className='text-dark text-400 mb-0'>
                KT Events, a division of Khaleej Times, is a leading organizer of high-profile conferences, summits, and exhibitions across the Middle East. We are dedicated to creating impactful and memorable experiences that connect industry leaders, innovators, and influencers.
                {/* <br />
                <br />
                <a
                  href='https://drive.google.com/uc?id=1dl24V9XpiYYeK4mgGqUvuc1mCTghS2zC&export=download'
                  target='_blank'
                  rel='noopener noreferrer'
                  className='text-400'
                >
                  <b>Download the 2022 events calendar</b>
                </a> */}
              </h2>
            </Col>
          </Row>
        </Container>
      </div>
      {isDesertVisible && (
        <Container fluid className="p-0">
          <Row>
            <Col lg={12} className="text-center">
              <a href="https://ktdesertdrive.com/online-booking/tickets.php?refer=khaleejtimesevents" target="_BLANK" rel="noopener noreferrer">
                <img
                  src="/data/DesertDriveWebsiteBanner.webp"
                  alt=""
                  className="d-none d-md-block"
                  style={{ maxWidth: '100%', margin: '0px auto' }}
                />
                <img
                  src="/data/DesertDriveWebsiteBanner.webp"
                  alt=""
                  className="d-block d-md-none"
                  style={{ maxWidth: '100%', margin: '0px auto' }}
                />
              </a>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
}

export default TextGrid;
