import React from "react";
import {
  // Collapse,
  Container,
  Row,
  Col,
  Card,
  CardBody,
} from "reactstrap";
import ScrollAnimation from "react-animate-on-scroll";
// const size = document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024 ? true : false;
function Agenda() {
  function AgendaCard({ data }) {
    return data.map((d, i) => (
      <Col lg={12}>
        <ScrollAnimation animateIn="fadeInDown" animateOnce={true} duration={0.4}>
          <Card style={{ backgroundColor: "#373a4a", minHeight: "50px" }}>
            <CardBody>
              <Container>
                <Row>
                  <Col xs={4} className="align-self-center pr-0">
                    <h2 className="text-900" style={{ color: "#c74634" }}>
                      {d.time}
                    </h2>
                  </Col>
                  <Col xs={8} className="align-self-center ">
                    <h2 className="text-400 " style={{ color: "white" }}>
                      {d.title}
                    </h2>
                  </Col>
                </Row>
              </Container>
            </CardBody>
          </Card>
        </ScrollAnimation>
      </Col>
    ));
  }

  return (
    <>
      <div className="section" data-parallax={true}>
        <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
          <Container>
            <Row>
              <Col md="12">
                <h1
                  className="text-center section-title text-900"
                  style={{ color: "#373a4a", fontSize: "60px" }}
                >
                  <b>AGENDA</b>
                </h1>
                <h2 className="text-700 text-center mb-4 text-white">
                  Accenture Exchange Opening Ceremony
                </h2>
              </Col>
            </Row>
          </Container>
          <Container>
            <Row>
              <AgendaCard data={content1} />
            </Row>
          </Container>
        </ScrollAnimation>
      </div>
    </>
  );
}

const content1 = [
  { time: "10:00 to 10:15 am", title: "Registration & Coffee" },
  { time: "10:15 to 10:25 am", title: "Accelerating Growth with Oracle Cloud Infrastructure" },
  { time: "10:25 to 10:35 am", title: "The Key to Risk-Free Cloud Transformation" },
  { time: "10:35 to 11:05 am", title: "Mission Critical Masters" },
  { time: "11:05 to 11:25 am", title: "Networkng break" },
  { time: "11:25 to 12:40 pm", title: "Interactive Roundtable Discussion" },
  { time: "12:40 to 12:50 pm", title: "Reimagine Your Cloud Journey" },
  { time: "12:50 pm onwards", title: "Lunch" },
];

export default Agenda;
