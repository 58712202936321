import React from "react";
import { Container, Row, Col, Card } from "reactstrap";
import Judges from "./Judges";

function TextGrid() {
  let pageHeader = React.createRef();

  return (
    <>
      <div
        style={{
          backgroundColor: "#fff",
        }}
        className="section section-with-space p-0"
        data-parallax={true}
        ref={pageHeader}
      >
        <Container>
          <Row className="justify-content-center">
            <Col lg={8} className="mb-4">
              <img src={require(`assets/images/awardcx.png`)} width="100%" alt="" />
            </Col>

            <Col lg={12}>
              <div className="motto">
                <p>
                  The CX Evolve Customer Experience Awards in conjunction with the Khaleej Times is
                  dedicated to celebrating the best of Customer Experience in the GCC region. Come
                  and join a community of CX Champions who are dedicated to showcasing the very best
                  in the industry.
                  <br />
                  <br />
                  This premier Awards event has been designed specifically for Customer Experience
                  by Customer Experience professionals. All our judges are highly experienced
                  thought leaders and practitioners with significant experience in local and
                  international markets.
                  <br />
                  <br />
                  Our winners will be showcased by the Khaleej Times – the oldest and most respected
                  business media publication in the UAE and you will have the opportunity to case
                  study at our conference events.
                  <br />
                  <br />
                  Do you want to join that celebrated group of Awards Winners and be recognised for
                  outstanding achievement?
                </p>
              </div>
              <div className="container-fluid row mt-4">
                <Col lg={12}>
                  <h1
                    className="text-dark section-title pt-3"
                    style={{ borderTop: "5px solid #ec7026" }}
                  >
                    <b>Recognition Categories (Only for CX Practitioners)</b>
                  </h1>
                </Col>
                {categories.map((c) => (
                  <Col lg={6} xs={12}>
                    <Card
                      className="p-3"
                      style={{
                        border: "1px solid #e17022",
                        color: "#fff",
                        minHeight: "150px",
                      }}
                    >
                      <h2>{c.title}</h2>
                      <p>{c.description}</p>
                    </Card>
                  </Col>
                ))}
              </div>
              <div className="container-fluid row mt-4">
                <Col lg={12}>
                  <h1
                    className="text-dark section-title pt-3"
                    style={{ borderTop: "5px solid #ec7026" }}
                  >
                    <b>Recognition Categories - (Only for Solution Providers)</b>
                  </h1>
                </Col>
                {recogCategories.map((c) => (
                  <Col lg={6} xs={12}>
                    <Card
                      className="p-3"
                      style={{
                        border: "1px solid #e17022",
                        background: "",
                        color: "#fff",
                      }}
                    >
                      <h2>{c}</h2>
                    </Card>
                  </Col>
                ))}
                {/* <h2 className="text-dark">
                  Solution Providers or technology providers who would like to apply for the above
                  category of recognitions, kindly write to{" "}
                  <a href="mailto:events@khaleejtimes.com">events@khaleejtimes.com</a>. One of our
                  team members will contact you with all the details around the Recognitions.
                </h2> */}
              </div>
              {/* <div className="container-fluid row mt-4">
                <Col lg={12}>
                  <h1
                    className="text-dark section-title pt-3"
                    style={{ borderTop: "5px solid #ec7026" }}
                  >
                    <b>How to participate in CX Awards</b>
                  </h1>
                  <h2 className="text-dark">
                    To participate, Kindly register at{" "}
                    <a href="https://khaleejtimesevents.com/cx-evolve/register-awards">
                      https://khaleejtimesevents.com/cx-evolve/register-awards
                    </a>
                    <br />
                    The deadline to register and nominate for the award categories are August 10th.
                    <br />
                    You have the option to register for multiple awards. The nomination/entry fee
                    for each category of Award is AED 999.
                    <br />
                    Upon successful registration, you will receive access to the CX Digital Awards
                    Platform. There, you can find instructions and submission documentation.
                    <br />
                    The award submission process consists of two stages:
                    <br />
                    <ul>
                      <li>Submit your written entries to the portal by August 10th.</li>
                      <li>
                        Complete a virtual presentation to the Judges and Advisory Board, including
                        questions, no later than August 21st.
                      </li>
                    </ul>
                    <br />
                  </h2>
                </Col>
              </div> */}
              {/* <div className="container-fluid row mt-4">
                <Col lg={12}>
                  <h1
                    className="text-dark section-title pt-3"
                    style={{ borderTop: "5px solid #ec7026" }}
                  >
                    <b>Reminder on Key Dates</b>
                  </h1>
                  <h2 className="text-dark">
                    <ul>
                     
                      <li>
                        Award Registration/Nomination Deadline: <b>10th August 2023</b>
                      </li>
                      <li>
                        Round 1 Submission Deadline:<b> 11th August</b>
                      </li>
                      <li>
                        Round 2 Shortlist Presentations:<b> August 21st</b>
                      </li>
                      <li>
                        Announcement of Award Winners for Each category:{" "}
                        <b>24th August at CX Evolve Summit.</b>
                      </li>
                    </ul>
                  </h2>
                </Col>
              </div> */}
              {/* <div className="container-fluid row mt-4">
                <Col lg={12}>
                  <h1
                    className="text-dark section-title pt-3"
                    style={{ borderTop: "5px solid #ec7026" }}
                  >
                    <b>Sponsor the Awards:</b>
                  </h1>
                  <h2 className="text-dark">
                    Maximize your company's exposure by sponsoring this amazing networking and
                    celebratory event. Enjoy exclusive benefits and coverage in renowned
                    publications like Khaleej Times and their online portals.
                  </h2>
                </Col>
              </div> */}
              {/* <div className="container-fluid row mt-4">
                <Col lg={12}>
                  <h1
                    className="text-dark section-title pt-3"
                    style={{ borderTop: "5px solid #ec7026" }}
                  >
                    <b>Judging Criteria:</b>
                  </h1>
                  <h2 className="text-dark">
                    Our panel of esteemed judges, comprised of industry experts and thought leaders,
                    will rigorously evaluate the entries and select the winners. These judges bring
                    years of experience and expertise in the field of customer experience, ensuring
                    fair and unbiased assessments.
                    <br />
                    <br />
                    Don't miss this opportunity to gain recognition for your outstanding
                    contributions to the world of customer experience. Join us at the CX Evolve
                    Customer Experience Awards and let your success shine! Submit your application
                    by the designated deadline and double-check that all required information and
                    supporting materials are included.
                  </h2>
                </Col>
              </div> */}
              {/* <div className="container-fluid row mt-4">
                <Col lg={12}>
                  <h1
                    className="text-dark section-title pt-3"
                    style={{ borderTop: "5px solid #ec7026" }}
                  >
                    <b>Instructions to Applicants</b>
                  </h1>
                  <h2 className="text-dark">
                    <ul>
                      <li aria-level="1">
                        Carefully read and understand the award category criteria to ensure your
                        application aligns with the specific requirements.
                      </li>
                      <li aria-level="1">
                        Provide a clear and concise overview of your customer experience initiative,
                        including its goals, objectives, and target audience.
                      </li>
                      <li aria-level="1">
                        Clearly explain the approach used to implement your customer experience
                        solution, highlighting its effectiveness and feasibility.
                      </li>
                      <li aria-level="1">
                        Showcase the measurable results achieved by your initiative, such as
                        improvements in customer satisfaction, revenue growth, or enhanced loyalty.
                      </li>
                      <li aria-level="1">
                        Highlight the unique and innovative practices, features, or achievements
                        that set your initiative apart from others.
                      </li>
                      <li aria-level="1">
                        Share the lessons learned from your initiative, including any challenges
                        faced, strategies employed to overcome them, and opportunities for
                        improvement.
                      </li>
                      <li aria-level="1">
                        Include supporting evidence, such as data, testimonials, or case studies, to
                        validate the impact and success of your customer experience initiative.
                      </li>
                      <li aria-level="1">
                        Ensure your application is well-structured, organised, and easy to follow,
                        with clear headings and sections.
                      </li>
                      <li aria-level="1">
                        Pay attention to details, such as grammar, spelling, and formatting, to
                        present a professional and polished application.
                      </li>
                    </ul>
                  </h2>
                </Col>
              </div> */}
              {/* <div className="container-fluid row mt-4">
                <Col lg={12}>
                  <h1
                    className="text-dark section-title pt-3"
                    style={{ borderTop: "5px solid #ec7026" }}
                  >
                    <b>FAQs</b>
                  </h1>
                  <h2 className="text-dark">
                    <br />
                    <strong>
                      {" "}
                      Is there a fee to participate in the CX Evolve Awards Function?
                    </strong>
                    <br />
                    No. There are no fees to participate at the CX Evolve Awards Function.
                    <br />
                    <br />
                    <strong>What should I/we include in our submission?</strong>
                    <br />
                    We have provided a questionnaire for the parameters, and we encourage
                    participants to be as specific as possible when highlighting their expertise and
                    work. Detailed instructions are included in the portal. The statement must
                    provide tangible examples to support the entry. Good entries tend to focus on
                    measurable results, unless using direct client quotes. In short, facts and
                    figures are always welcome, as they can be easily measured. Attachments to
                    support the claims and numbers should be added.
                    <br />
                    <br />
                    <strong>How will I be judged?</strong>
                    <br />
                    The CX Evolve Awards will be judged by the editorial team of Khaleej Times along
                    with an Advisory Committee that will consist of senior practitioners and thought
                    leaders who have significant prior experience in awards and are well respected
                    in the profession. They will be deciding on the basis of the nominations
                    received on the CX Evolve Portal.
                    <br />
                    <br />
                    <strong>Is there a ceremony?</strong>
                    <br />
                    Yes, the CX Evolve Awards will take place on August 24, 2023, along with the CX
                    Evolve Conference.
                    <br />
                    <br />
                    <strong>Can I submit more than one entry?</strong>
                    <br />
                    Yes, if you are eligible for more than one category, you may submit the entry.
                    <br />
                    <br />
                    <strong>How can I find out more about the awards process?</strong>
                    <br />
                    If you would like any more information, please feel free to contact our Senior
                    Conference Producer, Mitsu Ruparel, at{" "}
                    <a href="mailto:mitsu@khaleejtimes.com">mitsu@khaleejtimes.com</a>
                    <br />
                    <br />
                  </h2>
                </Col>
              </div> */}
            </Col>
          </Row>
        </Container>
        <Judges />
      </div>
    </>
  );
}

export default TextGrid;

export const banking = [
  "Best Retail Bank",
  "Best Islamic Bank",
  "Best Corporate Bank",
  "Best SME Bank",
  "Best Insurance provider",
  "Middle East Best Digital Bank",
  "Best Mobile Banking App",
];
export const individual = ["Banking CEO of the Year", "Outstanding leadership Awards"];

export const techProvider = [
  "Best Digital Transformation Provider",
  "Best Mobile Banking Service Provider",
  "Best Islamic Fintech Solutions Provider",
  "Best Digital Banking Innovation Provider",
  "Best Cybersecurity Provider",
  "Best Cloud Services Provider",
  "Best Payments Solutions Provider",
  "Best Digital Transformation",
  "Best User-Experience",
  "Best Digital Banking Innovation Provider",
];

export const Investment = [
  "Best Asset Management Firm",
  "Best Private Banking Firm",
  "Best Wealth Management Firm",
  "Best Private Equity Firm",
];

export const categories = [
  {
    title: "Recognition for Best CX Strategy",
    description:
      "Honor organizations that demonstrate a clear and impactful strategy, aligning business objectives with customer-centric practices.",
  },
  {
    title: "Recognition for Best CX Culture",
    description:
      "Recognize companies that foster a customer-centric culture, where every employee is empowered to prioritize customer satisfaction.",
  },
  {
    title: "Recognition for Best Use of Customer Insight & Feedback",
    description:
      "Highlight companies that effectively gather and leverage customer insights and feedback to drive actionable improvements.",
  },
  {
    title: "Recognition for Best Customer Service",
    description:
      "Honor organizations that prioritize employee satisfaction, professional growth, work-life balance, and a positive company culture, creating an environment that fosters exceptional customer experiences.",
  },
  {
    title: "CX Professional of the Year",
    description:
      "Recognize an individual who demonstrates exceptional leadership, expertise, and contributions to the field of customer experience, inspiring others and driving significant positive impact.",
  },
  {
    title: "CX Team of the Year",
    description:
      "Celebrate a team that collaboratively and consistently delivers outstanding customer experiences, showcasing exceptional teamwork, innovation, and results.",
  },
  {
    title: "Best Sustainability & Innovation Strategy",
    description:
      "Celebrate organizations that integrate sustainable practices and innovative solutions into their customer experience strategies.",
  },
  {
    title: "Best Digital Transformation Of the Year",
    description:
      "An opportunity to showcase an organizations success in driving various digital transformation initiatives across functions and across the enterprise.",
  },
];

const recogCategories = [
  "CX Partnership of the year",
  "CX Seamless Contact Center Operations of the Year",
  "CX Innovative solution provider of the Year",
  "CX Disruptor of the year",
  "CX Intelligent Chatbot of the Year",
  "CRM Platform of the Year",
  "AI Solution of the Year",
  "Analytics Platform of the Year",
  "CX Success Story of the Year",
  "CX Transformation Programme of the Year",
];
