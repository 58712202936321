import React from "react";
import { Container, Row, Col } from "reactstrap";

function TextGrid() {
  let pageHeader = React.createRef();
  const size =
    document.body.clientWidth >= 1024 ||
    document.documentElement.clientWidth > 1024
      ? true
      : false;
  return (
    <>
      <div
        style={{
          backgroundColor: "#051245",
          height: size ? "100px" : "50px",
          // backgroundImage: "linear-gradient(to bottom, #fff, #e2f2ff)",
        }}
        className="py-2"
        data-parallax={true}
        ref={pageHeader}
      >
        <Container>
          <Row className="justify-content-center">
            <Col lg={6} xs={6} className="text-center pt-2 text-white">
              <img
                src={require(`assets/images/Image-2.png`)}
                alt={"Khaleej"}
                width="100%"
              />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default TextGrid;
