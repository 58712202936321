import React from "react";
import { Container, Row, Col } from "reactstrap";

function SpeakerModal({ speaker }) {
  if (speaker && speaker.image) {
    return (
      <>
        <Container>
          <div className="owner">
            <div className="text-center">
              <img
                alt={speaker.name}
                className="mt-3  img-no-padding img-responsive"
                src={require(`assets/speakers/${speaker.image}`)}
                style={{ maxWidth: "200px" }}
              />
            </div>
            <div className="text-center">
              <h3 className="text-dark text-600 text-uppercase mt-2 m-0 d-block px-0">
                {speaker.name}
              </h3>

              <h5 className="text-muted m-0">{speaker.title}</h5>
              <h5 className="text-dark text-600 text-uppercase m-0">{speaker.company}</h5>
              <br />
            </div>
          </div>
          <Row>
            <Col className="" md="12">
              {speaker.text &&
                speaker.text.map((t, index) => (
                  <>
                    <p
                      style={{ fontSize: "12px" }}
                      key={t}
                      dangerouslySetInnerHTML={{ __html: t }}
                    />
                    <br />
                  </>
                ))}
              {speaker.bio && (
                <>
                  <p
                    style={{ fontSize: "12px" }}
                    dangerouslySetInnerHTML={{ __html: speaker.bio }}
                  />
                  <br />
                </>
              )}
            </Col>
          </Row>
        </Container>
      </>
    );
  } else return "";
}

export default SpeakerModal;
