import React from "react";
import { Container, Row, Col } from "reactstrap";

function TextGrid() {
  const size =
    document.body.clientWidth >= 1024 ||
    document.documentElement.clientWidth > 1024
      ? true
      : false;
  return (
    <>
      <div
        className='section border-top border-bottom'
        style={{
          background: "#fbfbfb",
          alignItems: "center",
          padding: size ? "2rem 0" : "3rem 0",
        }}
      >
        <Container>
          <Row>
            <Col lg={12} className='text-dark border-right'>
              <img
                src={require(`assets/images/logo/khaleejdark.png`)}
                width='100%'
                style={{ maxWidth: "450px" }}
                alt=''
              />
              <h3 className='text-400'>
                <a href='https://www.khaleejtimes.com'>www.khaleejtimes.com</a>
                <br />
                <br />
                Galadari Printing and Publishing Co. L.L.C is the publishing
                house of one of the leading English dailies in the UAE. Khaleej
                Times is the first English newspaper to be launched in the UAE
                in 1978. The broadsheet comprises of the general news section,
                the business pages and a lively sports section. Khaleej Times is
                the highest circulated of the English language newspapers
                throughout the Gulf. It reaches out to all parts of the UAE.
                Additionally, it covers Bahrain, Oman, Kuwait, Qatar and Saudi
                Arabia through a dedicated distribution network.
                <br />
                <br />
                With a multinational readership of 450,000, Khaleej Times
                Special Reports and Supplements are regarded as part of a
                valuable service to the community. They offer advertisers an
                opportunity to promote their products and services over an
                extended time period, in a uniquely relevant editorial context
                catering to their own precise target audience.
                <br />
                <br />
                Classified is a separate tabloid of 28 to 32 pages published
                daily along with the Khaleej Times main paper. It carries local
                advertisements about Cars, Real Estate, Recruitment /
                Educational Services and Matrimonial.
                <br />
                <br />
                Wknd Magazine, brought out on Fridays along with the broadsheet,
                gives its readers a wide range of information. It discusses a
                range of topics that are of interest to men, women and children.
                Stories are a fine balance between topics and themes of local
                interest and international relevance.
                <br />
                <br />
                Khaleej Times Online is the global face of the newspaper on the
                internet. The newspaper stepped into the digital medium with the
                launch of Khaleej Times online. The online version is not just a
                transcript of the print version. It reaches out to a targeted
                readership both at home and abroad, who are interested in news
                and information both about the UAE as well as the Middle East at
                large. It has carved its own niche brand equity and is one of
                the websites in the Middle East with largest number of hits.
                Apart from focusing on news from the UAE and the Middle East, it
                also provides many value-added and interactive features which
                are exclusive to the online edition.
              </h3>
            </Col>
          </Row>
        </Container>
      </div>
      ;
    </>
  );
}

export default TextGrid;
