import React from "react";

import { Container, Row, Col } from "reactstrap";

function WhyAttend() {
  const size =
  document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024
    ? true
    : false;
  return (
    <>
      <div
        style={{
          backgroundImage: "url(" + require("assets/images/shutterstock_2070783284.jpg") + ")",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundAttachment: "fixed",
        }}
        className="section section-with-space border-top border-bottom pb-3"
        data-parallax={true}
      >
        <Container className="py-4">
          <Row>
            <Col lg={12}>
              <h1
                className=" section-title-cx pt-3 text-white meta-title text-left"
                style={{ fontSize: size ? "3rem" : "2rem" }}
              >
                <b>EVENT OBJECTIVES</b>
              </h1>
            </Col>
          </Row>
          <Row className="justify-content">
            {content3.map((data, index) => (
              <Col xs={10} key={index} className="py-3">
                <h2 className="meta-title text-white w-100">
                  <span style={{ backdropFilter: "blur(8px)" }}>
                    <i class="fa fa-bookmark mr-3" aria-hidden="true"></i>
                    {data}
                  </span>
                </h2>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </>
  );
}

const content3 = [
  "Highlight on Dubai Metaverse Strategy ",
  "future of Metaverse",
  "Investment in Metaverse",
  "Technology and Ecosystem Development ",
  "Deep Dive into government and Policy Regulations",
];

export default WhyAttend;
