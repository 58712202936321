export const speakers = [
  {
    name: 'Ritu Raj Sharma',
    title: 'VP & Head of SAP Digital, Europe',
    company: 'Infosys',
    image: 'ritu-raj.jpg',
    text: [`VP & Head of SAP Digital, Europe, Infosys`],
  },
  {
    name: 'Joseph Alenchery',
    title: 'SVP & Regional Head, Energy',
    company: 'Infosys',
    image: 'joseph-alenchery.jpg',
    text: [`SVP & Regional Head, Energy, Infosys`],
  },
  {
    name: 'Sami Shaikh',
    title: 'Customer Advisor, AWS for SAP, MENAT',
    company: 'AWS',
    image: 'Sami-Shaikh.jpeg',
    text: [`Customer Advisor, AWS for SAP, MENAT, AWS`],
  },
  {
    name: 'Prashant Gupta',
    title: 'Europe Sales Head – SAP Cloud Transformation',
    company: 'Infosys',
    image: 'Prashant-Gupta.jpg',
    text: [`Europe Sales Head – SAP Cloud Transformation, Infosys`],
  },
  {
    name: 'Satya Prathangi',
    title: 'Principal Specialist - SAP on AWS',
    company: 'AWS',
    image: 'Satya-Prathangi.png',
    text: [`Europe Sales Head - Principal Specialist - SAP on AWS, AWS`],
  },
  {
    name: 'Zafar Ahmed',
    title: 'Global Head of SAP Sales, SURE',
    company: 'Infosys',
    image: 'zafar-ahmed.jpg',
    text: [`Global Head of SAP Sales, SURE, Infosys`],
  },
  {
    name: 'Manal',
    title: 'Customer Sevice and Delivery Executive',
    company: 'SAP EMEA',
    image: 'Manal.jpeg',
    text: [`Customer Sevice and Delivery Executive - SAP EMEA`],
  },
  {
    name: 'Kumar Paramasivam',
    title: 'Vice President',
    company: 'Infosys',
    image: 'Kumar_Paramasivam.jpeg',
    text: [`Customer Sevice and Delivery Executive - Infosys`],
  },
  /* {
    name: 'Ravi Krishnan',
    title: 'Vice President',
    company: 'Infosys',
    image: 'ravi-krishnan.jpg',
    text: [`Vice President, Infosys`],
  }, */

];

export const news = [];
