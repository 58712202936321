import React from "react";
import { Container, Row, Col, Button } from "reactstrap";
import SpeakerNew from 'componentsInfosysNew/SpeakerNew.js';
import { speakers } from '../viewsInfosysNew/content';

function NewGrid() {
    let pageHeader = React.createRef();
    return (
        <>
            <div className="inner" data-parallax={true} ref={pageHeader}>
                {/* <Section01 /> */}
                <Container fluid className="p-0">
                    <Row noGutters className="align-items-center">
                        <Col lg={12}>
                            <img
                                src={require("assets/images/infosys/banner1.jpg")}
                                width="100%"
                                alt="Infosys"
                                className="sliderDiv"
                            />
                        </Col>
                    </Row>
                </Container>
                {/* <Section02 /> */}
                <section className="aboutSection sectionPadding" id="about">
                    <Container>
                        <Row className="align-items-center">
                            {/* <Col lg={6}>
                                <div className="imgBox">
                                    <img src={require("assets/images/infosys/home-img1.jpg")} className="img-fluid" alt="" />
                                </div>
                            </Col> */}
                            <Col lg={12}>
                                <div className="textBox">
                                    <p>We warmly invite you to join us as we celebrate a game-changing milestone in our journey and commitment to the Middle East market. “RISE with SAP” Enabled Digital Transformations powered by AWS.</p>
                                    <Button href="/infosys/register" className="btnInfo" style={{ marginTop: '30px' }}>
                                        Register To Attend
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
                {/* <Section03 /> */}
                <section className="whySection sectionPadding">
                    <Container>
                        <Row className="align-items-center">
                            <Col lg={12}>
                                <h1 className="sectionTitle text-white">Topics To focus On</h1>
                            </Col>
                            <Col lg={4}>
                                <div className="whyBox">
                                    <h3>Industry Trends</h3>
                                    <p>Accelerating digital transformation with RISE with SAP</p>
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="whyBox">
                                    <h3>SAP’s Perspective</h3>
                                    <p>How RISE is helping customers in their Digital transformation Journey</p>
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="whyBox">
                                    <h3>SAP Enabled</h3>
                                    <p>Digital Transformations powered by AWS</p>
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="whyBox">
                                    <h3>Partner Perspective</h3>
                                    <p>Why RISE with AWS</p>
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="whyBox">
                                    <h3>Infosys View</h3>
                                    <p>RISE Advisory Services</p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
                {/* <Section04 /> */}
                {/* <section className="keySection sectionPadding">
                    <Container>
                        <Row className="align-items-center">
                            <Col lg={7}>
                                <div className="textBox">
                                    <h1>Top 3 challenges that Infosys will be addressing the target segment.</h1>
                                    <ul>
                                        <li>Help customers in taking a data backed decision in RISE with SAP adoption.</li>
                                        <li>Help customers in demystifying RISE & GROW</li>
                                        <li>How RISE with AWS option helps customers in faster adoption of native AI/ML services from AWS.</li>
                                    </ul>
                                </div>
                            </Col>
                            <Col lg={5}>
                                <div className="imgBox">
                                    <img src={require("assets/images/infosys/home-img2.jpg")} className="img-fluid" alt="" />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section> */}
                {/* <Section05 /> */}
                <section className="agendaSection sectionPadding">
                    <Container>
                        <Row className="align-items-center">
                            <Col lg={12}>
                                <h1 className="sectionTitle">Agenda</h1>
                            </Col>
                            <Col lg={12}>
                                <div className="mainBox">
                                    <div className="table-responsive">
                                        <table className="table table-bordered">
                                            <thead className="table-dark">
                                                <tr>
                                                    <th>Time</th>
                                                    <th>Session</th>
                                                    <th>Speaker</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>10:30 - 11:00 AM</td>
                                                    <td>Welcome and Registration</td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>11:00 - 11:10 AM</td>
                                                    <td>Introductory Address by Infosys</td>
                                                    <td>Ritu Raj Sharma</td>
                                                </tr>
                                                <tr>
                                                    <td>11:10 - 11:20 AM</td>
                                                    <td>Evolving Industry Trends and The Future</td>
                                                    <td>Joseph Alenchery</td>
                                                </tr>
                                                <tr>
                                                    <td>11:20 - 11:35 AM</td>
                                                    <td>SAP's Perspective - How RISE is helping customers in their digital transformation journey</td>
                                                    <td>Manal Siddiqui</td>
                                                </tr>
                                                <tr>
                                                    <td>11:35 - 11:50 AM</td>
                                                    <td>AWS' Perspective - Why RISE with AWS</td>
                                                    <td>Sami Shaikh + Satya (AWS)</td>
                                                </tr>
                                                <tr>
                                                    <td>11:50 - 12:05 PM</td>
                                                    <td>RISE Advisory Services by Infosys</td>
                                                    <td>Prashant Gupta, Infosys</td>
                                                </tr>
                                                <tr>
                                                    <td>12:05 - 12:30 PM</td>
                                                    <td>Panel Discussion: SAP-enabled Digital Transformation Powered by AWS</td>
                                                    <td>Moderated by Ritu Raj Sharma, Infosys</td>
                                                </tr>
                                                <tr>
                                                    <td>12:30 - 12:40 PM</td>
                                                    <td>Closing Address</td>
                                                    <td>Zafar Ahmed</td>
                                                </tr>
                                                <tr>
                                                    <td>12:40 PM Onwards</td>
                                                    <td>Networking Lunch</td>
                                                    <td></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
                {/* <Section06 /> */}
                <section className="speakersSection bgColor sectionPadding" id="speakers">
                    <Container>
                        <Row className="align-items-center">
                            <Col lg={12}>
                                <h1 className="sectionTitle text-white">2024 Speakers</h1>
                            </Col>
                        </Row>
                        <Row className="align-items-center">
                            <SpeakerNew speakers={speakers} />
                        </Row>
                    </Container>
                </section>
            </div>
        </>
    );
}

export default NewGrid;
