import React from "react";

import { Container, Row, Col } from "reactstrap";

function WhyAttend() {
  const size =
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024
      ? true
      : false;
  return (
    <>
      <div
        style={{
          backgroundImage: "url(" + require("assets/images/Asset19.png") + ")",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundAttachment: "fixed",
        }}
        className="section section-with-space pb-3"
        data-parallax={true}
      >
        <Container className="py-4">
          <Row>
            <Col lg={12}>
              <h1
                className=" section-title-cx pt-3 text-white meta-title text-left"
                style={{ fontSize: size ? "3rem" : "2rem" }}
              >
                <b>
                  IMPACTFUL GATHERING
                  <br />
                  TO REALIZE THE POTENTIAL
                  <br />
                  OF METAVERSE
                </b>
              </h1>
            </Col>
          </Row>
          <Row className="justify-content-left">
            {content3.map((data, index) => (
              <Col lg={3} xs={6} className=" py-3 text-center">
                <img
                  src={require(`assets/images/icons/${data.image}`)}
                  alt="spotlight"
                  width="100"
                  style={{ minWidth: "120px" }}
                />
                <h2 className="text-400 m-0 text-center text-white meta-title text-uppercase mt-3">
                  <b>{data.title}</b>
                </h2>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </>
  );
}

const content3 = [
  { title: "Thought Leaders and Influencers", image: "Asset33.png" },
  { title: "Policymakers and Regulators", image: "Asset32.png" },
  { title: "Private Sector & Startups", image: "Asset31.png" },
  { title: "Investors and VCs", image: "Asset32.png" },
  { title: "Metaverse Enthusiasts", image: "Asset32.png" },
  { title: "Government", image: "Asset30.png" },
];

export default WhyAttend;
