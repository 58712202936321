import React from "react";
import Navbar from "componentsCX/Navbar.js";
import ProfilePageHeader from "componentsCX/Headers/ProfilePageHeader.js";
import CXEvolveKSAEdition2023 from "componentsCX/CXEvolveKSAEdition2023.js";

import Footer from "componentsCX/Footer.js";
// import Testimonials from "componentsCX/Testimonials.js";

function AgendaPage() {
  return (
    <>
      <Navbar />

      <ProfilePageHeader title="CX Evolve 2023 - KSA Edition: Contentsquare showcased their AI-powered Digital Experience Analytics platform" bgColor="#fff" />
      <div className="section profile-content" style={{ padding: 0 }}>
        <CXEvolveKSAEdition2023 />
      </div>
      {/* <Testimonials /> */}
      <Footer />
    </>
  );
}

export default AgendaPage;
