import React from "react";

import { Container, Row, Col, Card, Modal } from "reactstrap";

import ScrollAnimation from "react-animate-on-scroll";

import SpeakerModal from "componentsWomen/SpeakerModal.js";

function SpeakerGrid({ speakers, pastspeakers }) {
  // const speakersKSA = [];

  const size =
    document.body.clientWidth >= 1024 ||
    document.documentElement.clientWidth >= 1024
      ? true
      : false;
  const [open, toggleOpen] = React.useState({
    open: false,
    speaker: {},
  });
  const updateModal = (e, speaker) => {
    e.preventDefault();
    toggleOpen({ open: !open.open, speaker: speaker });
  };
  function SpeakerCard({ speaker }) {
    return (
      <a href="/" onClick={(e) => updateModal(e, speaker)}>
        <Card
          className="card-background-image mx-auto"
          style={{
            borderRadius: 0,
            minHeight: size ? "360px" : "380px",
            maxHeight: size ? "360px" : "380px",
            maxWidth: "200px",
          }}
        >
          <Container fluid>
            <Row>
              <Col xs={12} className="px-0">
                <div className="card-avatar px-3 pt-3">
                  <img
                    src={require(`assets/speakers/${speaker.image}`)}
                    width="100%"
                    alt="Portrait of speaker"
                    style={{ borderRadius: 0, maxWidth: "200px" }}
                  />
                </div>
              </Col>
              {/* {speaker.companyImage && (
                <Col xs={12} className="text-center">
                  <img
                    src={require(`assets/speakers/${speaker.companyImage}`)}
                    alt=""
                    className="p-0 border-none"
                    width="70%"
                    style={{
                      borderRadius: 0,
                    }}
                  />
                </Col>
              )} */}
              <Col xs={12}>
                <h4 className="author text-dark text-uppercase mt-2 m-0 d-block px-0">
                  {speaker.name}
                </h4>

                <small className="author text-muted m-0">{speaker.title}</small>
                <h5 className="author text-dark text-uppercase m-0 d-block px-0">
                  {speaker.company}
                </h5>
              </Col>
            </Row>
          </Container>
        </Card>
      </a>
    );
  }

  return (
    <>
      <div
        style={{
          backgroundColor: "#e0e4e5",
        }}
        className="section section-with-space"
        data-parallax={true}
      >
        <div className="filter" />
        <Container>
          <Row>
            {speakers.map((speaker, index) => (
              <Col className="ml-auto mr-auto px-1" lg={2} key={index}>
                <ScrollAnimation
                  animateIn="fadeInUp"
                  animateOnce={true}
                  duration={0.5}
                >
                  <SpeakerCard speaker={speaker} />
                </ScrollAnimation>
              </Col>
            ))}

            <Col lg={12} className="mt-5">
              <h1 className="text-dark text-center section-title">
                <b>PAST SPEAKERS</b>
              </h1>
            </Col>
            {pastspeakers.map((speaker, index) => (
              <Col className="ml-auto mr-auto px-1" lg={2} key={index}>
                <ScrollAnimation
                  animateIn="fadeInUp"
                  animateOnce={true}
                  duration={0.5}
                >
                  <SpeakerCard speaker={speaker} />
                </ScrollAnimation>
              </Col>
            ))}
          </Row>
        </Container>
        <Modal isOpen={open.open} toggle={(e) => updateModal(e)} style={{}}>
          <div className="section profile-content">
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={(e) => updateModal(e)}
            >
              <span aria-hidden={true}>×</span>
            </button>
            <SpeakerModal speaker={open.speaker} />
          </div>
        </Modal>
      </div>
    </>
  );
}

export default SpeakerGrid;
