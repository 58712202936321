import React from "react";
import Navbar from "componentsCX/Navbar.js";
import LandingPageHeader from "componentsCX/Headers/LandingPageHeader.js";
import Footer from "componentsCX/Footer.js";
import TextGrid from "componentsCX/TextGrid.js";
import TextGrid2 from "componentsCX/Textgrid2.js";
import TextGrid3 from "componentsCX/Textgrid3.js";


import WhyAttend from "componentsCX/WhyAttend";

import SpeakerCard from "componentsCX/SpeakerCard";
// import News from "componentsCX/News";
// news, speakers, sponsorsPage, sponsorsPage_2022, CurrentSpeakers
import { speakers, sponsorsPage, sponsorsPage_2022, CurrentSpeakers } from "./content";
import Sponsor from "componentsCX/Sponsor";

function Home() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("profile-page");
    return function cleanup() {
      document.body.classList.remove("profile-page");
    };
  });

  return (
    <>
      <div id="outer-container">
        <Navbar home={true} />
        <section id="home" />

        <LandingPageHeader />
        <main id="page-wrap">
          <section id="about" />
          <TextGrid />
          <section id="speakers" />
          <SpeakerCard speakers={speakers} CurrentSpeakers={CurrentSpeakers} />
          <WhyAttend />
          <TextGrid2 />
          <TextGrid3 />
          <section id="partners" />
          <Sponsor sponsors={sponsorsPage} sponsors_2022={sponsorsPage_2022} />
          {/* <News news={news} /> */}
          <Footer />
        </main>
      </div>
    </>
  );
}

export default Home;
