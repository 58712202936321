import React from "react";
import { Container, Row, Col } from "reactstrap";

function TextGrid() {
  let pageHeader = React.createRef();

  return (
    <>
      <div
        style={{
          backgroundColor: "#f3f5ff",
        }}
        className="py-5"
        data-parallax={true}
        ref={pageHeader}
      >
        <Container>
          <Row>
            <Col lg={6}>
              <h1 className="text-center text-main">
                <b>
                  Join us at the Opening Ceremony of the Accenture Exchange at
                  Expo 2020 Dubai!
                </b>
              </h1>
            </Col>
            <Col lg={6}>
              <h2 className="text-center text-main">
                <b>
                  <i className="fa fa-map-marker mr-3"></i>Accenture Exchange at
                  Expo 2020 Dubai. The Partner Hub, 5th floor, overlooking Al
                  Wasl plaza, Mobility District, Expo 2020
                </b>
              </h2>
            </Col>
          </Row>
        </Container>
      </div>
      <div
        style={{
          backgroundColor: "#fff",
        }}
        className="py-5"
        data-parallax={true}
        ref={pageHeader}
      >
        <Container>
          <Row className="justify-content-center">
            <Col lg={10}>
              <h3 className="text-dark text-400">
                <b>Connecting minds at the Accenture Exchange</b> You are
                cordially invited to the opening of our Accenture Exchange at
                Expo 2020 Dubai.
                <br />
                <br />
                <b>
                  Be among the first to explore our new Accenture Exchange
                </b>{" "}
                located at the heart of Expo 2020 Dubai. Connect with industry
                experts and network with our ecosystem partners during this
                exclusive evening.
                <br />
                <br />
                Upon registration, your invitation will include a complimentary
                ticket to enter Expo 2020 Dubai and a complimentary parking
                pass.
                <br />
                <br />
                <b>To book your complimentary ticket</b>, please provide your
                email address in the registration form below so we can send you
                your complimentary ticket to access the Expo 2020 Dubai site. If
                you would like a complimentary parking pass, please provide a
                home or office location to which we can have it delivered.
                Kindly also fill in your preferences if you would like a curated
                tour of the pavilions at the Expo 2020 Dubai site.
                <br />
                <br />
                Our space is limited due to pandemic precautions and measures
                that we have taken for your safety and health, we will accept
                RSVPs on a first come first served basis. If you miss your spot,
                don’t worry, we will have another opportunity to host you in the
                coming days and we will prioritize your request for the next
                one. Many thanks for your interest and understanding.
              </h3>
            </Col>
            {/* <Col lg={12} className="text-center">
              <Button
                className="px-3 py-2  rounded-0"
                color="info"
                style={{ minWidth: "200px" }}
                href="https://www.ktuniexpo.com/Inquiry/index/Exhibit"
              >
                <p className="m-0 text-700" style={{ color: "#fff" }}>
                  ENQUIRE NOW
                </p>
              </Button>
            </Col> */}
          </Row>
        </Container>
      </div>
    </>
  );
}

export default TextGrid;
