import React from "react";

import { Container, Row, Col, Card, Button } from "reactstrap";

function News() {
  const All_YT_Video = [
    '74nZ00wIHOQ',
    '-JtuYIlysW0',
    'aeIHoEEW1TU',
    'Q3rMlMTYfiQ',
    'NvGwBlBt2zk',
    '-h6bTv0FBNQ',
    'z3-4oYx0Jng',
    'g9IC0FsLFYw',
    'H7beLc_H3EI',
    '0_T_N8h21_8'
  ];
  const MEWBD_NEWS = [

    { url: 'https://www.khaleejtimes.com/uae/uae-four-male-ceos-awarded-for-supporting-female-colleagues', image: 'https://image.khaleejtimes.com/?uuid=c9caae80-1a70-52a7-a312-1d44ee38d401&function=fit&type=preview&source=false&q=75&maxsize=1500&scaleup=0', title: 'UAE: Four male CEOs awarded for supporting female colleagues' },

    { url: 'https://www.khaleejtimes.com/uae/uae-middle-east-women-board-of-directors-2022-leads-discussions-on-reducing-gender-gap-increasing', image: 'https://image.khaleejtimes.com/?uuid=c4f5ec28-7f76-5fb0-94fc-1e05dbe4d89b&function=cropresize&type=preview&source=false&q=75&crop_w=0.99999&crop_h=0.88343&x=0&y=0&width=1500&height=844', title: 'UAE: Middle East Women Board of Directors 2022 leads discussions on reducing gender gap, increasing representation' },

    { url: 'https://www.khaleejtimes.com/business/unlocking-the-potential-of-women-through-middle-east-women-board-of-directors-2022', image: 'https://image.khaleejtimes.com/?uuid=3f0ab93a-073f-5455-8397-5ac4456f17b9&function=fit&type=preview&source=false&q=75&maxsize=1500&scaleup=0', title: 'Unlocking the potential of Women through Middle East Women Board of Directors 2022' },

    { url: 'https://www.khaleejtimes.com/business/breaking-down-barriers-with-2022-edition-of-middle-east-women-board-of-directors', image: 'https://image.khaleejtimes.com/?uuid=2b4ac8cf-8311-5d61-b5f1-69ff02037189&function=cropresize&type=preview&source=false&q=75&crop_w=0.91632&crop_h=0.99999&x=0.04184&y=0&width=1500&height=844', title: 'Breaking down Barriers with 2022 edition of Middle East Women Board of Directors' },

    { url: 'https://www.khaleejtimes.com/uae/female-representation-on-corporate-boards-vital-for-growth-of-organisations-say-experts', image: 'https://image.khaleejtimes.com/?uuid=8b925405-c0ea-597a-b29d-fa0756c8ef04&function=cropresize&type=preview&source=false&q=75&crop_w=0.73472&crop_h=0.99999&x=0.13264&y=0&width=1500&height=844', title: 'Female representation on corporate boards vital for growth of organisations, say experts' },

    { url: 'https://www.khaleejtimes.com/uae/the-role-of-headhunters-in-increasing-the-number-of-women-in-boardrooms', image: 'https://image.khaleejtimes.com/?uuid=527604ea-b537-5a0a-ba95-4e8b20175245&function=cropresize&type=preview&source=false&q=75&crop_w=0.99999&crop_h=0.84606&x=0&y=0&width=1500&height=844', title: 'The role of headhunters in increasing the number of women in boardrooms' },

    { url: 'https://www.khaleejtimes.com/events/breaking-barriers-middle-east-women-board-of-directors-2022-offers-unique-networking-opportunity-fo', image: 'https://image.khaleejtimes.com/?uuid=6e2c8e21-cf0a-4d3e-a6e1-afb507a1b4e0&function=cropresize&type=preview&source=false&q=75&crop_w=0.99999&crop_h=0.84375&x=0&y=0&width=1500&height=844', title: 'Breaking barriers: Middle East Women Board of Directors 2022 offers unique networking opportunity for female leaders' },

    { url: 'https://www.khaleejtimes.com/business/female-leadership-to-increase-corporate-profitability', image: 'https://image.khaleejtimes.com/?uuid=1fba13ad-9fd3-5d19-b7ce-c8d6acef5d1f&function=cropresize&type=preview&source=false&q=75&crop_w=0.99999&crop_h=0.9&width=1500&height=844&x=1.0E-5&y=0.1', title: 'Female leadership to increase corporate profitability' }

  ];
  const NET_ZERO_NEWS = [

    { url: 'https://www.khaleejtimes.com/energy/net-zero-forum-in-dubai-slash-carbon-emissions-now-not-30-years-later-says-expert', image: 'https://image.khaleejtimes.com/?uuid=7af65148-8aa3-58dc-860c-2dfba4780128&function=cropresize&type=preview&source=false&q=75&crop_w=0.99999&crop_h=0.84586&width=1500&height=844&x=1.0E-5&y=0.07707', title: 'Net Zero forum in Dubai: Slash carbon emissions now, not 30 years later, says expert' },

    { url: 'https://www.khaleejtimes.com/uae/dubai-global-efforts-to-limit-global-warming-falling-behind', image: 'https://image.khaleejtimes.com/?uuid=415285a5-e3bd-5991-873c-f4174e9494f7&function=cropresize&type=preview&source=false&q=75&crop_w=0.99999&crop_h=0.84543&x=0&y=0&width=1500&height=844', title: 'Dubai takes the lead in addressing climate change' },

    { url: 'https://www.khaleejtimes.com/energy/uae-plans-to-invest-nearly-dh600-billion-in-clean-energy', image: 'https://image.khaleejtimes.com/?uuid=60538ac6-2d42-537c-ab3a-650982c7b41d&function=fit&type=preview&source=false&q=75&maxsize=1500&scaleup=0', title: 'UAE plans to invest nearly Dh600 billion in clean energy' },

    { url: 'https://www.khaleejtimes.com/environment/dubai-world-far-away-from-showing-real-progress-to-achieving-net-zero-goal-says-top-expert', image: 'https://image.khaleejtimes.com/?uuid=4fc56564-a9df-57e3-ae3e-0434f618a827&function=cropresize&type=preview&source=false&q=75&crop_w=0.99999&crop_h=0.83045&x=0&y=0&width=1500&height=844', title: 'Dubai: World far away from showing real progress to achieving net zero goal, says top expert' },

    { url: 'https://www.khaleejtimes.com/business/khaleej-times-taking-the-journey-to-net-zero', image: 'https://image.khaleejtimes.com/?uuid=51940fea-0faf-50ab-93c4-cd832dd99eb8&function=cropresize&type=preview&source=false&q=75&crop_w=0.99999&crop_h=0.85814&x=0&y=0&width=1500&height=844', title: 'Khaleej Times – Taking the Journey to Net Zero' },

    { url: 'https://www.khaleejtimes.com/environment/uae-an-aggressive-country-to-adopt-new-technologies-for-climate-change', image: 'https://image.khaleejtimes.com/?uuid=0e0fff85-0d7d-5b52-888c-3edb9b10c39d&function=cropresize&type=preview&source=false&q=75&crop_w=0.99999&crop_h=0.84606&x=0&y=0&width=1500&height=844', title: 'UAE an aggressive country to adopt new technologies for climate change' }

  ];
  const DESERT_DRIVE_NEWS = [

    { url: 'https://www.khaleejtimes.com/uae/kt-desert-drive-a-big-fun-for-off-roaders', image: 'https://image.khaleejtimes.com/?uuid=7b52a8f4-0c3a-58b3-a5b8-346c8b50d80c&function=cropresize&type=preview&source=false&q=75&crop_w=0.99999&crop_h=0.84375&x=0&y=0&width=1500&height=844', title: 'Look: 700 off-roaders experience thrill of KT Desert Drive' },

    { url: 'https://www.khaleejtimes.com/events/khaleej-times-desert-drive-a-ready-reckoner', image: 'https://image.khaleejtimes.com/?uuid=e250edf3-e2ef-58fb-ad99-7e13f0a03352&function=cropresize&type=preview&source=false&q=75&crop_w=0.99999&crop_h=0.9453&x=0&y=0&width=1500&height=844', title: 'KT Desert Drive: How to prepare for ultimate off-road experience' },

    { url: 'https://www.khaleejtimes.com/events/kt-desert-drive-dune-bashing-adventure-kicks-off-in-ras-al-khaimah', image: 'https://image.khaleejtimes.com/?uuid=d8e18ff8-ecb2-57a3-aef4-49f107f0dc5d&function=cropresize&type=preview&source=false&q=75&crop_w=0.99999&crop_h=0.74875&width=1500&height=844&x=1.0E-5&y=0.25125', title: 'KT Desert Drive: Dune-bashing adventure kicks off in Ras Al Khaimah' },

    { url: 'https://www.khaleejtimes.com/events/kt-desert-drive-7-benefits-to-venture-out-in-the-uae-and-go-off-roading-in-your-suvs', image: 'https://image.khaleejtimes.com/?uuid=eeda2be0-1a41-5183-be58-21d77a16968a&function=cropresize&type=preview&source=false&q=75&crop_w=0.99422&crop_h=0.99999&x=0.00289&y=0&width=1500&height=844', title: 'KT Desert Drive: 7 benefits to venture out in the UAE and go off-roading in your SUVs' },

    { url: 'https://www.khaleejtimes.com/events/kt-desert-drive-how-to-prepare-your-car-for-a-beautiful-off-road-experience', image: 'https://image.khaleejtimes.com/?uuid=b2a112c6-4783-5ccf-85f5-e2deaa1c383c&function=fit&type=preview&source=false&q=75&maxsize=1500&scaleup=0', title: 'KT Desert Drive: How to prepare your car for a beautiful off-road experience' },

    { url: 'https://www.khaleejtimes.com/events/khaleej-times-desert-drive-is-back-this-november', image: 'https://image.khaleejtimes.com/?uuid=7ed914ea-f9ee-50c0-9083-5af4c702398e&function=fit&type=preview&source=false&q=75&maxsize=1500&scaleup=0', title: 'Khaleej Times Desert Drive is back this November' },

    { url: 'https://www.khaleejtimes.com/uae/jeep-wrangler-is-back-to-celebrate-the-fourth-edition-of-khaleej-times-desert-drive', image: 'https://image.khaleejtimes.com/?uuid=e2d83b7a-f025-52ca-96de-f37200b870e7&function=fit&type=preview&source=false&q=75&maxsize=1500&scaleup=0', title: 'Jeep Wrangler is back to celebrate the fourth edition of Khaleej Times Desert Drive' },

    { url: 'https://www.khaleejtimes.com/events/kt-desert-drive-8-absolute-no-nos-to-reduce-the-risk-of-an-off-roading-incident-in-uae', image: 'https://image.khaleejtimes.com/?uuid=6ce71227-630a-5e9f-a031-9a114f696fa8&function=cropresize&type=preview&source=false&q=75&crop_w=0.99999&crop_h=0.9997&x=0&y=0&width=1500&height=844', title: 'KT Desert Drive: 8 absolute no-nos to reduce the risk of an off-roading incident in UAE' },

    { url: 'https://www.khaleejtimes.com/events/kt-desert-drive-pay-extra-attention-to-the-tyres-before-cruising-up-the-dunes', image: 'https://image.khaleejtimes.com/?uuid=7ed914ea-f9ee-50c0-9083-5af4c702398e&function=fit&type=preview&source=false&q=75&maxsize=1500&scaleup=0', title: 'KT Desert Drive: Pay extra attention to the tyres before cruising up the dunes' },

    { url: 'https://www.khaleejtimes.com/events/kt-desert-drive-cafu-to-power-off-roading-adventure', image: 'https://image.khaleejtimes.com/?uuid=7ed914ea-f9ee-50c0-9083-5af4c702398e&function=cropresize&type=preview&source=false&q=75&crop_w=0.99999&crop_h=0.94612&x=0&y=0&width=1500&height=844', title: 'KT Desert Drive: CAFU to power off-roading adventure' },

    { url: 'https://www.khaleejtimes.com/events/kt-desert-drive-9-suv-modification-tips-to-help-you-go-harder-faster-and-stronger', image: 'https://image.khaleejtimes.com/?uuid=dfba00d9-2cce-5f57-be64-bc8ce410d130&function=fit&type=preview&source=false&q=75&maxsize=1500&scaleup=0', title: 'KT Desert Drive: 9 SUV modification tips to help you go harder, faster and stronger' },

    { url: 'https://www.khaleejtimes.com/uae/kt-desert-drive-the-dunes-are-calling-you-and-your-4x4', image: 'https://image.khaleejtimes.com/?uuid=bb06bbb3-7bd7-5e4f-8cde-f3a930b1b078&function=fit&type=preview&source=false&q=75&maxsize=1500&scaleup=0', title: 'KT Desert Drive: The dunes are calling you... And your 4x4' },

    { url: 'https://www.khaleejtimes.com/events/in-pictures-kt-desert-drive-hits-the-dunes-of-ras-al-khaimah', image: 'https://image.khaleejtimes.com/?uuid=6462f855-66e6-5b4c-b889-77420228cf63&function=cropresize&type=preview&source=false&q=75&crop_w=0.99999&crop_h=0.84375&x=0&y=0&width=1500&height=844', title: 'In pictures: KT Desert Drive hits the dunes of Ras Al Khaimah' },

    { url: 'https://www.khaleejtimes.com/events/kt-desert-drive-off-road-gear-you-must-always-carry-for-your-suv', image: 'https://image.khaleejtimes.com/?uuid=dd12b463-3169-5f34-92ad-3a9d53569208&function=cropresize&type=preview&source=false&q=75&crop_w=0.99999&crop_h=0.90628&x=0&y=0&width=1500&height=844', title: 'KT Desert Drive: Off-road gear you must always carry for your SUV' }

  ];
  const ARTELLIGENCE_NEWS = [

    { url: 'https://www.khaleejtimes.com/events/dubai-5th-edition-of-the-artelligence-forum-begins-on-sep-20', image: 'https://image.khaleejtimes.com/?uuid=020cff14-ce0a-44f8-8212-e10387328d33&function=cropresize&type=preview&source=false&q=75&crop_w=0.99829&crop_h=0.99999&x=0.00085&y=0&width=1500&height=844', title: 'Dubai: 5th edition of the Artelligence forum begins on Sep 20' },

    { url: 'https://www.khaleejtimes.com/uae/uae-businesses-becoming-more-data-centric', image: 'https://image.khaleejtimes.com/?uuid=8e6f7a46-4901-5707-b0fa-b12123b6ab56&function=cropresize&type=preview&source=false&q=75&crop_w=0.99999&crop_h=0.75515&x=0&y=0&width=1500&height=844', title: 'UAE businesses becoming more data-centric' },

    { url: 'https://www.khaleejtimes.com/uae/uae-hpe-keen-to-build-impactful-use-cases-across-different-domains', image: 'https://image.khaleejtimes.com/?uuid=deb1fa3e-cecb-541e-96fe-333a15c6b11c&function=cropresize&type=preview&source=false&q=75&crop_w=0.99999&crop_h=0.81119&x=0&y=0&width=1500&height=844', title: 'UAE: HPE keen to build impactful use cases across different domains' },

    { url: 'https://www.khaleejtimes.com/events/ai-has-made-inroads-into-all-sectors-in-the-uae-say-experts', image: 'https://image.khaleejtimes.com/?uuid=7cab0bf7-4d79-523b-862b-a7e3bc88eae6&function=cropresize&type=preview&source=false&q=75&crop_w=0.99999&crop_h=0.98093&x=0&y=0&width=1500&height=844', title: 'AI has made inroads into all sectors in the UAE, say experts' }

  ];
  const size =
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth > 1024 ? true : false;
  return (
    <>
      <div
        style={{
          backgroundColor: "#fff",
          paddingTop: size ? "8rem" : "3rem",
        }}
        className="section"
        data-parallax={true}
      >
        <div className="overlay2 d-none d-xl-block d-lg-block " />
        <div className="overlayMedium d-none d-xl-none d-lg-none d-md-block" />
        <div className="overlayMobile d-xl-none d-lg-none d-md-none d-sm-block d-xs-block" />
        <Container>
          <Row>


            <Col md="12">
              <h1 className="text-center text-dark section-title">
                <b>Videos</b>
              </h1>
            </Col>
            {All_YT_Video.map((n, index) => (
              <Col md="4" key={index} className='mb-3'>
                <iframe
                  class="embed-responsive-item"
                  style={{ width: '100%', minHeight: '180px' }}
                  src={`https://www.youtube.com/embed/${n}?autoplay=0`}
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen>
                </iframe>
              </Col>
            ))}
            <hr style={{ clear: "both", width: '100%', borderTop: '5px dotted #1d1960' }} />


            <Col md="12">
              <h1 className="text-center text-dark section-title">
                <b>Middle East - Women Board of Directors News</b>
              </h1>
            </Col>
            {MEWBD_NEWS.map((n, index) => (
              <>
                <Col md="4" key={index} className='mb-4'>
                  <a
                    href={n.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={n.image}
                      alt="meltwater"
                      width="100%"
                      style={{ borderRadius: '10px', height: '200px', objectFit: 'cover', objectPosition: '50% 20%' }}
                    />
                    <h4 className="text-dark mt-2 mb-2">
                      {n.title}
                    </h4>
                    <Button
                      className="btn"
                      color="info"
                      size="sm"
                      outline
                      // style={{ minWidth: "100%" }}
                      type="button"
                    >
                      More
                    </Button>
                  </a>
                </Col>
              </>
            ))}

            <hr style={{ clear: "both", width: '100%', borderTop: '5px dotted #1d1960' }} />

            <Col md="12">
              <h1 className="text-center text-dark section-title">
                <b>The Journey to Net Zero News</b>
              </h1>
            </Col>
            {NET_ZERO_NEWS.map((n, index) => (
              <>
                <Col md="4" key={index} className='mb-4'>
                  <a
                    href={n.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={n.image}
                      alt="meltwater"
                      width="100%"
                      style={{ borderRadius: '10px', height: '200px', objectFit: 'cover', objectPosition: '50% 20%' }}
                    />
                    <h4 className="text-dark mt-2 mb-2">
                      {n.title}
                    </h4>
                    <Button
                      className="btn"
                      color="info"
                      size="sm"
                      outline
                      // style={{ minWidth: "100%" }}
                      type="button"
                    >
                      More
                    </Button>
                  </a>
                </Col>
              </>
            ))}

            <hr style={{ clear: "both", width: '100%', borderTop: '5px dotted #1d1960' }} />

            <Col md="12">
              <h1 className="text-center text-dark section-title">
                <b>Desert Drive News</b>
              </h1>
            </Col>
            {DESERT_DRIVE_NEWS.map((n, index) => (
              <>
                <Col md="4" key={index} className='mb-4'>
                  <a
                    href={n.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={n.image}
                      alt="meltwater"
                      width="100%"
                      style={{ borderRadius: '10px', height: '200px', objectFit: 'cover', objectPosition: '50% 20%' }}
                    />
                    <h4 className="text-dark mt-2 mb-2">
                      {n.title}
                    </h4>
                    <Button
                      className="btn"
                      color="info"
                      size="sm"
                      outline
                      // style={{ minWidth: "100%" }}
                      type="button"
                    >
                      More
                    </Button>
                  </a>
                </Col>
              </>
            ))}

            <hr style={{ clear: "both", width: '100%', borderTop: '5px dotted #1d1960' }} />

            <Col md="12">
              <h1 className="text-center text-dark section-title">
                <b>Artelligence News</b>
              </h1>
            </Col>
            {ARTELLIGENCE_NEWS.map((n, index) => (
              <>
                <Col md="4" key={index} className='mb-4'>
                  <a
                    href={n.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={n.image}
                      alt="meltwater"
                      width="100%"
                      style={{ borderRadius: '10px', height: '200px', objectFit: 'cover', objectPosition: '50% 20%' }}
                    />
                    <h4 className="text-dark mt-2 mb-2">
                      {n.title}
                    </h4>
                    <Button
                      className="btn"
                      color="info"
                      size="sm"
                      outline
                      // style={{ minWidth: "100%" }}
                      type="button"
                    >
                      More
                    </Button>
                  </a>
                </Col>
              </>
            ))}

            <hr style={{ clear: "both", width: '100%', borderTop: '5px dotted #1d1960' }} />

            <Col md="12">
              <h1 className="text-center text-dark section-title">
                <b>Industry News</b>
              </h1>
            </Col>
            {news.map((n, index) => (
              <Col lg={4}>
                <Container fluid key={index}>
                  <Row>
                    <Col lg={12}>
                      <Card>
                        <Container fluid>
                          <Row>
                            <Col lg={12} style={{ padding: 0 }} className="align-self-center">
                              {n.iframe ? (
                                <div class="embed-responsive embed-responsive-16by9">
                                  <iframe
                                    src={n.iframe}
                                    allowfullscreen
                                    frameborder={0}
                                    title="jpost"
                                  />
                                </div>
                              ) : (
                                <img
                                  src={require(`assets/images/news/${n.image}`)}
                                  alt="meltwater"
                                  width="100%"
                                  style={{ borderRadius: 0 }}
                                />
                              )}
                            </Col>
                            <Col
                              lg={12}
                              className=""
                              style={{
                                padding: "1rem",
                              }}
                            >
                              <h4 className="text-dark" style={{ margin: 0, padding: 0 }}>
                                {n.title}
                              </h4>
                              <br />
                              <h6
                                className="text-dark"
                                style={{
                                  margin: 0,
                                  padding: 0,
                                  textTransform: "none",
                                }}
                              >
                                {n.short_description}{" "}
                                <Button
                                  className="btn"
                                  color="info"
                                  size="sm"
                                  href={n.link}
                                  outline
                                  // style={{ minWidth: "100%" }}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  More
                                </Button>
                              </h6>
                            </Col>
                          </Row>
                        </Container>
                      </Card>
                    </Col>
                  </Row>
                </Container>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </>
  );
}

export default News;

const news = [

  {
    title: "Businesses are now more educated about CX, says Chalhoub Group official",
    link: "https://www.khaleejtimes.com/business/businesses-are-now-more-educated-about-cx-says-chalhoub-group-official",
    image: "42.jpeg",
  },
  {
    title: "Khaleej Times launches CX Evolve 2022 summit",
    link: "https://www.pressreader.com/uae/khaleej-times/20220709/281943136591658",
    image: "Logo-cx.png",
  },
  {
    link: "https://www.khaleejtimes.com/photos/look-adventurers-off-road-enthusiasts-buckle-up-for-kt-desert-drive",
    image: "1.jpg",
    title: "Look: Adventurers, off-road enthusiasts buckle up for KT Desert Drive",
  },
  {
    link: "https://www.khaleejtimes.com/events/kt-desert-drive-over-1400-participants-enjoy-high-octane-off-road-adventure",
    image: "2.jpg",
    title: "Look: Adventurers, off-road enthusiasts buckle up for KT Desert Drive",
  },
  {
    link: "https://www.khaleejtimes.com/uae/kt-desert-drive-5-days-to-go-before-the-much-awaited-off-road-adventure",
    image: "3.jpg",
    title: "Look: Adventurers, off-road enthusiasts buckle up for KT Desert Drive",
  },
  {
    link: "https://www.khaleejtimes.com/article/five-tips-off-roaders-should-keep-in-mind-before-heading-out",
    image: "4.jpg",
    title: "Look: Adventurers, off-road enthusiasts buckle up for KT Desert Drive",
  },
  {
    link: "https://www.khaleejtimes.com/local-events/how-to-prep-your-car-for-kt-desert-drive",
    image: "5.jpg",
    title: "Look: Adventurers, off-road enthusiasts buckle up for KT Desert Drive",
  },
  {
    link: "https://www.khaleejtimes.com/uae/kt-desert-drive-keep-your-eyes-on-the-road-your-hand-upon-the-wheel-on-nov-19",
    image: "6.jpg",
    title: "Look: Adventurers, off-road enthusiasts buckle up for KT Desert Drive",
  },
  {
    link: "https://www.khaleejtimes.com/entertainment/hurry-and-register-to-experience-the-rush-of-off-roading-at-khaleej-times-desert-drive-on-nov19th20",
    image: "7.jpg",
    title: "Look: Adventurers, off-road enthusiasts buckle up for KT Desert Drive",
  },
  {
    link: "https://www.khaleejtimes.com/article/kt-desert-drive-off-roading-with-the-right-tyres-is-more-fun",
    image: "8.jpg",
    title: "KT Desert Drive: Off-roading with the right tyres is more fun",
  },
  {
    link: "https://www.khaleejtimes.com/local-events/castrol-lubricants-partner-for-3rd-edition-of-kt-desert-drive-2",
    image: "9.jpg",
    title: "Castrol Lubricants partner for 3rd edition of KT Desert Drive",
  },
  {
    link: "https://www.khaleejtimes.com/uae/khaleej-times-desert-drive-cruises-to-ras-al-khaimah-this-november",
    image: "10.jpg",
    title: "Khaleej Times Desert Drive cruises to Ras Al Khaimah this November",
  },
  {
    link: "https://www.khaleejtimes.com/business/banks-need-to-focus-on-smart-transformation",
    image: "11.jpg",
    title: "Banks need to focus on ‘smart transformation’",
  },
  {
    link: "https://www.khaleejtimes.com/business/innovations-are-driving-banking-financial-services",
    image: "12.jpg",
    title: "Innovations are driving banking, financial services",
  },
  // {
  //   link: "https://www.khaleejtimes.com/business/dont-just-build-for-today-focus-on-tomorrow",
  //   image: "13.jpg",
  //   title: "Don’t just build for today; focus on tomorrow",
  // },
  {
    link: "https://www.khaleejtimes.com/business/cyberattacks-a-question-of-when-it-will-happen-not-if",
    image: "14.jpg",
    title: "Cyberattacks: A question of when it will happen, not if",
  },
  {
    link: "https://www.khaleejtimes.com/uae/uae-on-its-way-to-becoming-global-ai-leader-minister",
    image: "15.jpg",
    title: "UAE on its way to becoming global AI leader: Minister",
  },
  {
    link: "https://www.khaleejtimes.com/tech/artelligence-2021-align-your-data-strategy-with-your-business-goals ",
    image: "16.jpg",
    title: "Artelligence 2021: Align your data strategy with your business goals",
  },
  {
    link: "https://www.khaleejtimes.com/technology/ai-masterclass-at-kts-artelligence-forum-2021",
    image: "17.jpg",
    title: "AI Masterclass at KT’s Artelligence Forum 2021",
  },
  {
    link: "https://www.khaleejtimes.com/corporate/khaleej-times-to-host-4th-artelligence-forum-on-sept-13",
    image: "18.jpg",
    title: "Khaleej Times to host 4th Artelligence forum on Sept 13",
  },
];
