import React from "react";
import { Container, Row, Col } from "reactstrap";

function TextGrid() {
  let pageHeader = React.createRef();
  // const size = document.body.clientWidth >= 1024 || document.documentElement.clientWidth > 1024 ? true : false;
  return (
    <>
      <div
        style={{
          /* backgroundImage: "url(" + require("assets/images/Asset4.png") + ")",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundAttachment: "fixed", */
        }}
        className="section"
        data-parallax={true}
        ref={pageHeader}
      >
        <Container>
          <Row className="pb-5">
            <Col lg={12}>
              <h1 className="mb-5" style={{ color: "#0025c8", fontSize: "2.5rem" }}>
                <b>ABOUT THE ROUNDTABLE</b>
              </h1>
            </Col>
            <Col lg={7} className="align-self-center">
              <p className="text-dark text-500">
                The close-door roundtable discussion will explore the latest trends, opportunities and challenges faced by business and functional leaders when it comes to achieving their customer experience and engagement objectives.
                <br /><br />
                And how organizations can leverage some of the latest technologies and innovations, to be more operationally efficient in their customer engagement strategies.
              </p>
            </Col>
            <Col lg={5}>
              <img
                src={require("assets/images/QEF_Map_Desktop1.jpeg")}
                alt="security"
                width="100%"
              />
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <h1 className="mb-5" style={{ color: "#0025c8", fontSize: "2.5rem" }}>
                <b>KEY TOPICS OF DISCUSSION</b>
              </h1>
            </Col>
            <Col lg={12} className="align-self-center">
              <p className="text-dark text-500">
                1. <b>Create seamless and unbroken customer journeys</b> - Giving a connected channel experience to your end customers by giving all the customer facing teams complete visibility into the customer previous engagements (over every channel) in one place.
                <br /><br />
                2. <b>Achieve operational excellence and reduce overall operational expense by creating efficient customer journeys</b> for example using customer data to understand the preffered channel/time/content to be used to communicate with the customer (automated/with a human touch)
                <br /><br />
                3. <b>Help customers seamlessly engage with the modern distributed workforce</b> by upgrading to world's largest full-stack customer communication platform (Powers 70 million conversations a day worldwide with uptimes of 99.95% including both software and operator uptimes)
                <br /><br />
                4. <b>Conduct stress-free and a unbroken customer communication over reliable and scalable cloud infrastructure</b> backed by the industry's largest dedicated support and delivery teams working 24*7
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default TextGrid;
