// reactstrap componentsuaeIndia
import { Container, Col, Row } from "reactstrap";

import React from "react";

function LandingPageHeader({ home }) {
  const size =
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024
      ? true
      : false;

  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (document.documentElement.scrollTop > 50 || document.body.scrollTop > 50) {
      } else if (document.documentElement.scrollTop < 300 || document.body.scrollTop < 300) {
      }
    };

    window.addEventListener("scroll", updateNavbarColor);

    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });

  return (
    <>
      <div
        style={{
          overflow: "hidden",
          background: "#fff",
          backgroundImage: "url(" + require("assets/images/revenue-operations-concept.png") + ")",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundAttachment: "fixed",
          // backgroundImage:
          //   "linear-gradient(106deg, rgba(1,255,255,1) 0%, rgba(0,11,46,1) 59%)",
        }}
        className="page-header"
        // ref={myRef}
        data-parallax={true}
      >
        <Container fluid style={{ height: "100%" }}>
          <Row style={{ display: "flex", alignContent: "center" }}>
            {/* <Col lg={12} className="mb-3">
              <img
                src={require(`assets/images/logo/khaleej.png`)}
                style={{ maxWidth: "150px", marginLeft: "-5px" }}
                width="100%"
                alt="KhaleejTimes"
              />
            </Col> */}

            <Col
              md={4}
              xs={12}
              style={{
                background: "white",
                height: "100vh",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div>
                <div className="container-fluid row ">
                  <Col lg={12} xs={6} className="pl-1">
                    <img
                      src={require(`assets/images/Newgen Logo with tagline.png`)}
                      width="100%"
                      alt="KhaleejTimes"
                    />
                  </Col>
                </div>
                <h1
                  className="text-900   mt-1"
                  style={{ fontSize: size ? "2rem" : "2rem", color: "#373a4a" }}
                >
                  <b>Unlocking the Power of Digital Trade and Supply Chain Finance </b>
                </h1>
                <h1
                  className="text-400   mt-1"
                  style={{ fontSize: size ? "1.5rem" : "1.5rem", color: "#373a4a" }}
                >
                  Digital Trade and Supply Chain Finance
                </h1>
                <h1
                  className="text-400  "
                  style={{ fontSize: size ? "1.3rem" : "1.3rem", color: "#E89042" }}
                >
                  5<sup>th</sup> September 2024 <br /> Dubai UAE
                </h1>
              </div>
            </Col>
          </Row>
        </Container>

        <br />
      </div>
    </>
  );
}

export default LandingPageHeader;
