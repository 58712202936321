import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button } from "reactstrap";
import Card from "reactstrap/lib/Card";
import CardBody from "reactstrap/lib/CardBody";
import CardImg from "reactstrap/lib/CardImg";
import CardTitle from "reactstrap/lib/CardTitle";

function TextGrid() {
  const [comingup, setComingup] = useState([]);
  const [recent, setRecent] = useState([]);

  // Fetch data when the component mounts
  useEffect(() => {
    fetch(`https://api.khaleejtimesevents.com/events.json?v=09thOct2024${new Date()}`)
      .then((response) => response.json())
      .then((data) => {
        setComingup(data.comingup || []);
        setRecent(data.recent || []);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <>
      <div
        style={{
          background: "#2296e3",
        }}
        className="section"
        data-parallax={true}
      >
        <Container>
          <Row>
            <Col lg={12}>
              <h1 className="text-white mb-5 text-uppercase">
                <b>Coming Up</b>
              </h1>
            </Col>
          </Row>

          <Row>
            {comingup.map((c, index) => (
              <Col lg={4} key={index}>
                <a
                  href={c.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Card style={{ minHeight: "330px", marginBottom: "30px" }}>
                    <CardImg
                      alt={c.title}
                      src={c.image}
                      top
                      width="100%"
                    />
                    <CardBody>
                      <CardTitle tag="h3">
                        <center>{c.title}</center>
                      </CardTitle>
                      {/* If needed, uncomment to display the date and description */}
                      {/* 
                      <CardSubtitle className='mt-1 mb-2 text-muted text-center' tag='h3'>
                        <span dangerouslySetInnerHTML={{ __html: c.date }}></span>
                      </CardSubtitle>
                      */}
                      {/* <CardText className='text-400'>{c.description}</CardText> */}
                    </CardBody>
                  </Card>
                </a>
              </Col>
            ))}
          </Row>
        </Container>

        <Container className="mt-5">
          <Row>
            <Col lg={12}>
              <h1 className="text-white mb-5 text-uppercase">
                <b>Recent Events</b>
              </h1>
            </Col>
          </Row>
          <Row>
            {recent.map((c, index) => (
              <Col lg={4} key={index} className={index < 6 ? '' : 'd-none'}>
                <a
                  href={c.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Card style={{ minHeight: "330px", marginBottom: "30px" }}>
                    <CardImg
                      alt={c.title}
                      src={c.image}
                      top
                      width="100%"
                    />
                    <CardBody>
                      <CardTitle tag="h3">
                        <center>{c.title}</center>
                      </CardTitle>
                    </CardBody>
                  </Card>
                </a>
              </Col>
            ))}
            {recent && recent.length > 6 && (
              <Col className="text-center" lg={12}>
                <Button className="btn" color="primary" outline size="md" href="/recent">
                  Load More
                </Button>
              </Col>
            )}
          </Row>
        </Container>
      </div>
    </>
  );
}

export default TextGrid;
