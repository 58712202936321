import React, { useEffect } from "react";
import Navbar from "components/Navbar.js";

import Footer from "components/Footer.js";

import { Container, Row, Col } from "reactstrap";

function ThankYou() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Navbar />
      <div
        className="section profile-content"
        style={{ padding: 0, minHeight: "50vh", marginTop: "5rem" }}
      >
        <Container>
          <Row>
            <Col lg={12} className="text-center p-5 m-5">
              <h1>
                <>
                  <b>Thank you for registering.</b>
                  <br />
                  <br />
                  We appreciate your interest.
                  <br />
                  <br />
                  Please note that registrations are subject to approval, and you will receive a
                  confirmation from us once the verification process is complete.
                  <br />
                  <br />
                  Should you have any questions or require further assistance, please do not
                  hesitate to contact our dedicated team at events@khaleejtimes.com
                </>
              </h1>
            </Col>
          </Row>
        </Container>
      </div>

      <Footer />
    </>
  );
}

export default ThankYou;
