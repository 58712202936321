//import React from "react";
import React, { useState, useEffect } from "react";
import {
  Container,
  Collapse,
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Link } from "react-router-dom";
import classnames from "classnames";
import AnchorLink from "react-anchor-link-smooth-scroll";
// import { comingup, recent } from "./Events";

function NavbarDiv({ home }) {
  const [comingup, setComingup] = useState([]);
  const [recent, setRecent] = useState([]);
  const [navbarCollapse, setNavbarCollapse] = React.useState(false);
  const [scroll, setScroll] = React.useState(home ? true : false);

  const toggleNavbarCollapse = () => {
    setNavbarCollapse(!navbarCollapse);
    document.documentElement.classList.toggle("nav-open");
  };

  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (document.documentElement.scrollTop > 50 || document.body.scrollTop > 50) {
        setScroll(false);
      } else if (document.documentElement.scrollTop < 300 || document.body.scrollTop < 300) {
        setScroll(true);
      }
      if (!home) {
        setScroll(false);
      }
    };

    window.addEventListener("scroll", updateNavbarColor);

    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });

  // Fetch data when the component mounts
  useEffect(() => {
    fetch(`https://api.khaleejtimesevents.com/events.json?v=09thOct2024${new Date()}`)
      .then((response) => response.json())
      .then((data) => {
        setComingup(data.comingup || []);
        setRecent(data.recent || []);
      })
      .catch((err) => console.log(err));
  }, []);

  const menu = [
    { name: "HOME", link: "/", page: undefined, items: undefined, grid: 1 },
    {
      name: "EVENTS",
      link: "",
      page: "/",
      items: [...comingup, ...recent].map((event) => ({
        name: event.title,
        link: event.link,
      })),
      grid: 1,
    },
    {
      name: "SPONSOR",
      link: "/sponsor",
      page: undefined,
      items: undefined,
      grid: 1,
    },
    {
      name: "REPORTS",
      link: "/post-show-reports",
      page: undefined,
      items: undefined,
  
      grid: 1,
    },
    {
      name: "NEWS",
      link: "/news",
      page: undefined,
      items: undefined,
      grid: 1,
    },
    {
      name: "CONTACT US",
      link: "/contact-us",
      page: undefined,
      items: undefined,
      grid: 1,
    },
  ];

  return (
    <>
      <Navbar
        className={`fixed-top`}
        style={{ minHeight: "40px" }}
        color-on-scroll="300"
        expand="lg"
      >
        <Container fluid>
          <div className="navbar-translate">
            <NavbarBrand data-placement="bottom" to="/" target="_blank" className="p-0" tag={Link}>
              <img
                src={require(`assets/images/logo/khaleej-new.png`)}
                className=""
                width="100%"
                style={{ maxWidth: "200px" }}
                alt="logo"
              />
            </NavbarBrand>
            <button
              aria-expanded={navbarCollapse}
              className={classnames("navbar-toggler text-right navbar-toggler", {
                toggled: navbarCollapse,
              })}
              onClick={toggleNavbarCollapse}
            >
              <span className="navbar-toggler-bar bar1 text-white" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />
            </button>
          </div>
          <Collapse className="justify-content-end" navbar isOpen={navbarCollapse}>
            <Nav navbar style={{ zIndex: 1000000 }}>
              <NavItem className="d-flex align-middle">
                {social.map((s) => (
                  <a
                    key={s.name}
                    className={`btn-primary pt-4 px-2`}
                    style={{
                      color: scroll ? "#1d1960" : "#1d1960",
                      backgroundColor: "transparent",
                    }}
                    href={s.link}
                  >
                    <i className={`fa fa-${s.name}`} style={{ fontSize: "1.5rem" }} />
                  </a>
                ))}
              </NavItem>
              {menu.map((link, i) =>
                !link.items ? (
                  <NavItem key={i} className="">
                    {window.location.pathname === "/" && link.page === "/" ? (
                      <AnchorLink
                        offset="100"
                        href={`#${link.link}`}
                        onClick={toggleNavbarCollapse}
                        className="nav-link navbar-item-custom text-600 border-left"
                        style={{ color: scroll ? "#1d1960" : "#1d1960" }}
                      >
                        {link.name}
                        <hr className="m-0 mt-1 p-0" align="center" />
                      </AnchorLink>
                    ) : link.page ? (
                      <a
                        href={`/#${link.link}`}
                        className="nav-link navbar-item-custom text-600 border-left"
                        style={{ color: scroll ? "#1d1960" : "#1d1960" }}
                      >
                        {link.name}
                        <hr className="m-0 mt-1 p-0" align="center" />
                      </a>
                    ) : (
                      <a
                        href={`${link.link}`}
                        className="nav-link navbar-item-custom text-600 border-left"
                        style={{ color: scroll ? "#1d1960" : "#1d1960" }}
                      >
                        {link.name}
                        <hr className="m-0 mt-1 p-0" align="center" />
                      </a>
                    )}
                  </NavItem>
                ) : (
                  <UncontrolledDropdown nav inNavbar key={i}>
                    <DropdownToggle
                      nav
                      caret
                      className="navbar-item-custom text-600 border-left"
                      style={{ color: scroll ? "#1d1960" : "#1d1960", }}
                    >
                      {link.name}
                    </DropdownToggle>
                    <DropdownMenu right style={{ height: '80vh', overflow: 'auto' }}>
                      {link.items.map((i) => (
                        <DropdownItem
                          href={i.link}
                          key={i.link}
                          className="navbar-item-custom text-400"
                        >
                          {i.name}
                        </DropdownItem>
                      ))}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                )
              )}
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default NavbarDiv;

const social = [
  {
    name: "facebook",
    link: "https://www.facebook.com/khaleejtimes/?locale=en_US",
  },
  {
    name: "linkedin",
    link: "https://www.linkedin.com/company/khaleejtimes/mycompany/?viewAsMember=true",
  },
  {
    name: "twitter",
    link: "https://twitter.com/khaleejtimes",
  },
  { name: "instagram", link: "https://www.instagram.com/khaleejtimes/" },
  {
    name: "youtube",
    link: "https://www.youtube.com/c/khaleejtimes",
  },
];
