import React from "react";
import Navbar from "componentsCX/Navbar.js";
import ProfilePageHeader from "componentsCX/Headers/ProfilePageHeader.js";
import PastSponsorPage from "componentsCX/PastSponsorPage";
import Footer from "componentsCX/Footer.js";
import { sponsorsPage_2022 } from "./content";

function RegisterPage() {
  return (
    <>
      <Navbar />
      <ProfilePageHeader title="PAST SPONSORS" bgColor="#fff" />
      <PastSponsorPage sponsors={sponsorsPage_2022} />
      <Footer />
    </>
  );
}

export default RegisterPage;
