import React from "react";
import Navbar from "componentsWomen/Navbar.js";
import ProfilePageHeader from "componentsWomen/Headers/ProfilePageHeader.js";
import SpeakerCardAll from "componentsWomen/SpeakerCardAll";
import { pastspeakers, speakers } from "./content";
import Footer from "componentsWomen/Footer.js";
import Sponsor from "componentsWomen/Sponsor.js";
import { sponsorsPage } from "./content";
function SponsorPage() {
  return (
    <>
      <Navbar />
      <ProfilePageHeader title="2021 SPEAKERS" bgColor="#fff" />
      <div className="section profile-content" style={{ padding: 0 }}>
        <SpeakerCardAll speakers={speakers} pastspeakers={pastspeakers} />
      </div>
      <Sponsor sponsors={sponsorsPage} />
      <Footer />
    </>
  );
}

export default SponsorPage;
