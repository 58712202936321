import React from 'react';
import {
  Col,
  Container,
  // Collapse
  Row,
  TabContent,
  TabPane,
} from 'reactstrap';
// import Button from 'reactstrap/lib/Button';

function Agenda() {
  const [activeTab] = React.useState('1');
  // const toggle = (tab) => {
  //   if (activeTab !== tab) {
  //     setActiveTab(tab);
  //   }
  // };

  function AgendaCard({ data }) {
    return data.map((d, index) => (
      <Container className="p-4 border-bottom">
        <Row>
          <Col lg={2} className="align-self-center">
            <h3 className="text-400 mb-0 text-white">
              <b>{d.time}</b>
            </h3>
          </Col>
          <Col lg={10} className="align-self-center text-white">
            <h2 className="text-400 m-0">{d.title}</h2>
            {d.text &&
              d.text.map((t) => (
                <h3 className="text-400 m-0 mt-1" dangerouslySetInnerHTML={{ __html: t }} />
              ))}
          </Col>
        </Row>
      </Container>
    ));
  }

  return (
    <>
      <div
        style={{
          backgroundColor: '#000b2e',
        }}
        className="section"
        data-parallax={true}
      >
        <Container>
          <Row>
            <Col lg={3}>
              <h1
                className="text-white section-title pt-3"
                style={{ borderTop: '5px solid #01b0ff' }}
              >
                <b>AGENDA</b>
              </h1>
              <h2 className="text-700 text-white">(Gulf Standard Time)</h2>
            </Col>
            <Col md="9">
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  <Container>
                    <Row>
                      <Col lg={12} className="px-0">
                        <AgendaCard data={content1} />
                      </Col>
                    </Row>
                  </Container>
                </TabPane>
              </TabContent>
            </Col>
            {/* <Col lg={12} className="align-self-center text-center mt-3">
              <Button
                className="px-3 py-2 rounded-0"
                color="info"
                style={{ minWidth: '200px' }}
                href="/uae-india-investment-forum/brochure"
              >
                <p className="m-0 text-700" style={{ color: '#fff' }}>
                  Request Full Agenda
                </p>
              </Button>
            </Col> */}
          </Row>
        </Container>
      </div>
    </>
  );
}

const content1 = [
  {
    time: '09.00',
    title: 'Registrations and Networking',
  },

  {
    time: '09:30',
    title: 'Welcome',
    text: [`Vinay Kamat, Editor-in-Chief, Khaleej Times`],
  },

  {
    time: '09.40',
    title: 'Welcome Note',
    text: [`Dr. Aman Puri, Consul General, Government of India    `],
  },

  {
    time: '09.50',
    title: 'Opening Keynote Address',
    text: [
      `His Excellency Dr. Thani Bin Ahmed Al Zeyoudi, Minister of State for Foreign Trade, Ministry of Economy, UAE`,
    ],
  },
  {
    time: '10.00',
    title: 'Bilateral Trade between UAE and India',
    text: [
      `His Excellency Dr. Ahmed Abdul Rahman Albanna, UAE’s Ambassador to India, Ministry of Foreign Affairs, UAE`,
    ],
  },
  {
    time: '10.10',
    title: 'In conversation',
    text: [
      `His Excellency Sunjay Sudhir, Ambassador of India to the UAE, Government of India<br/>Anjana Sankar, Khaleej Times`,
    ],
  },

  {
    time: '10.20',
    title: 'Opening Case Study: Zooming in on the Start-up Ecosystem',
    text: [
      `Abhinav Gupta, Chief Executive Officer, CARS24, Gulf
      `,
    ],
  },
  {
    time: '10.30',
    title: 'Panel Discussion: Step-up, Start up',
    text: [
      `
      Panelists<br/>
Abhinav Gupta, Chief Executive Officer, CARS24, Gulf Neeraj Gupta, Chief Executive Officer, Policybazaar.ae, UAE Nishant Pitti, Co-founder, EaseMyTrip<br/>
Shradha Sharma, Founder, YourStory, India<br/>
Sujeet Kumar, Co – Founder, Udaan, India<br/><br/>
Moderator<br/>
Eileen Pryer, Director, University College Birmingham
      `,
    ],
  },
  {
    time: '11.10',
    title: 'Ease of Doing Business & FDI Investments in India',
    text: [`Manmeet Nanda, Joint Secretary, Department for Promotion of Industry & Internal Trade`],
  },
  {
    time: '11.20',
    title: 'Networking Coffee Break',
    text: [],
  },
  {
    time: '11.50',
    title: 'Panel Discussion: Brilliance of CEPA',
    text: [
      `
      Speakers<br/>
Nishant Ranjan, Chief Executive, GCC Operations, Bank of Baroda<br/>
Priya Rawat, Chief Operating Officer, Invest India<br/>
Shaima Al Alaydarous, Director of the Trade Negotiations and International Organizations Department, Ministry of Economy, UAE<br/><br/>
Moderator<br/>
Anurag Chaturvedi, Chairman, ICAI Dubai
      `,
    ],
  },
  {
    time: '12.20',
    title: `Speech: Industrial Perspective on signing of CEPA`,
    text: [`Arun Chawla, Director General, Federation of Indian Chambers of Commerce & Industry`],
  },
  {
    time: '12.30',
    title: 'Speech: Bi-Lateral Food Security, Innovating for Food Sufficiency and Security',
    text: [`Satyam Bose, Chairman & Chief executive Officer, Virenxia, UAE`],
  },
  {
    time: '12.40',
    title: 'Panel Discussion: Innovation-driven food security',
    text: [
      `Panelists<br/>
      Nitin Puri, Head- e Marketplace, Innoterra<br/>
      Dr. Reji Kurien Thomas, Group Chief Technology Officer, Virenxia Piyush Chowhan, Group Chief Information Officer, Lulu International <br/><br/> 
      Moderator<br/> ̰
      Joydeep Sengupta, Khaleej Times`,
    ],
  },
  {
    time: '13.10',
    title: 'Panel Discussion: Investing in Innovation',
    text: [
      `Panelists<br/>
      His Excellency Sameh Al Qubaisi, Director General - Economic Affairs at Abu Dhabi Department of Economic Development<br/>
Prof. Satya Charkavarthy, Co-Founder, GalaxEye<br/>
Prasoon Shrivastava, Chief Executive Officer, Zepth<br/>
Shrikant Sinha, Chief Executive Officer, TASK, Government of Telangana<br/><br/>
Moderator<br/>
Shradha Sharma, Founder, YourStory, India`,
    ],
  },
  {
    time: '13.50',
    title: 'Networking Lunch',
    text: [],
  },
  {
    time: '14.50',
    title: 'Conversation: Gateway to the Future',
    text: [
      `Panelists<br/>
      Sanjeev Kumar, Chairman & Managing Director and Director (Projects), TCIL, Government of India Nikhil Nanda, Managing Director, Svendgaard<br/><br/>
      Moderator<br/>
      Yasmine Mustafa, Khaleej Times`,
    ],
  },
  {
    time: '15.10',
    title: 'Speech: New Peak in the India UAE economic relationship',
    text: [`Prakash Hinduja, Chairman, Hinduja Group`],
  },
  {
    time: '15.20',
    title: `Panel Discussion: Technology - Potential collaboration, investment and benefits`,
    text: [
      `
      Panelists<br/>
Ghanim Mohammad Al Falasi, Senior Vice President - Technology & Entrepreneurship, Dubai Silicon Oasis Authority Ramesh Ram, Vice President & Region Head - Strategic Alliances, Tech Mahindra<br/>
Shivendra Singh, Vice President & Head - Global Trade Development, NASSCOM, India (Virtual)<br/>
Shrini Viswanath, Co-founder, UPSTOX, India<br/>
Sriranga Sampathkumar, VP & General Manager – Middle East and Africa, Infosys, India<br/><br/>
Moderator<br/>
Anjana Sankar, Khaleej Times

      `,
    ],
  },
  {
    time: '16.00',
    title: `Panel Discussion: Defining the Energy Innovation Ecosystem`,
    text: [
      `
      Panelists<br/>
      Binu Parthan, Head – Regions, The International Renewable Energy Agency (IRENA), UAE<br/>
      Eng. Nawal Alhanaee, Director - Future Energy Department, Ministry of Energy & Infrastructure, UAE Sanjay Kumar, Head Lubes, Hindustan Petroleum Corporation Limited, India (Virtual)<br/>
      Martin Nagell, Advisor, Office of the CEO, Masdar, UAE<br/><br/>
      Moderator<br/>
      Ahmed Elbermbali, Managing Director, Clean Energy Business Council, UAE

      `,
    ],
  },
  {
    time: '16.30',
    title: `Closing of the event by Consul General & Khaleej Times`,
    text: [``],
  },
];

export default Agenda;
