// reactstrap componentsuaeIndia
import { Container, Col, Row } from "reactstrap";

import React from "react";

function LandingPageHeader({ home }) {
  const size =
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024
      ? true
      : false;

  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (document.documentElement.scrollTop > 50 || document.body.scrollTop > 50) {
      } else if (document.documentElement.scrollTop < 300 || document.body.scrollTop < 300) {
      }
    };

    window.addEventListener("scroll", updateNavbarColor);

    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });

  return (
    <>
      <div
        style={{
          overflow: "hidden",
          background: "#fff",
          backgroundImage: "url(" + require("assets/images/MediaWall2x2m-01.png") + ")",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundAttachment: "fixed",
          // backgroundImage:
          //   "linear-gradient(106deg, rgba(1,255,255,1) 0%, rgba(0,11,46,1) 59%)",
        }}
        className="page-header"
        // ref={myRef}
        data-parallax={true}
      >
        <Container style={{ paddingTop: size && "10vh" }}>
          <Row className=" mb-5">
            {/* <Col lg={12} className="mb-3">
              <img
                src={require(`assets/images/logo/khaleej.png`)}
                style={{ maxWidth: "150px", marginLeft: "-5px" }}
                width="100%"
                alt="KhaleejTimes"
              />
            </Col> */}

            <Col md={12} xs={12}>
              <img
                src={require(`assets/images/logo/Finacle-vertical-color.png`)}
                style={{ maxWidth: "150px" }}
                width="100%"
                className="border-right pr-3"
                alt="KhaleejTimes"
              />
              <img
                src={require(`assets/images/logo/Logo-RedHat-A-Color-RGB.png`)}
                style={{ maxWidth: "180px" }}
                width="100%"
                className=" pl-3"
                alt="KhaleejTimes"
              />
            </Col>
          </Row>

          <Row>
            <Col>
              <h1
                className="text-900  text-left mt-1"
                style={{ fontSize: size ? "3.5rem" : "1.75rem", color: "#373a4a" }}
              >
                <b>Recomposing Banking:</b>
              </h1>
              <h1
                className="text-400  text-left"
                style={{ fontSize: size ? "2rem" : "1.5rem", color: "#e13521" }}
              >
                Growth with Business Model Innovation{" "}
              </h1>
              <h1
                className="text-700  text-left mt-5"
                style={{ fontSize: size ? "1.5rem" : "1.25rem", color: "#373a4a" }}
              >
                <b>
                  7<sup>th</sup> SEPTEMBER, 2023 <span className="text-muted text-400">|</span> 11 AM - 12.30
                  PM
                </b>
              </h1>
            </Col>
          </Row>
        </Container>

        <br />
      </div>
    </>
  );
}

export default LandingPageHeader;
