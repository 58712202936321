import React from "react";
import { Container, Row, Col } from "reactstrap";

function TextGrid() {
  let pageHeader = React.createRef();
  // const size = document.body.clientWidth >= 1024 || document.documentElement.clientWidth > 1024 ? true : false;
  return (
    <>
      <div
        style={{
          backgroundImage: "url(" + require("assets/images/infosysredhat.jpg") + ")",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundAttachment: "fixed",
        }}
        className="section"
        data-parallax={true}
        ref={pageHeader}
      >
        <div className="overlay-secondary"></div>
        <Container>
          <Row>
            <Col lg={8}>
              <h1 className="text-700" style={{ color: "#e13521", fontSize: "3rem" }}>
                <b>EVENT OVERVIEW</b>
              </h1>
              <br />
              <br />
              <h2 className="text-white text-400">
                Khaleej Times in partnership with Infosys Finacle and Redhat will be hosting an
                Executive Roundtable on the topic{" "}
                <b>
                  “Recomposing Banking Growth with Business Model Innovation” in Dubai on the 7th of
                  September 2023.
                </b>
                <br />
                <br />
              </h2>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default TextGrid;
