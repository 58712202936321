import React from "react";
import {
  // Collapse,
  Container,
  Row,
  Col,
  Card,
  CardBody,
} from "reactstrap";
import ScrollAnimation from "react-animate-on-scroll";
const size =
  document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024 ? true : false;
function Agenda() {
  function AgendaCard({ data }) {
    return data.map((d, i) => (
      <Col lg={12}>
        <ScrollAnimation animateIn="fadeInDown" animateOnce={true} duration={0.4}>
          <Card style={{ backgroundColor: "#373a4a", minHeight: "50px" }}>
            <CardBody>
              <Container>
                <Row>
                  <Col xs={1} className="align-self-center">
                    <h2
                      className="text-900 "
                      style={{ fontSize: size ? "50px" : "40px", color: "#e13521" }}
                    >
                      {i + 1}
                    </h2>
                  </Col>
                  <Col xs={11} className="align-self-center  ">
                    <h2 className="text-400 " style={{ color: "white" }}>
                      {d.time}
                    </h2>
                  </Col>
                </Row>
              </Container>
            </CardBody>
          </Card>
        </ScrollAnimation>
      </Col>
    ));
  }

  return (
    <>
      <div className="section" data-parallax={true}>
        <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
          <Container>
            <Row>
              <Col md="12">
                <h1
                  className="text-center section-title text-900"
                  style={{ color: "#373a4a", fontSize: "60px" }}
                >
                  <b>AGENDA</b>
                </h1>
                <h2 className="text-700 text-center mb-4 text-white">
                  Accenture Exchange Opening Ceremony
                </h2>
              </Col>
            </Row>
          </Container>
          <Container>
            <Row>
              <AgendaCard data={content1} />
            </Row>
          </Container>
        </ScrollAnimation>
      </div>
    </>
  );
}

const content1 = [
  { time: "Welcome Note by Khaleej Times " },
  {
    time: "Keynote presentation on the topic: Recomposing Banking Growth with Business Model Innovation",
  },
  { time: "Ice breaker Session" },
  {
    time: "Roundtable Discussion on the theme : Recomposing Banking Growth with Business Model Innovation",
  },
  { time: "Summary of Roundtable Discussion by Roundtable leaders. " },
  { time: "Case Study Discussion" },
  { time: "Closing Note followed by Networking Lunch" },
];

export default Agenda;
