import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import Card from 'reactstrap/lib/Card';

function TextGrid() {
  let pageHeader = React.createRef();

  return (
    <>
      <div
        style={{
          backgroundColor: '#fff',
        }}
        className="py-5"
        data-parallax={true}
        ref={pageHeader}
      >
        <Container>
          <Row>
            <Col lg={10}>
              <h1 className=" text-dark section-title">
                <b>MBG - Corporate Services</b>
              </h1>
               
            </Col>
            {content.map((data, index) => (
              <Col lg={3} xs={6} key={index} className="py-4">
                <Card style={{ maxWidth: '100px' }} className="mx-auto">
                <i className={`lni ${data.image} text-center`} style={{
                  fontSize: '80px',
                  color: '#1e2a5e',
                }}></i>
                </Card>
                <h3 className="text-600 text-center">{data.title}</h3>
              </Col>
            ))}
              
          </Row>
        </Container>
      </div>
    </>
  );
}

const content = [
  { title: 'Recent Tax Legislative Changes', image: 'lni-money-protection' },
  { title: 'Tax Audit framework, Tax Audit Process', image: 'lni-bar-chart' },
  { title: 'Common mistakes in VAT return filing & VD submission', image: 'lni-keyword-research' },
  { title: 'Significant Impact of administrative Penalities', image: 'lni-revenue' },
  { title: 'Appeal Process', image: 'lni-bulb' },
];

export default TextGrid;
