// reactstrap componentsuaeIndia
import { Container, Col, Row } from "reactstrap";

import React from "react";

function LandingPageHeader({ home }) {
  const size =
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024
      ? true
      : false;

  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (document.documentElement.scrollTop > 50 || document.body.scrollTop > 50) {
      } else if (document.documentElement.scrollTop < 300 || document.body.scrollTop < 300) {
      }
    };

    window.addEventListener("scroll", updateNavbarColor);

    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });

  return (
    <>
      <div
        style={{
          overflow: "hidden",
          background: "#fff",
          backgroundImage: "url(" + require("assets/images/shutterstock_2159418579.jpg") + ")",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundAttachment: "fixed",
          // backgroundImage:
          //   "linear-gradient(106deg, rgba(1,255,255,1) 0%, rgba(0,11,46,1) 59%)",
        }}
        className="page-header"
        // ref={myRef}
        data-parallax={true}
      >
        <Container fluid style={{ height: "100%" }}>
          <Row style={{ display: "flex", alignContent: "center" }}>
            {/* <Col lg={12} className="mb-3">
              <img
                src={require(`assets/images/logo/khaleej.png`)}
                style={{ maxWidth: "150px", marginLeft: "-5px" }}
                width="100%"
                alt="KhaleejTimes"
              />
            </Col> */}

            <Col
              md={5}
              xs={12}
              style={{
                background: "white",
                height: "100vh",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div>
                <div className="container-fluid row ">
                  <Col lg={4} xs={6} className="pr-2">
                    <img
                      src={require(`assets/images/logo/Cloud4C_logo_blue_.png`)}
                      width="100%"
                      className=""
                      alt="KhaleejTimes"
                    />
                  </Col>
                  <Col lg={4} xs={6} className="pl-1">
                    <img
                      src={require(`assets/images/logo/o-prtnr-clr-rgb.png`)}
                      // style={{ maxWidth: size ? "300px" : "200px" }}
                      width="100%"
                      alt="KhaleejTimes"
                    />
                  </Col>
                </div>
                <h1
                  className="text-900  text-left mt-1"
                  style={{ fontSize: size ? "3.8rem" : "2.3rem", color: "#373a4a" }}
                >
                  <b>
                    The Future of
                    <br />
                    Financial Services
                  </b>
                </h1>
                <h1
                  className="text-400  text-left"
                  style={{ fontSize: size ? "2.9rem" : "2rem", color: "#c74634" }}
                >
                  A CXO Executive Summit{" "}
                </h1>
              </div>
            </Col>
          </Row>
        </Container>

        <br />
      </div>
    </>
  );
}

export default LandingPageHeader;
