import React from 'react';
import { Container, Row, Col, Card } from 'reactstrap';
// import ScrollAnimation from 'react-animate-on-scroll';
// import Particles from './Particles';
// import CountUp from 'react-countup';

function Event() {
  const size =
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth > 1024 ? true : false;

  return (
    <>
      <div
        style={{
          background:
            'linear-gradient(245deg, rgba(122,48,115,1) 0%, rgba(205,35,55,1) 28%, rgba(236,112,38,1) 44%, rgba(249,170,42,1) 73%)',
        }}
        className="section"
        data-parallax={true}
      >
        <Container>
          <Row className="justify-content-center">
            <Col lg={12}>
              <h1
                className=" section-title-cx pt-3 text-center"
                style={{ color: '#fff', fontSize: '3rem' }}
              >
                <b>ATTENDEES</b>
              </h1>
              <Card className="p-3">
                <div className="container-fluid row">
                  <Col lg={4}>
                    <h3 className="text-900-cx">CXOS:</h3>
                    <h3 className="text-700-cx">
                      <ul>
                        {ceos.map((c) => (
                          <li>{c}</li>
                        ))}
                      </ul>
                    </h3>
                  </Col>
                  <Col lg={4}>
                    <h3 className="text-900-cx">Heads, VPs, Directors and Managers of:</h3>
                    <h3 className="text-700-cx">
                      <ul>
                        {!size && head3.map((c) => <li>{c}</li>)}
                        {size && heads.map((c) => (
                          <li>{c}</li>
                        ))}
                      </ul>
                    </h3>
                  </Col>
                  <Col lg={4}>
                    {size && (
                      <>
                        {' '}
                        <h3 className="text-900-cx"> </h3>
                        <h3 className="text-700-cx">
                          <ul>
                            {heads2.map((c) => (
                              <li>{c}</li>
                            ))}
                          </ul>
                        </h3>
                      </>
                    )}
                  </Col>
                </div>

                <br />
              </Card>
            </Col>
            <Col lg={12}>
              <h1
                className=" section-title-cx p-3 text-center "
                style={{ color: '#fff', fontSize: '3rem' }}
              >
                <b>FOCUS INDUSTRIES</b>
              </h1>
              <div className="container row">
                {industries.map((c) => (
                  <Col xs={6} lg={3}>
                    <Card className="p-3">
                      <h3 className="text-700-cx">{c}</h3>
                    </Card>
                  </Col>
                ))}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

const ceos = [
  'Executive Officers ',
  'Experience Officers',
  'Operating Officers ',
  'Marketing Officers',
  'Customer Officers',
];

const heads = [
  'Customer Experience/CX Strategy',
  'Customer Care',
  'Marketing',
  'Digital Experience',
  'Organizational Design/Effectiveness',
  'Client Management',
  'Brand/Customer Loyalty',
  'Communications/Social Media',
  'Design Thinking',
];
const heads2 = [
  'Contact Centres',
  'Customer Success',
  'Shared Services',
  'Design Practice',
  'Human/User-Centred Design',
  'User Experience/Experience Design',
  'Service Design',
  'Product – Experience, Design, Innovation',
  'Digital Innovation',
];

const head3 = [...heads, heads2];

const industries = [
  'Retail',
  'Government Services ',
  'Healthcare ',
  'Telecomm',
  'FMCG ',
  'Transport and Logistics ',
  'Airlines',
  'Service ',
  'Travel & Tourism ',
  'Banking',
  'Finance & Insurance',
];
export default Event;
