// reactstrap componentsuaeIndia
import { Container, Col, Row, Button } from "reactstrap";

import React from "react";
// import React, { useEffect, useRef, useState } from "react";

function LandingPageHeader({ home }) {
  const size =
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024
      ? true
      : false;
  // const [vantaEffect, setVantaEffect] = useState(0);
  // const myRef = useRef(null);

  // useEffect(() => {
  //   if (!vantaEffect) {
  //     setVantaEffect(
  //       window.VANTA.RINGS({
  //         el: myRef.current,
  //         mouseControls: true,
  //         touchControls: true,
  //         gyroControls: false,
  //         minHeight: 200.0,
  //         minWidth: 200.0,
  //         scale: 0.5,
  //         scaleMobile: 1.0,
  //         color: 0xfcfcfc,
  //         backgroundColor: `#000b2e`,
  //       })
  //     );
  //   }
  //   return () => {
  //     if (vantaEffect) vantaEffect.destroy();
  //   };
  // }, [vantaEffect]);

  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (document.documentElement.scrollTop > 50 || document.body.scrollTop > 50) {
      } else if (document.documentElement.scrollTop < 300 || document.body.scrollTop < 300) {
      }
    };

    window.addEventListener("scroll", updateNavbarColor);

    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });

  return (
    <>
      <div
        style={{
          overflow: "hidden",
          // background: '#fff',
          backgroundImage: "url(" + require("assets/images/Asset27.jpg") + ")",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundAttachment: "fixed",
          backgroundPosition: "left",
          // backgroundImage:
          //   "linear-gradient(106deg, rgba(1,255,255,1) 0%, rgba(0,11,46,1) 59%)",
        }}
        className="page-header"
        // ref={myRef}
        data-parallax={true}
      >
        {/* <div className='overlay-secondary'></div> */}
        <Container fluid style={{ paddingTop: size && "13vh" }}>
          <Row className="justify-content-start">
            <Col md={4} xs={12} className="text-center">
              <img
                src={require(`assets/images/logo/metalogo.png`)}
                style={{ maxWidth: "450px" }}
                width="100%"
                alt="CX"
              />
              <br />
            </Col>
          </Row>
          <Row className="align-items-center justify-content-start">
            <Col md={4} xs={12} className="mb-1" style={{ zIndex: 1000 }}>
              <Container fluid>
                <Row className="justify-content-center">
                  <Col lg={4} xs={6} className={`px-${size ? 0 : 1}`}>
                    <Button
                      className="px-3 py-2 rounded-0 "
                      color="primary"
                      style={{
                        width: "99%",
                        background:
                          "linear-gradient(97deg, rgba(218,0,126,1) 32%, rgba(79,37,131,1) 93%)",
                        border: "0",
                      }}
                      href="/meta-vision2022/register"
                    >
                      <p className="m-0  text-900-cx " style={{ color: "#fff" }}>
                        REGISTER <br /> NOW
                      </p>
                    </Button>{" "}
                  </Col>
                  <Col lg={4} xs={6} className={`px-${size ? 0 : 1}`}>
                    <Button
                      className="px-3 py-2 rounded-0 "
                      color="primary"
                      style={{
                        width: "99%",
                        background:
                          "linear-gradient(97deg, rgba(218,0,126,1) 32%, rgba(79,37,131,1) 93%)",
                        border: "0",
                      }}
                      href="/meta-vision2022/brochure"
                    >
                      <p className="m-0 text-900-cx" style={{ color: "#fff" }}>
                        DOWNLOAD <br />
                        BROCHURE
                      </p>
                    </Button>{" "}
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default LandingPageHeader;
