import React from "react";
import Slider from "react-slick";

import { Container, Row, Col } from "reactstrap";
import Card from "reactstrap/lib/Card";
import CardBody from "reactstrap/lib/CardBody";
import CardTitle from "reactstrap/lib/CardTitle";

function WhySponsor() {
  const settings = {
    dots: true,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 8000,
    slidesToShow: 1,
    slidesToScroll: 1,
    className: "p-10",
  };

  return (
    <>
      <div
        style={{
          backgroundColor: "#fff",
        }}
        className='section'
        data-parallax={true}
      >
        <Container>
          <Row>
            <Col lg={12}>
              <h1 className='text-main'>
                <b>WHY SPONSOR KHALEEJ TIMES EVENTS?</b>
              </h1>
              <h2 className='text-dark text-400'>
                Khaleej Times Events leverage our brand strengths to deliver
                unmatched client experiences—combining in-person and virtual
                engagement of the most valuable audiences across the globe,
                market-moving conversations on priority topics, and robust
                cross-platform amplification. Our dedicated team of experts
                works hand-in-hand with each partner to seamlessly integrate
                brands to ensure client success.
              </h2>
            </Col>
          </Row>
        </Container>
        <Container className='mt-5'>
          <Row
            style={{
              backgroundColor: "rgb(234,208,241)",
              backgroundImage:
                "url(" + require("assets/images/event.png") + ")",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          >
            <Col lg={2} xs={6} className='p-0'>
              <div
                style={{
                  aspectRatio: "1/1",
                  display: "flex",
                  justifyContent: "center",
                  border: "1px solid rgba(0,0,0,0.5)",
                  textAlign: "center",
                  background: "rgba(0,0,0,0.5)",
                }}
              >
                <div className='align-self-center'>
                  <img
                    src={require("assets/images/logo/khaleej1.png")}
                    alt='Khaleej'
                    width='90%'
                  />
                  <h2 className='text-400 text-white'>Events by Number</h2>
                </div>
              </div>
            </Col>
            {content.map((c) => (
              <Col lg={2} xs={6} className='p-0'>
                <div
                  style={{
                    aspectRatio: "1/1",
                    display: "flex",
                    justifyContent: "center",
                    border: "1px solid rgba(0,0,0,0.5)",
                    textAlign: "center",
                    background: c.color,
                  }}
                >
                  <div className='align-self-center'>
                    <h1 className='text-700 text-white'>{c.num}</h1>
                    <h2 className='text-700 text-white'>{c.title}</h2>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
        <Container className='mt-5'>
          <Row className='justify-content-center'>
            <Col lg={4}>
              <Card>
                <CardTitle>
                  <h1 className='text-center text-700'>Who We Reach</h1>
                </CardTitle>
                <CardBody className='px-3 text-700'>
                  <ul>
                    <li>C-Suite & BDMS </li>
                    <li>High-Net-Worth </li>
                    <li>Investors</li>
                    <li>Financial Advisors</li>
                    <li>Policymakers</li>
                    <li>Small Business Owners</li>
                    <li>Entrepreneurs</li>
                  </ul>
                </CardBody>
              </Card>
            </Col>
            <Col lg={4}>
              <Card>
                <CardTitle>
                  <h1 className='text-center text-700'>What We Cover</h1>
                </CardTitle>
                <CardBody className='px-3 text-700'>
                  <ul>
                    <li>Business</li>
                    <li>Entrepreneurship</li>
                    <li>Health Care</li>
                    <li>Investing</li>
                    <li>Politics & Policy</li>
                    <li>Social Impact</li>
                    <li>Tech</li>
                  </ul>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <div
        style={{
          backgroundColor: "#efefef",
        }}
        className='section py-0'
        data-parallax={true}
      >
        <Container>
          <Row className='justify-content-center'>
            <Col lg={3} className='align-self-center'>
              <h1 className='text-center text-700'>
                See Khaleej Times Events in Live Action
              </h1>
            </Col>
            <Col lg={9} xs={10}>
              <Slider {...settings}>
                {videos.map((v) => (
                  <div class='embed-responsive embed-responsive-16by9'>
                    <iframe
                      class='embed-responsive-item'
                      src={`https://www.youtube.com/embed/${v}?rel=0`}
                      allowfullscreen
                      title={v}
                    ></iframe>
                  </div>
                ))}
              </Slider>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

const content = [
  { title: "", color: "" },
  { num: "16+ ", title: "In-Person Events ", color: "rgba(0,0,0,0.5)" },
  { title: "", color: "" },
  {
    num: "5,000+ ",
    title: "Attendees From All Over The World  ",
    color: "rgba(0,0,0,0.5)",
  },
  { title: "", color: "" },
  { title: "", color: "" },
  { num: "200,000+ ", title: "Emailer Reach ", color: "rgba(0,0,0,0.5)" },

  { title: "", color: "" },
  { num: "90,000+ ", title: "Circulation ", color: "rgba(0,0,0,0.5)" },
  { title: "", color: "" },
  { num: "4m+ ", title: "Social Media Reach ", color: "rgba(0,0,0,0.5)" },
];

// const stats = [
//   { title: "Monthly Page Views", value: "30000", plus: true },
//   { title: "Newsletter Subscribers", value: "250000", plus: true },
//   { title: "Million Hours of Content", value: "3.5 ", plus: true },
// ];

const videos = [
  "bsWaF7tUcx0",
  "_PBfe9uibxQ",
  "gSi6sNPmusk",
  "Pwmakjlh7Ok",
  "3c4bTyCO8dc",
  "S65w3OOdv4g",
  "3RivtscC_og",
  "rB4XlkmoXcI",
  "C_DTr0nfFNI",
  "p8wSN1dJp-k",
  "Tp5coVmBbls",
  "w0HMp7WpQ9A",
];

export default WhySponsor;
