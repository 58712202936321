import React from "react";
import { Row, Container, Col } from "reactstrap";

function DemoFooter() {
  return (
    <footer>
      <Container>
        <Row>
          <Col lg={4}>
            <div className="poweredBox">
              <h3>Organised by</h3>
              <a href="https://www.khaleejtimes.com/" rel="noopener noreferrer" target="_blank">
                <img src={require(`assets/images/logo/new-logo.png`)} alt="" />
              </a>
            </div>
          </Col>
          <Col lg={8}>
            <div className="partnersBox">
              <h3 class='text-center'>Lead Partners</h3>
              <div class='text-center'>
                <img src={require(`assets/images/new/partners-logo1.png`)} width="100%" style={{ maxWidth: "340px" }} alt="" />
                {/*<img src={require(`assets/images/new/partners-logo2.png`)} width="100%" style={{ maxWidth: "200px" }} alt="" /> */}
                <img src={require(`assets/images/new/partners-logo3.png`)} width="100%" style={{ maxWidth: "170px" }} alt="" />
                <img src={require(`assets/images/new/partners-logo4.png`)} width="100%" style={{ maxWidth: "170px" }} alt="" />
              </div>
            </div>
          </Col>
        </Row>
        <Row id="sponsors">
          <Col lg={12}>
            <div className="sponsorsBox">
              <h3>Past Sponsors</h3>
              <img src={require(`assets/images/new/sponsors-img1.jpg`)} width="100%" style={{ maxWidth: "400px" }} alt="" />
            </div>
          </Col>
        </Row>
      </Container>
      <section className="copyrightSec">
        <Container>
          <Row>
            <Col lg={4}>
              <p>
                <a href="https://www.khaleejtimes.com/privacy-notice" rel="noopener noreferrer" target="_blank">Privacy Notice</a> |&nbsp;
                <a href="/sponsor/download-brochure">Brochure</a>
              </p>
            </Col>
            <Col lg={4}>
              <p>
                Connect With Us<br />
                <a href="https://www.linkedin.com/company/khaleejtimes/mycompany/" rel="noopener noreferrer" target="_blank">
                  <i className="fa fa-linkedin" />
                </a>
              </p>
            </Col>
            <Col lg={4}>
              <p>
                &copy; 2022 Galadari Printing and Publishing LLC. All rights reserved.
              </p>
            </Col>
          </Row>
        </Container>
      </section>
    </footer >
  );
}

export default DemoFooter;

// const social = [
//   // {
//   //   name: "facebook",
//   //   link: "https://www.facebook.com/khaleejtimes",
//   // },
//   {
//     name: "linkedin",
//     link: "https://www.linkedin.com/company/khaleejtimes/mycompany/",
//   },
//   // {
//   //   name: "twitter",
//   //   link: "https://twitter.com/khaleejtimes",
//   // },
// ];
