import React from "react";
import Navbar from "componentsCX/Navbar.js";
import ProfilePageHeader from "componentsCX/Headers/ProfilePageHeader.js";
import Footer from "componentsCX/Footer.js";

function RegisterAwardPage() {
  return (
    <>
      <Navbar />
      <ProfilePageHeader title="REGISTER FOR AWARDS" bgColor="#fff" />
      <div className="section profile-content" style={{ padding: 0 }}>
        <iframe
          src="https://cxevolveawards.evalato.com/form"
          id="evalato_embed_7219"
          frameBorder="0"
          width="100%"
          height="1000"
          title="form"
        />
      </div>
      <Footer />
    </>
  );
}

export default RegisterAwardPage;
