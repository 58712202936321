import React from "react";
import Navbar from "componentsCX/Navbar.js";
import ProfilePageHeader from "componentsCX/Headers/ProfilePageHeader.js";
import GalleryCX from "componentsCX/GalleryCX";

import Footer from "componentsCX/Footer.js";
// import Testimonials from "componentsCX/Testimonials.js";

function GalleryCx() {
  return (
    <>
      <Navbar />
      <ProfilePageHeader title="GALLERY" bgColor="#fff" />
      <div className="section profile-content" style={{ padding: 0 }}>
        <GalleryCX />
      </div>
      {/* <Testimonials /> */}
      <Footer />
    </>
  );
}

export default GalleryCx;
