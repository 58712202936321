import React from 'react';

import { Container, Row, Col, Button } from 'reactstrap';

import Slider from 'react-slick';

function News({ news }) {
  const settings = {
    dots: true,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 8000,
    slidesToShow: 3,
    slidesToScroll: 1,
    className: 'p-10',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <div
        style={{
          backgroundColor: '#fff',
        }}
        className="section section-with-space"
        data-parallax={true}
      >
        <div className="overlay2 d-none d-xl-block d-lg-block " />
        <div className="overlayMedium d-none d-xl-none d-lg-none d-md-block" />
        <div className="overlayMobile d-xl-none d-lg-none d-md-none d-sm-block d-xs-block" />
        <Container>
          <Row>
            <Col xs={12}>
              <div className="title">
                <h1 className="text-center section-title text-main text-uppercase">
                  <b>GLIMPSES FROM THE 2022 EVENT</b>
                </h1>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={11} className={'ml-auto mr-auto'}>
              <Slider {...settings}>
                {images.map((n, index) => (
                  <img
                    src={`https://drive.google.com/uc?id=${n.image}&export=download`}
                    width="100%"
                    alt="event"
                    className='px-1'
                  />
                ))}
              </Slider>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row style={{ marginTop: '2rem' }}>
            <Col lg={12} className={'text-center'}>
              <Button
                href="/uae-india-investment-forum/news"
                className="btn"
                style={{ backgroundColor: '#000b2e' }}
                size="lg"
              >
                <p className="m-0 text-700" style={{ color: '#fff' }}>
                  VIEW ALL
                </p>
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default News;

const images = [
  { image: '1F3jYAnuXJl9I0RiREZUfOIiuVhE63hvw' },
  { image: '1QkjKZsxu3bUZ33TH7XCxBn38GC9CUNqN' },
  { image: '1Tz-l4qm_Y_kyu2bAmPAAWy_6j9tYeRTa' },
  { image: '1KLZfTR8iliKIhXvx0HYJ3MYJnYOQfyUm' },
  { image: '1rkQg4fsAZ-BMB8BSPM0ecFLIeufg8Z2f' },
  { image: '1JGeFZS16s6zvNqj74YjL5l8sVZIH5Phl' },
  { image: '1zfqY6CypplYQ-SCzSPzjDxOjgN7YhpDY' },
  { image: '1shNYk16_qlPMbT6eS_IWVTgPCTaWWV0M' },
  { image: '1sFSalpdv7Tslh8u_0F72jEfmLnYK4nQ1' },
  { image: '173nGrWQvYY3_KUb5Zk9eEtYm8B9zHXh-' },
  { image: '1a3orBV9X3erc_rVZSdYjxQhxbn2sbFgD' },
  { image: '1QX0dY6RA96IMHNWLT9-GmrV_bfmmWjft' },
  { image: '1J3enQOLOHZDWk12iKmQB0HDLGRveacJG' },
  { image: '1bcax4h8tOCJyG1-JmZyHMBbzEpI09CET' },
  { image: '1U9sJ0rAqISd0eBFH8nn5AIjzxK8OACxq' },
  { image: '16DabY3iOUGDRtySdjbIZxgU7KXZeAaz6' },
  { image: '1ML9trjIzUpbPO1KKNovwKdEaY9gMJ28b' },
  { image: '1rta2OWYPbtzY1sRQ4Vzr6C_4Fxg0d9lo' },
  { image: '1IQN9ikeH0TCQ-cnNpS0MGWIM1ZHzXXRF' },
  { image: '1Ry16OU6t6TQixliNNg--TyhmxkoN7qUF' },
  { image: '1_Yi_6OWihCATMkUoMUZ_JU6pqY43rXEi' },
  { image: '1JJDkzh_MHy6yPcQwA--HsfYnd1gPsotM' },
  { image: '1UhCHd2yVZMTwbRgV-VP7lzuuyk3C0Rf7' },
  { image: '1GTnb0-R5Z0MqQNWg-UorS_vthaiaWRWb' },
  { image: '1C0dO_sPB4LfpGS2QQ3-KzlVcP4RBdNWI' },
  { image: '1dRbNFM7XKmgMl9q2x5hoKAZZ2H_1Rtwv' },
  { image: '1VS-EAHJbu3JBs2bf7JFnsPt0DZlTFelt' },
  { image: '1XqNL-4l_l8wVEugGJXB3hnxPNWqtT94I' },
  { image: '1yTF05MKS9RA5OfqCHYlqIfv_rFYGOWC5' },
  { image: '1jqTvRbevWcqJtMS2ItjMSOaVbTH3Jtin' },
];
