// reactstrap componentsuaeIndia
import { Container, Col, Row } from "reactstrap";

import React from "react";

function LandingPageHeader({ home }) {
  const size =
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024
      ? true
      : false;

  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (document.documentElement.scrollTop > 50 || document.body.scrollTop > 50) {
      } else if (document.documentElement.scrollTop < 300 || document.body.scrollTop < 300) {
      }
    };

    window.addEventListener("scroll", updateNavbarColor);

    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });

  return (
    <>
      <div
        style={{
          overflow: "hidden",
          background: "rgb(0,137,207)",
          backgroundImage: "url(" + require("assets/exotel-boardroom/bg.jpg") + ")",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundAttachment: "fixed",
          // backgroundImage:
          //   "linear-gradient(106deg, rgba(1,255,255,1) 0%, rgba(0,11,46,1) 59%)",
        }}
        className="page-header"
        // ref={myRef}
        data-parallax={true}
      >
        <Container style={{ paddingTop: size && "10vh" }}>
          <Row className="">
            <Col md={4} xs={12}></Col>
            <Col md={8} xs={12}
              className="text-center"
              style={{
                background: '#ffffffc7',
                padding: '40px'
              }}
            >
              <Container>
                <Row>
                  <Col>
                    <h1
                      className="text-800  text-left mb-0"
                      style={{ fontSize: size ? "2.5rem" : "1.75rem", color: "#000" }}
                    >
                      <span>
                        <small style={{ color: 'rgb(0 117 197)', fontWeight: 'bold' }}>In Person Boardroom</small>
                        <br />
                        Build Connected Customer Journeys
                        <br />
                        <small style={{ fontWeight: 500, color: '#000' }}>
                          Deliver uncompromised and Win hands down in the age of Customer Over expectations
                        </small>
                      </span>
                    </h1>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
          <Row className="align-items-center"></Row>
        </Container>
      </div>
    </>
  );
}

export default LandingPageHeader;
