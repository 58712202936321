import React from 'react';

import { Container, Row, Col } from 'reactstrap';
// import ScrollAnimation from "react-animate-on-scroll";
import Slider from 'react-slick';
function Testimonials() {
  const settings = {
    dots: true,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    className: 'p-10',
  };

  return (
    <>
      <div
        style={{
          background: '#01ffff',
          backgroundSize: 'cover',
          overflow: 'hidden',
        }}
        className="section section-with-space"
        data-parallax={true}
      >
        <Container>
          <Row>
            <Col xs={12}>
              <div className="">
                <h1 className="section-title text-center text-main">
                  <b>TESTIMONIALS</b>
                </h1>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12} className="text-center">
              <Slider {...settings}>
                {content.map((c) => (
                  <>
                    <br />
                    <br />
                    <h2
                      className="text-main text-600"
                      dangerouslySetInnerHTML={{ __html: c.testimonial }}
                    />
                    <br />
                    <br />
                    <h3 className="text-main text-800">{c.by}</h3>
                  </>
                ))}
              </Slider>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

const content = [
  {
    testimonial: `
    It is indeed a matter of pride to be a part of the strategic relationship between India and UAE cementing with much larger trade deals and pacts. We are going to be participating to further enhance the untapped potential of the great nations.!
	`,
    by: 'Nikhil Nanda, Managing Director at Svendgaard',
  },
  {
    testimonial: `
    The UAE-India Investment Forum 2022 comes at an important time as both nations look to ratify the recently-signed historic trade agreement. India and the UAE enjoy highly complementary economies and there’s much we can offer one another as we embark on a new era of cooperation and collaboration. The agreement offers enormous benefits for people in both countries across a broad spectrum of industries. Now we need to put the promise of this new agreement into practice, and equip businesses with the tools to drive a new era of prosperity. Platforms such as this are the best place to start.
	`,
    by: 'Shaima Sharif Alaydarous, Director, Trade Negotiations and International Organizations Department, UAE Ministry of Economy',
  },
];

export default Testimonials;
