import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "reactstrap";

import Card from "reactstrap/lib/Card";
import CardBody from "reactstrap/lib/CardBody";
import CardImg from "reactstrap/lib/CardImg";
import CardSubtitle from "reactstrap/lib/CardSubtitle";

import CardTitle from "reactstrap/lib/CardTitle";

function TextGrid() {
  const [recent, setRecent] = useState([]);

  // Fetch data when the component mounts
  useEffect(() => {
    fetch(`https://api.khaleejtimesevents.com/events.json?v=09thOct2024${new Date()}`)
      .then((response) => response.json())
      .then((data) => {
        setRecent(data.recent || []);
      })
      .catch((err) => console.log(err));
  }, []);
  return (
    <>
      <div
        style={{
          background: "#2296e3",
        }}
        className='section'
        data-parallax={true}
      >
        <Container className='mt-5'>
          <Row>
            {recent.map((c) => (
              <Col lg={4}>
                <a
                  href={c.link}
                  target='_blank'
                  without
                  rel='noopener noreferrer'
                >
                  <Card style={{ minHeight: '370px', marginBottom: '30px' }}>
                    <CardImg
                      alt={c.title}
                      src={c.image}
                      top
                      width='100%'
                    />
                    <CardBody>
                      <CardTitle tag='h2'>{c.title}</CardTitle>
                      <CardSubtitle className='mt-1 mb-2 text-muted' tag='h3'>
                        {c.date}
                      </CardSubtitle>
                    </CardBody>
                  </Card>
                </a>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </>
  );
}

export default TextGrid;
