import React from "react";

import { Container, Row, Col } from "reactstrap";
import ScrollAnimation from "react-animate-on-scroll";
import Slider from "react-slick";

function Sponsor({ sponsors, sponsors_2022 }) {
  // const rows = [
  //   ["TECHNOLOGY PARTNER"],
  //   ["STRATEGIC SPONSOR"],
  //   ["GOLD SPONSOR"],
  //   ["SILVER SPONSOR"],
  // ];
  const rows = [
    ["ROW1"],
    ["ROW2"],
    ["ROW3"],
    ["ROW4"],
    ["ROW8"],
    ["ROW5"],
    ["ROW7"],
    ["ROW6"],
  ];
  let rowsTitle = {};
  rowsTitle.ROW1 = "Headline Sponsor";
  rowsTitle.ROW2 = "Strategic Sponsor";
  rowsTitle.ROW3 = "Gold Sponsor";  
  rowsTitle.ROW4 = "Gold Sponsor";  
  rowsTitle.ROW5 = "Media Partner";  
  rowsTitle.ROW6 = "Entertainment Partner";  
  rowsTitle.ROW7 = "";  
  rowsTitle.ROW8 = "CX Partner";  

  const settingsdesktop = {
    dots: true,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 8000,
    slidesToShow: 5,
    slidesToScroll: 3,
    className: "p-10",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <>
      <div
        style={{
          backgroundColor: "#201e45",

          // backgroundImage: "linear-gradient(315deg, #d4418e 0%, #0652c5 74%)",
        }}
        className="section"
        data-parallax={true}
      >
        <Container>
          <Row className="justify-content-center">
            <Col lg={12}>
              <h1
                className="text-center section-title-cx pt-3"
                style={{ color: "#f04c21", fontSize: "3rem" }}
              >
                <b>2024 SPONSORS</b>
              </h1>
            </Col>
            <Col lg={12}>
              <Container>
                <Row>
                  {rows.map((row) => (
                    <Col md={4}>
                      {row.map((sponsor) => (
                        <Row>
                          <Col lg={12} className={"ml-auto mr-auto"}>
                            <h2
                              className="text-white text-400 text-center pt-3 mb-0 pt-0 mt-4"
                              style={{
                                margin: 0,
                                padding: 0,
                                paddingTop: rowsTitle[sponsor] && "20px",
                              }}
                            >
                              {rowsTitle[sponsor]}
                            </h2>
                          </Col>
                        {sponsors[sponsor] && sponsors[sponsor].map((s) => (
                          <Col lg={s.grid || 4} className={"ml-auto mr-auto"}>
                            <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
                              <h1
                                className="text-white text-400 text-uppercase text-center pb-3"
                                style={{
                                  margin: 0,
                                  padding: 0,
                                  paddingTop: s.title && "20px",
                                }}
                                dangerouslySetInnerHTML={{
                                  __html: !s.title1 ? s.title : "",
                                }}
                              ></h1>
                              <a
                                target="_BLANK"
                                rel="noopener noreferrer"
                                href={s.link}
                                style={{
                                  display: "block",
                                  background: s.bg,
                                }}
                              >
                                <div
                                  // Card
                                  className="mx-auto"
                                  style={{ borderRadius: 0, maxWidth: s.size }}
                                >
                                  <img
                                    src={`/sponsers/cx-evolve/${s.image}`}
                                    alt={s.name}
                                    width="100%"
                                    className="rounded-0"
                                  />
                                </div>
                              </a>
                            </ScrollAnimation>
                          </Col>
                        ))}
                        </Row>
                        ))}
                    </Col>
                  ))}
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
      </div>
      <div
        style={{
          backgroundColor: "#F5F5F5",

          // backgroundImage: "linear-gradient(315deg, #d4418e 0%, #0652c5 74%)",
        }}
        className="section"
        data-parallax={true}
      >
        <Container>
          <Row className="justify-content-center">
            <Col lg={12}>
              <h1
                className="text-center section-title-cx pt-3"
                style={{ color: "#f04c21", fontSize: "3rem" }}
              >
                <b>PAST SPONSORS</b>
              </h1>
            </Col>
            <Col lg={12}>
                  <Slider {...settingsdesktop}>
                    {sponsors_2022.map((speaker, index) => (
                      <a target="_LINK" href={speaker.link} key={index}>
                        <Row className="m-2">
                          <Col xs={12} className="px-0">
                            <div className="card-avatar px-3aaa pt-3aaa">
                              <img
                                src={require(`assets/sponsors/${speaker.image}`)}
                                width="100%"
                                alt="..."
                                style={{
                                  borderRadius: 0,
                                  height: "150px",
                                }}
                              />
                            </div>
                          </Col>
                          <Col xs={12} className="text-center">
                            {/* <h2 className="text-dark text-700-cx  mt-2">{speaker.name}</h2> */}

                            {/* <h4 className="text-400 text-muted m-0">{speaker.type}</h4> */}
                            {/* <h4 className="text-dark text-900-cx  m-0 d-block px-0">{speaker.text}</h4> */}
                          </Col>
                        </Row>
                      </a>
                    ))}
                  </Slider>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Sponsor;
