import React from "react";

import Footer from "components/Footer.js";
import NavbarDiv from "components/Navbar";
import { Col, Container, Row } from "reactstrap";

function RegisterPage() {
    const size = document.body.clientWidth >= 1024 || document.documentElement.clientWidth > 1024 ? true : false;
  return (
    <>
      <NavbarDiv />
      
      <div
        className="section "
        style={{
          background: '#fbfbfb',
          alignItems: 'center',
          paddingTop: size ? '8rem' : '3rem',
        }}
      >
        <Container>
            <Row>
                <Col lg={12}>
                    <h1 className="text-400">Use of cookies by Khaleej Times Events:</h1>
                    <h2>
                        <p>
                            A cookie is a text-only string of data that a website saves to your computer's hard drive in your browser's cookie file so that it can remember who you are. Most popular websites use cookies, which can speed up the process of organizing content to match your interests. Cookies by themselves cannot uniquely identify you.  
                        </p>
                        <br />
                        <p>
                            Cookies can be used to detect multiple visits to the website by the same device and to assign a randomly generated unique number to each visit to streamline our (or our partners') understanding of the interactions made on the website during that visit.
                        </p>
                        <br />
                        <p>The cookies we use, and their rationale are listed in the table below.</p>
                    </h2>

                    <table className="table table-hover table-bordered">
                        <thead>
                            <tr>
                                <th><strong>Cookie Category</strong></th>
                                <th><strong>Cookie Classification Type</strong></th>
                                <th><strong>Cookie Name</strong></th>
                                <th><strong>Purpose</strong></th>
                                <th><strong>Lifespan</strong></th>
                            </tr>
                        </thead>
						
						<tbody>
							<tr>
								<td><p>Essential</p></td>
								<td><p>First Party</p></td>
								<td><p>__privaci_cookie_consent_generated</p></td>
								<td><p>SECURITI.ai cookies are required to support core functionality of the SECURITI.ai consent solution. The cookies help maintain and honor consent preferences submitted by the user.</p></td>
								<td><p>1 year</p></td>
							</tr>
							<tr>
								<td><p>Essential</p></td>
								<td><p>First Party</p></td>
								<td><p>__privaci_cookie_consent_uuid</p></td>
								<td><p>SECURITI.ai cookies are required to support core functionality of the SECURITI.ai consent solution. The cookies help maintain and honor consent preferences submitted by the user.</p></td>
								<td><p>1 year</p></td>
							</tr>
							<tr>
								<td><p>Essential</p></td>
								<td><p>First Party</p></td>
								<td><p>__privaci_cookie_consents</p></td>
								<td><p>SECURITI.ai cookies are required to support core functionality of the SECURITI.ai consent solution. The cookies help maintain and honor consent preferences submitted by the user.</p></td>
								<td><p>1 year</p></td>
							</tr>
							<tr>
								<td><p>Essential</p></td>
								<td><p>First Party</p></td>
								<td><p>__privaci_latest_published_version</p></td>
								<td><p>SECURITI.ai cookies are required to support core functionality of the SECURITI.ai consent solution. The cookies help maintain and honor consent preferences submitted by the user.</p></td>
								<td><p>Session</p></td>
							</tr>
							<tr>
                                <td><p>Advertising</p></td>
                                <td><p>Third Party</p></td>
                                <td><p>NID</p></td>
                                <td><p>This cookie is used for purposes to show ads in services for signed-out users.</p></td>
                                <td><p>6 months</p></td>
							</tr>
                            <tr>
                                <td><p>Performance &amp; Functionality</p></td>
                                <td><p>Third Party</p></td>
                                <td><p>LAST_RESULT_ENTRY_KEY</p></td>
                                <td><p>This cookie is used to store the user's video player preferences using embedded YouTube video.</p></td>
                                <td><p>Session</p></td>
                            </tr>
                            <tr>
                                <td><p>Performance &amp; Functionality</p></td>
                                <td><p>Third Party</p></td>
                                <td><p>TESTCOOKIESENABLED</p></td>
                                <td><p>This cookie used to save individual comfort settings you have selected and keep them for your current and future visits to the site.</p></td>
                                <td><p>Session</p></td>
                            </tr>
                            <tr>
                                <td><p>Performance &amp; Functionality</p></td>
                                <td><p>Third Party</p></td>
                                <td><p>nextId</p></td>
                                <td>
                                <p>Registers a unique ID to keep statistics of what videos from YouTube the user has seen.</p>
                                </td>
                                <td><p>Session</p></td>
                            </tr>
                            <tr>
                                <td><p>Performance &amp; Functionality</p></td>
                                <td><p>Third Party</p></td>
                                <td><p>requests</p></td>
                                <td><p>This is a video embed service use to host video content on the site.</p></td>
                                <td><p>Session</p></td>
                            </tr>
                            <tr>
                                <td><p>Performance &amp; Functionality</p></td>
                                <td><p>Third Party</p></td>
                                <td><p>LAST_RESULT_ENTRY_KEY</p></td>
                                <td><p>This cookie is used to store the user's video player preferences using embedded YouTube video.</p></td>
                                <td><p>Session</p></td>
                            </tr>
                            <tr>
                                <td><p>Performance &amp; Functionality</p></td>
                                <td><p>Third Party</p></td>
                                <td><p>TESTCOOKIESENABLED</p></td>
                                <td>
                                <p>This cookie used to save individual comfort settings you have selected and keep them for your current and future visits to the site.</p>
                                </td>
                                <td><p>Session</p></td>
                            </tr>
                            <tr>
                            <td><p>Performance &amp; Functionality</p></td>
                            <td><p>Third Party</p></td>
                            <td><p>nextId</p></td>
                            <td>
                            <p>Registers a unique ID to keep statistics of what videos from YouTube the user has seen.</p>
                            </td>
                            <td><p>Session</p></td>
                            </tr>
                            <tr>
                            <td><p>Performance &amp; Functionality</p></td>
                            <td><p>Third Party</p></td>
                            <td><p>requests</p></td>
                            <td>
                            <p>This is a video embed service use to host video content on the site.</p>
                            </td>
                            <td><p>Session</p></td>
                            </tr>
                            <tr>
                            <td><p>Performance &amp; Functionality</p></td>
                            <td><p>Third Party</p></td>
                            <td><p>VISITOR_INFO1_LIVE</p></td>
                            <td>
                            <p>This cookie is used to measure user's bandwidth to determine whether user gets the new player interface or the old.</p>
                            </td>
                            <td><p>6 months</p></td>
                            </tr>
                            <tr>
                            <td><p>Performance &amp; Functionality</p></td>
                            <td><p>Third Party</p></td>
                            <td><p>VISITOR_PRIVACY_METADATA</p></td>
                            <td>
                            <p>This cookie is used to remember user input and associate a user&rsquo;s actions.</p>
                            </td>
                            <td><p>6 months</p></td>
                            </tr>
                            <tr>
                                <td><p>Performance &amp; Functionality</p></td>
                                <td><p>Third Party</p></td>
                                <td><p>YSC</p></td>
                                <td>
                                <p>This cookie is used to remember user input and associate a user&rsquo;s actions.</p>
                                </td>
                                <td><p>Session</p></td>
                                </tr>
                                <tr>
                                    <td><p>Performance &amp; Functionality</p></td>
                                    <td><p>Third Party</p></td>
                                    <td><p>remote_sid</p></td>
                                    <td>
                                    <p>This cookie is used to allow us to embed videos.</p>
                                    </td>
                                    <td><p>Session</p></td>
                            </tr>
                        </tbody>
                    </table>

                    <br />
                    <h3 className="text-400 pt-3">How can cookies be disabled?</h3>
                    <p>Changing your browser's settings or selecting Customize by clicking the Cookies button at the bottom of this policy will allow you to modify the cookie settings that will be applied when you visit our website.</p>
                </Col>
            </Row>
        </Container>
    </div>

      <Footer />
    </>
  );
}

export default RegisterPage;
