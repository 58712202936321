import React from 'react';

import { Col, Modal, } from 'reactstrap';
import SpeakerModal from 'componentsInfosysNew/SpeakerModal.js';

function SpeakerGrid({ speakers }) {
    const [open, toggleOpen] = React.useState({
        open: false,
        speaker: {},
    });

    const updateModal = (e, speaker) => {
        e.preventDefault();
        toggleOpen({ open: !open.open, speaker: speaker });
    };

    function SpeakerNew({ speaker }) {
        return (
            <a href="/" onClick={(e) => updateModal(e, speaker)}>
                <div className="speakerBox">
                    <img src={require(`assets/speakers/infosys/${speaker.image}`)} width="100%" alt="" />
                    <div className="textBox">
                        <h3>{speaker.name}</h3>
                        <h4>{speaker.title}</h4>
                        <h5>{speaker.company}</h5>
                    </div>
                </div>
            </a>
        );
    }

    return (
        <>
            {(
                speakers.map((speaker) => (
                    <Col lg={4}>
                        <SpeakerNew speaker={speaker} />
                    </Col>
                ))
            )}

            <Modal isOpen={open.open} toggle={(e) => updateModal(e)} className="freshSpeakerModal">
                <div className="popupBox">
                    <button
                        aria-label="Close"
                        data-dismiss="modal"
                        type="button"
                        onClick={(e) => updateModal(e)}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                    <SpeakerModal speaker={open.speaker} />
                </div>
            </Modal>
        </>
    );
}

export default SpeakerGrid;
