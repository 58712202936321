import React from "react";
import Navbar from "componentsMeta/Navbar.js";
import ProfilePageHeader from "componentsMeta/Headers/ProfilePageHeader.js";
import Agenda from "componentsMeta/Agenda";

import Footer from "componentsMeta/Footer.js";
// import Testimonials from "componentsMeta/Testimonials.js";

function AgendaPage() {
  return (
    <>
      <Navbar />

      <ProfilePageHeader title="AGENDA" bgColor="#fff" />
      <div className="section profile-content" style={{ padding: 0 }}>
        <Agenda />
      </div>
      {/* <Testimonials /> */}
      <Footer />
    </>
  );
}

export default AgendaPage;
