// reactstrap components
import React, { useEffect } from "react";

import Register from "componentsNewgen/Register";
import TextGrid from "componentsNewgen/TextGrid";

// import NavbarDiv from "componentsNewgen/Navbar";
import Header from "./Header";

function LandingPageHeader() {
  // const size = document.body.clientWidth >= 1024 || document.documentElement.clientWidth > 1024 ? true : false;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {/* <NavbarDiv /> */}
      <Header />
      <TextGrid />

      <section id="register" />
      <Register type="REGISTER" />
    </>
  );
}

export default LandingPageHeader;
