import React from "react";
import { Container, Row, Col } from "reactstrap";
import Slider from "react-slick";
function TextGrid() {
  let pageHeader = React.createRef();

  const settings = {
    dots: true,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    className: "p-10",
  };
  // const size =
  //   document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024
  //     ? true
  //     : false;
  return (
    <>
      <div
        style={{
          backgroundColor: "#fff",
          padding: "6rem 0",
          paddingBottom: '0',
          backgroundImage: "url(" + require("assets/images/BackgroundCX.png") + ")",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundAttachment: "fixed",
        }}
        // className="py-5"
        data-parallax={true}
        ref={pageHeader}
      >
        <div className="overlay-cx"></div>
        <Container fluid>
          <Row className="justify-content-left">
            <Col lg={1}></Col>
            <Col lg={5}>
              {/* <h1 className='section-title-cx my-4' style={{ color: "#051245" }}>
                <b>The Whole Experience is Greater than the Sum of each</b>
              </h1> */}

              <h1 className=" section-title-cx pt-3 text-cx-g" style={{ fontSize: "3rem" }}>
                <b>ABOUT THE EVENT</b>
              </h1>

              <h3 className="text-400-cx text-dark">
                In 2024, it's more important than ever for organizations to map out and create 3 wow
                experience throughout the customer journey with their brand. With rising competition
                and a rapidly evolving global landscape, trust between consumers and brands is more
                sacred than ever. According to a recent Salesforce survey, 94% of consumers believe
                that a positive experience is 3s necessary as the actual product or service when
                making repeat purchases. And if that's not enough to convince you, 74% of consumers
                have already switched brands due to shifting loyalties.
                <br />
                <br />
                But fear not! The pandemic has accelerated the digital-first strategy. allowing
                brands to evolve alongside end-users and service providers in this era of digital
                flux. And with consumers willing to pay a premium of up to 16% for better
                experiences, the key to unlocking this opportunity is a smoother CX journey.
                <br />
                <br />
                Ready to shift from reactive to proactive? Join the 4th edition of CX Evolve Summit
                in Dubai, UAE, and discover how to anticipate consumer demands, control customer
                experiences, and drive brand loyalty. Learn to deliver value beyond personalization
                while safeguarding privacy and data integrity. Create hyper-personalized, unique,
                and socially responsible brands that build trust with consumers. Unlock the secret
                to an incredible customer experience at the upcoming CX Evolve Summit in Dubai, UAE.
                Don't miss this opportunity!
                <br />
                <br />
              </h3>
            </Col>
            <Col lg={6}>
              <div class="embed-responsive1 embed-responsive-4by3">
                <iframe
                  class="1embed-responsive-item"
                  style={{ width: '100%', height: 'auto', minHeight: '390px' }}
                  src="https://www.youtube.com/embed/ED-vjAL9NOM?autoplay=1"
                  title="CX Evolve 2023 Highlights: Unveiling the Future of Customer Experience"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col xs={11} className="mt-5">
              <Slider {...settings}>
                {content.map((c) => (
                  <a href={c.link} key={c.link}>
                    <img
                      src={require(`assets/banners/${c.image}`)}
                      width="100%"
                      // style={{ maxWidth: "728px" }}
                      alt=""
                    />
                  </a>
                ))}
              </Slider>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

const content = [
  // { link: "https://www.virenxia.com/", image: "main1.png" },
  // { link: "", image: "main2.jpg" },
  // { link: "https://allana.com/", image: "main3.jpg" },
  // { link: "https://www.tatacommunications.com/", image: "4.jpg" },
  // { link: "https://www.poly.com/ae/en", image: "5.jpg" },
  // { link: "https://bit.ly/2p0kYoJ", image: "6.png" },
  // { link: "https://www.teamviewer.com/tensor", image: "7.png" },
  // { link: "http://www.blueprism.com/", image: "8.png" },
];

export default TextGrid;
