import React from "react";

import LandingPageHeader from "components/Headers/LandingPageHeader.js";

import Footer from "components/Footer.js";
import NavbarDiv from "components/Navbar";

import WhySponsor from "components/WhySponsor";
import Sponsor from "components/Sponsor";
function RegisterPage() {
  return (
    <>
      <NavbarDiv />
      <LandingPageHeader text='Live, news-making discussions at unique in-person and virtual experiences' />
      <WhySponsor />
      <Sponsor />
      <Footer />
    </>
  );
}

export default RegisterPage;
