import React from "react";
// import ScrollAnimation from "react-animate-on-scroll";
import { Container, Row, Col, Button } from "reactstrap";

function TextGrid() {
  let pageHeader = React.createRef();
  // const size =
  //   document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024
  //     ? true
  //     : false;
  // const size = document.body.clientWidth >= 1024 || document.documentElement.clientWidth > 1024 ? true : false;
  return (
    <>
      <div
        style={{
          background: "#fff",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundAttachment: "fixed",
        }}
        className="section"
        data-parallax={true}
        ref={pageHeader}
      >
        <Container>
          <Row>
            <Col lg={6}>
              <h1 className="awards-title" style={{ fontSize: 32, textAlign: "justify" }}>
                Khaleej Times is looking for the Best Places to Work in the UAE.
                <br />
                Is your organisation one of them?
              </h1>
              <h2 className="awards-body" style={{ textAlign: "justify" }}>
                In partnership with employee experience survey and data experts, WorkL, Khaleej
                Times is on the hunt to find the very best places to work for across employee
                engagement and wellbeing.
                <br />
                <br />
                <b style={{ fontWeight: 700 }}>Why Enter?</b>
                <br />
                <br />
                <ul>
                  <li>
                    Opportunity to be recognised as a Khaleej Times Best Place to Work and help
                    attract, recruit and retain employees
                  </li>
                  <li>It’s quick - takes no more than 10 minutes to enter and budget friendly!</li>
                  <li>Receive immediate survey results and data once the survey closes</li>
                  <li>
                    Receive customised dashboard and data from global employee experience platform
                    WorkL about how your employees are feeling about your organisation, including
                    flight risk, net promoter score, employee matrices and an option to add Instant
                    Action Software™, word clouds and filtering responses.
                  </li>
                </ul>
              </h2>
            </Col>
            <Col lg={6}>
              <img src={require(`assets/images/image 114.png`)} width="100%" alt="KhaleejTimes" />
              <div class="embed-responsive embed-responsive-4by3 mt-4">
                <iframe
                  class="embed-responsive-item"
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/ZN3setXh1Ts?autoplay=1"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div
        style={{
          background: "#ffdf97",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundAttachment: "fixed",
        }}
        className="section"
        data-parallax={true}
        ref={pageHeader}
      >
        <Container>
          <Row>
            <Col lg={12}>
              <h1
                className="awards-title text-center"
                style={{ fontSize: 32, textAlign: "justify" }}
              >
                Entries close on 15<sup>th</sup> of November 2024. Don't miss out!
              </h1>
              <br />
              <h2 className="awards-body text-center" style={{ textAlign: "justify" }}>
                Find out more by booking a meeting with a dedicated member of our awards team. We'll
                guide you through the entry process, outline the benefits of participation, and
                delve into any additional inquiries you may have.
              </h2>
              <div className="d-flex justify-content-center">
                <Button
                  block
                  className="btn text-white text-700 mt-3 awards-body mx-2"
                  style={{ backgroundColor: "#6648b4", border: 0, maxWidth: 300 }}
                  size="lg"
                  target="_BLANK"
                  rel="noopener noreferrer"
                  href="https://calendar.app.google/gtXbse8QwX97tgDM9"
                >
                  <span className="awards-body">BOOK A DEMO</span>
                </Button>
                <Button
                  block
                  className="btn text-white text-700 mt-3 mx-2"
                  style={{ backgroundColor: "#6648b4", border: 0, maxWidth: 300 }}
                  size="lg"
                  target="_BLANK"
                  rel="noopener noreferrer"
                  href="https://business.workl.co/workplace-awards/khaleej-times-uae"
                >
                  <span className="awards-body">FIND OUT MORE</span>
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div
        style={{
          background: "#f9dad7",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundAttachment: "fixed",
        }}
        className="section"
        data-parallax={true}
        ref={pageHeader}
      >
        <Container>
          <Row>
            <Col lg={12}>
              <h1 className="awards-title mb-4" style={{ fontSize: 24, textAlign: "justify" }}>
                Winners will be announced in December 2024 in an exclusive supplement in Khaleej
                Times as well as a digital profile showcasing your organisation.
              </h1>
            </Col>
            <Col lg={6}>
              <img src={require(`assets/images/KTBPTW 1.png`)} width="100%" alt="KhaleejTimes" />
            </Col>
            <Col lg={6}>
              <div
                style={{ background: "#eb837a", flexDirection: "column" }}
                className="p-3 d-flex"
              >
                <h1 className="awards-title mb-4 text-white" style={{ fontSize: 24 }}>
                  Organisations have the opportunity to be recognised across the following
                  categories:
                </h1>
                <h3 className="awards-body text-white" style={{}}>
                  <li>
                    Best Places to Work:{" "}
                    <span style={{ fontWeight: 700 }}>Small Organisation (10-49 Employees)</span>
                  </li>
                  <br />
                  <li>
                    Best Places to Work:{" "}
                    <span style={{ fontWeight: 700 }}>Medium Organisation (50-249 Employees)</span>
                  </li>
                  <br />
                  <li>
                    Best Places to Work:
                    <span style={{ fontWeight: 700 }}>Big Organisation (250-1,999 Employees)</span>
                  </li>
                  <br />
                  <li>
                    Best Places to Work:{" "}
                    <span style={{ fontWeight: 700 }}>
                      {" "}
                      Very Big Organisation (2,000+ Employees)
                    </span>
                  </li>
                  <br />
                  <li>
                    Best Places to Work{" "}
                    <span style={{ fontWeight: 700 }}>for 16-23 year olds </span>
                  </li>
                  <br />
                  <li>
                    Best Places to Work <span style={{ fontWeight: 700 }}>for 55+ year olds </span>
                  </li>
                  <br />
                  <li>
                    Best Places to Work{" "}
                    <span style={{ fontWeight: 700 }}>for Employee Wellbeing </span>
                  </li>
                  <br />
                  <li>
                    Best Places to Work{" "}
                    <span style={{ fontWeight: 700 }}>for Business Sector Winners </span>
                  </li>

                  <br />
                  <br />
                  <br />
                  <span className="text-center border-top pt-4">
                    Contact{" "}
                    <span style={{ fontWeight: 700 }}>
                      ktbestplacestowork@workl.com or +44 20 4576 1730
                    </span>{" "}
                    to find out more.
                  </span>
                </h3>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default TextGrid;
// const content = [
//   {
//     title: "Overview of the current landscape of trade finance in the UAE",
//     description: "",
//   },
//   {
//     title: "Adoption rates of digital trade finance",
//     description: "",
//   },
//   {
//     title: "Challenges and opportunities for digital trade finance growth in the UAE",
//     description: "",
//   },
// ];

// const content1 = [
//   { title: "Networking with peers and industry leaders", image: "iftaar1.png" },
//   { title: "Breaking the fast with a thoughtfully curated Iftar meal", image: "iftaar3.png" },
//   { title: "A prayer break, respecting the traditions of Ramadan.", image: "iftaar2.png" },
//   {
//     title: "An opportunity to discuss the latest trends in technology and marketing.",
//     image: "iftaar4.png",
//   },
// ];
