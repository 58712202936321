import React from "react";

import { Container, Row, Col, Modal } from "reactstrap";
import SpeakerModal from "componentsCX/SpeakerModal.js";
// import ScrollAnimation from "react-animate-on-scroll";
// import Slider from "react-slick";
import "./cxevolve.css";
import { newspeakers } from "./SpeakerCard";

export const chairmans = [];
function SpeakerGrid({ speakers }) {
  // const size =
  //   document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024
  //     ? true
  //     : false;
  const [open, toggleOpen] = React.useState({
    open: false,
    speaker: {},
  });
  // const settings = {
  //   dots: true,
  //   arrows: true,
  //   autoplay: true,
  //   autoplaySpeed: 8000,
  //   slidesToShow: 2,
  //   slidesToScroll: 2,
  //   className: "p-10",
  // };

  // const settingsdesktop = {
  //   dots: true,
  //   arrows: true,
  //   autoplay: true,
  //   autoplaySpeed: 8000,
  //   slidesToShow: 5,
  //   slidesToScroll: 3,
  //   className: "p-10",
  // };

  const updateModal = (e, speaker) => {
    e.preventDefault();
    toggleOpen({ open: !open.open, speaker: speaker });
  };

  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    return function cleanup() {
      document.body.classList.remove("landing-page");
    };
  });

  // function SpeakerCard({ speaker }) {
  //   return (
  //     <a href="/" onClick={(e) => updateModal(e, speaker)}>
  //       <Container className="mb-3">
  //         <Row>
  //           <Col xs={12} className="px-0">
  //             <div className="px-3 pt-3 rounded-circle">
  //               <img
  //                 src={require(`assets/speakers/${speaker.image}`)}
  //                 width="100%"
  //                 className="rounded-circle"
  //                 alt="..."
  //                 style={{
  //                   borderRadius: 0,
  //                   maxWidth: "450px",
  //                 }}
  //               />
  //             </div>
  //           </Col>
  //           <Col xs={12} className="text-center">
  //             <h2 className="text-dark text-700-cx  mt-2">{speaker.name}</h2>

  //             <h4 className="text-400 text-muted m-0">{speaker.title}</h4>
  //             <h4 className="text-dark text-900-cx  m-0 d-block px-0">{speaker.company}</h4>
  //           </Col>
  //         </Row>
  //       </Container>
  //     </a>
  //   );
  // }

  return (
    <>
      <div
        style={{
          backgroundColor: "#fff",
        }}
        className="section"
        data-parallax={true}
      >
        <div className="filter" />
        <Container>
          <Row>
            <Col lg={12} className="">
              <h1
                className="text-center section-title-cx pt-3"
                style={{ color: "#f04c21", fontSize: "3rem" }}
              >
                <b> JUDGES</b>
              </h1>
            </Col>
          </Row>
          <Row className="mb-4 justify-content-center d-non1e">
            {newspeakers
              .filter((s) => !s.isOnlySpeaker)
              .map((chairman) => (
                <Col lg={3} className="text-center mb-4">
                  <a href="/" onClick={(e) => updateModal(e, chairman)}>
                    <img
                      src={require(`assets/speakers/${chairman.image}`)}
                      width="100%"
                      className="rounded-circle"
                      alt="..."
                      style={{
                        borderRadius: 0,
                        maxWidth: "450px",
                        objectFit: "cover",
                      }}
                    />
                  </a>
                  <div className="text-center">
                    <h2 className="text-dark text-700-cx  mt-2">{chairman.name}</h2>

                    <h4 className="text-400 text-muted m-0">{chairman.title}</h4>
                    <h4 className="text-dark text-900-cx  m-0 d-block px-0">{chairman.company}</h4>
                  </div>
                </Col>
              ))}
          </Row>
        </Container>

        <Modal isOpen={open.open} toggle={(e) => updateModal(e)} style={{}}>
          <div className="section profile-content">
            <button
              aria-label="Close"
              className="close mr-2 mt-2"
              data-dismiss="modal"
              type="button"
              onClick={(e) => updateModal(e)}
            >
              <span aria-hidden={true}>×</span>
            </button>
            <SpeakerModal speaker={open.speaker} />
          </div>
        </Modal>
      </div>
    </>
  );
}

export default SpeakerGrid;
