import React from "react";
import { Container, Row, Col, Button } from "reactstrap";
import SpeakerNew from 'componentsFreshworks/SpeakerNew.js';
import { speakers } from '../viewsFreshworks/content';

function NewGrid() {
    let pageHeader = React.createRef();
    return (
        <>
            <div className="inner" data-parallax={true} ref={pageHeader}>
                {/* <Section01 /> */}
                <Container fluid className="p-0">
                    <Row noGutters className="align-items-center">
                        <Col lg={12}>
                            <img
                                src={require("assets/images/freshworks/banner1.jpg")}
                                width="100%"
                                alt="The Freshworks Experience"
                                className="sliderDiv"
                            />
                        </Col>
                    </Row>
                </Container>
                {/* <Section02 /> */}
                <section className="aboutSection sectionPadding" id="about">
                    <Container>
                        <Row className="align-items-center">
                            <Col lg={12}>
                                <h1 className="sectionTitle">Get Ready for Hypergrowth</h1>
                            </Col>
                            <Col lg={12}>
                                <p>We warmly invite you to join us as we celebrate a game-changing milestone in our journey and commitment to the Middle East market. Here’s the big reveal - we're going live on a new cutting-edge AWS data center in Dubai! And that’s not all . Catch our Founder and Executive Chairman, Girish Mathrubootham, live, on stage as he showcases the latest and greatest innovations in Freshworks’ AI-powered customer & employee-service solutions. Be there and witness how Freshworks can increase efficiency and improve engagement for your business.</p>
                                {/* <h3>Target Group</h3>
                                <p><b>Directors and above from:</b> IT, Service Management, Digital Transformation, Customer Service & Experience, Customer Support etc.</p> */}
                            </Col>
                            <Col lg={12} className="text-center mt-5">
                                <Button href="/freshworks/register" className="btnFresh mr-3">
                                    Register To Attend
                                </Button>
                                {/* <Button href="/freshworks/download-brochure" className="btnFresh">
                                    Download Brochure
                                </Button> */}
                            </Col>
                        </Row>
                    </Container>
                </section>
                {/* <Section03 /> */}
                <section className="whySection sectionPadding">
                    <Container>
                        <Row className="align-items-center">
                            <Col lg={12}>
                                <h1 className="sectionTitle">Why Delegates Must Attend</h1>
                            </Col>
                            <Col lg={4}>
                                <div className="whyBox">
                                    <h3>Education</h3>
                                    <p>Dive into Freshworks’ latest generative AI capabilities and learn how the new data center stands to benefit your business.</p>
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="whyBox">
                                    <h3>Connection</h3>
                                    <p>Forge relationships with CS & IT leaders and gain unparalleled access to Freshworks’ executive team.</p>
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="whyBox">
                                    <h3>Inspiration</h3>
                                    <p>Reimagine the possibilities of AI-powered service and support and how it can help you uplevel your business.</p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
                {/* <Section04 /> */}
                {/* <section className="sectorSection sectionPadding">
                    <Container>
                        <Row className="align-items-center">
                            <Col lg={12}>
                                <h1 className="sectionTitle">Target Sector</h1>
                            </Col>
                            <Col lg={4}>
                                <div className="sectBox">
                                    <img src={require("assets/images/freshworks/home-icon1.png")} className="img-fluid" alt="Government" />
                                    <h3>Government</h3>
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="sectBox">
                                    <img src={require("assets/images/freshworks/home-icon2.png")} className="img-fluid" alt="Banking" />
                                    <h3>Banking</h3>
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="sectBox">
                                    <img src={require("assets/images/freshworks/home-icon3.png")} className="img-fluid" alt="Insurance" />
                                    <h3>Insurance</h3>
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="sectBox">
                                    <img src={require("assets/images/freshworks/home-icon4.png")} className="img-fluid" alt="FinTech" />
                                    <h3>FinTech</h3>
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="sectBox">
                                    <img src={require("assets/images/freshworks/home-icon5.png")} className="img-fluid" alt="Education" />
                                    <h3>Education</h3>
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="sectBox">
                                    <img src={require("assets/images/freshworks/home-icon6.png")} className="img-fluid" alt="Healthcare" />
                                    <h3>Healthcare</h3>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section> */}
                {/* <Section05 /> */}
                <section className="keySection sectionPadding">
                    <Container>
                        <Row className="align-items-center">
                            <Col lg={7}>
                                <div className="textBox">
                                    <h1>Key Takeaways</h1>
                                    <p>AI has become a game-changer in meeting the soaring expectations of customers and employees alike. Join us at this event to learn more about the innovative ways you can use AI to deliver exceptional customer and employee experiences.</p>
                                    <p className="mb-0"><b>Key Take Aways-</b></p>
                                    <ul>
                                        <li>Dive into Freshworks’ latest generative AI capabilities and uncover new ways to unlock business value with AI.</li>
                                        <li>Reimagine the possibilities of AI-powered service and support and how it can help you uplevel your business.</li>
                                        <li>Forge relationships with CS and IT leaders and gain unparalleled access to Freshworks’ executive team.</li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
                {/* <Section06 /> */}
                <section className="agendaSection sectionPadding">
                    <Container>
                        <Row className="align-items-center">
                            <Col lg={12}>
                                <h1 className="sectionTitle">Agenda</h1>
                            </Col>
                            <Col lg={12}>
                                <div className="mainBox">
                                    <div className="table-responsive">
                                        <table className="table table-bordered">
                                            <thead className="table-dark">
                                                <tr>
                                                    <th>Timings</th>
                                                    <th>Session</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>10:00 - 10:45 AM</td>
                                                    <td><b>Registration</b></td>
                                                </tr>
                                                <tr>
                                                    <td>10:45 - 10:55 AM</td>
                                                    <td><b>Welcome Note</b> by Sandie Overtveld - SVP & GM APJ & MEA - Freshworks</td>
                                                </tr>
                                                <tr>
                                                    <td>10:55 - 11:30 AM</td>
                                                    <td>
                                                        <b>Keynote</b> by Girish Mathrubootham - Founder & Executive Chairman, Freshworks<br /><br />
                                                        State of the Industry: How generative AI is transforming customer service and support
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>11:30 - 12:00 PM</td>
                                                    <td>
                                                        <b className="d-block mb-3">Customer Spotlight</b>
                                                        <ul className="CustomerSpotlight">
                                                            <li>Employee Experience value drivers featuring Chalhoub Group</li>
                                                            <li>Customer Service value drivers featuring Landmark Group</li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>12:00 - 12:15 PM</td>
                                                    <td><b>Break</b></td>
                                                </tr>
                                                <tr>
                                                    <td>12:15 - 1:00 PM</td>
                                                    <td>
                                                        <b>Customer Panel Discussion</b><br /><br />
                                                        The new standard: AI-powered experiences driving productivity and efficiency
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>1:00 - 1:05 PM</td>
                                                    <td><b>Closing Note</b></td>
                                                </tr>
                                                <tr>
                                                    <td>1:05 PM Onwards</td>
                                                    <td><b>Lunch and Networking</b></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
                {/* <Section07 /> */}
                <section className="speakersSection sectionPadding" id="speakers">
                    <Container>
                        <Row className="align-items-center">
                            <Col lg={12}>
                                <h1 className="sectionTitle text-white">2024 Speakers</h1>
                            </Col>
                        </Row>
                        <Row className="align-items-center">
                            <SpeakerNew speakers={speakers} />
                        </Row>
                    </Container>
                </section>
            </div>
        </>
    );
}

export default NewGrid;
