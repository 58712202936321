import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import ScrollAnimation from 'react-animate-on-scroll';
import CountUp from 'react-countup';

function Event() {
  const size =
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth > 1024 ? true : false;
  const Text = ({ grid, data }) => {
    return (
      <Col lg={grid} className={`${size ? 'py-5' : 'py-2'}`}>
        <ScrollAnimation animateIn={'fadeInUp'} animateOnce={true} duration={1.5}>
          <Container fluid>
            <Row
              className="text-center p-4"
              style={{ border: '3px solid #f04c21', height: size ? '220px' : '180px' }}
            >
              <Col xs={12} className="px-0">
                <h3 className={'m-0 text-700-cx text-dark'}>{data.pretitle}</h3>
              </Col>
              <Col xs={12}>
                <h1
                  className={'m-0 text-900-cx text-cx-g'}
                  style={{ color: '#000', fontSize: size ? '4rem' : '3rem' }}
                >
                  <b>
                    <small style={{ color: '#000' }}>
                      <b>{data.prefix}</b>
                    </small>

                    <CountUp end={data.value} duration={3} />

                    <small style={{ color: '#000' }}>
                      <b>{data.suffix}</b>
                    </small>
                  </b>
                </h1>
              </Col>
              <Col xs={12} className="px-0">
                <h3 className={'m-0 text-700-cx text-dark'}>{data.title}</h3>
              </Col>
            </Row>
          </Container>
        </ScrollAnimation>
      </Col>
    );
  };
  return (
    <>
      <div
        style={{
          background: '#fff',

          overflow: 'hidden',
        }}
        className="section"
        data-parallax={true}
      >
        <Container>
          <Row className="justify-content-md-left">
            <Col lg={12}>
              <div className="">
                <h1
                  className=" section-title-cx pt-3 text-center"
                  style={{ color: '#f04c21', fontSize: '3rem' }}
                >
                  <b>KEY INDUSTRY STATISTICS</b>
                </h1>
              </div>
            </Col>
            {content.map((c) => (
              <Text key={c.title} grid={4} data={c} />
            ))}
          </Row>
        </Container>
      </div>
    </>
  );
}

const content = [
  {
    pretitle: 'Global spend on CRM and Customer-centric transformation by 2027',
    title: '',
    image: '17.png',
    value: 113,
    prefix: '$',
    suffix: 'Bn',
  },
  {
    pretitle: 'CX is a top priority for',
    title: 'of GCC businesses.',
    image: '18.png',
    value: 96,
    prefix: '',
    suffix: '%',
  },
  {
    pretitle: 'Being Proactive is more critical now. ',
    title: 'of consumers expect companies to understand their requirements. ',
    image: '19.png',
    value: 64,
    prefix: '',
    suffix: '%',
  },
  {
    pretitle: 'Personalization is Expected. ',
    title: 'of consumers prefer to purchase from brands that understand their needs. ',
    image: '19.png',
    value: 70,
    prefix: '',
    suffix: '%',
  },

  {
    pretitle: 'Engaged and happy workforce = Smoother customer journey. ',
    title: 'of CxO agree engaged workforce is a competitive advantage.',
    image: '19.png',
    value: 87,
    prefix: '',
    suffix: '%',
  },
  {
    pretitle: 'Privacy concerns are deep.',
    title:
      'of customers expressed a lack of confidence in data protection systems adopted by businesses. ',
    image: '19.png',
    value: 79,
    prefix: '',
    suffix: '%',
  },
];

export default Event;
