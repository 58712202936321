import React from "react";

import { Container, Row, Col } from "reactstrap";

function WhyAttend() {
  return (
    <>
      <div
        style={{
          backgroundColor: "#201e45",
        }}
        className="section section-with-space border-top border-bottom pb-3"
        data-parallax={true}
      >
        <Container className="py-4">
          <Row>
            <Col lg={12}>
              <h1
                className="text-center section-title-cx pt-3"
                style={{ color: "#fff", fontSize: "3rem" }}
              >
                <b>KEY FOCUS AREAS</b>
              </h1>
            </Col>
          </Row>
          <Row className="justify-content-center">
            {content3.map((data, index) => (
              <Col lg={data.grid || 6} xs={12} key={index} className="py-5">
                <Container fluid>
                  <Row>
                    <Col xs={3}>
                      <img
                        src={require(`assets/images/icons/${data.image}`)}
                        width="100%"
                        style={{ maxWidth: "100px" }}
                        className="mx-auto"
                        alt="icon"
                      />
                    </Col>
                    <Col xs={9}>
                      <h2 className="text-900-cx text-uppercase text-cx-g">{data.title}</h2>
                      <h3 className="text-400-cx text-white">{data.description}</h3>
                      <h3 className="text-400-cx text-white">
                        <ul>{data.points && data.points.map((p) => <li>{p}</li>)}</ul>
                      </h3>
                    </Col>
                  </Row>
                </Container>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </>
  );
}

const content3 = [
  {
    title: "Employee Training & Empowerment",
    description:
      "Can organizations really give their front-end and customer-facing employees the knowledge and tools they need to make informed decisions? The answer is yes, my friend! By providing a 360-degree information view, companies can give their employees the power to take charge and make things happen like superheroes. So, are you ready to join the training and empowerment revolution",
    image: "58.png",
  },

  {
    title: "The constant `new normal`:",
    description:
      "It is one thing to be in the middle of a pandemic, another to be towards the endemic-perhaps more dynamic to be in the 'new normal' which embraces the digital and physical in varying degrees-the permanent hybrid. What would be the critical building blocks for strategy, roadmap and ultimately delivering the value to the unforgiving social media-connected customer",
    image: "59.png",
  },

  {
    title: "Convergence of Digital and Physical CX",
    description:
      "The fusion of digital and physical customer experience (CX) is upon us! Customers now expect top-notch service whether they're browsing online or strolling down the high street. What are the coolest brands doing to keep up with this trend, and how can they stay consistent and continually improve their game? It's time to raise the CX bar",
    image: "62.png",
  },

  {
    title: "The Metaverse",
    description:
      "Is it just a passing fad or is it the real deal? More importantly, how is it changing the game for e-commerce and customer experiences? Who are the bigwigs investing in this emerging tech, and what does the future hold? Looks like the Metaverse is here to stay, and with all the investments pouring in, we can't wait to see how it'll shape the next 3-5 years (and beyond!)",
    image: "57.png",
  },

  {
    title: "AI and Human Collaboration",
    description:
      "Investing in AI might seem like a no-brainer these days, but that's only the tip of the iceberg. Thanks to Machine Learning and AI, chat-bots are becoming smarter and more efficient by the day, and we're seeing some pretty sweet results. By combining the power of AI and human touch, we're seeing a whopping 61% increase in customer happiness! Not to mention, agent satisfaction is at a solid 69% and productivity is up by 66%. Don't just take our word for it, even the pros at Forrester agree",
    image: "56.png",
  },

  {
    title: "Social Media and Customer Experience",
    description:
      "Integrating communication technology across social media platforms allows for a smooth customer experience and greater data collection for brands. Choosing the right integration solution requires flexibility and keeping an eye on the ever-evolving digital world. The more connected the world, the bigger the opportunity and challenge in owning the customer experience",
    image: "61.png",
  },

  {
    title: "E-Commerce and WhatsApp Experience",
    description:
      "Are our customers getting over traditional apps and hopping on the WhatsApp shopping bandwagon? It seems like the WhatsApp era has got them craving lightning-fast response times, seamless resolutions, and hassle-free journeys. That means we gotta step up our game and deliver a customer experience that's not just agile, but also super empathetic",
    image: "64.png",
  },

  {
    title: "Voice of the Customer and Insights",
    description:
      "With big data and real-time insights, we can dive deeper than ever before and unlock all sorts of new opportunities to wow our customers. But don't just take our word for it - check out some real-life case studies and see if you can snag some sweet ideas to tackle those pesky friction points",
    image: "60.png",
  },

  {
    title: "Customer Experience in the Government Sector",
    description:
      "The UAE Government sector prioritizes customer experience, aiming to provide seamless and efficient services to its citizens and residents. Through digital transformation, personalized interactions, and streamlined processes, the government is committed to enhancing customer satisfaction and building a strong foundation of trust and transparency in its service delivery.",
    image: "62.png",
  },

  {
    title: "Design thinking for better CX",
    description:
      "If you wanna nail design thinking, you gotta think preemptively! That means making sure customer interactions are valuable and usable, and showing enough empathy to understand what they really want. It's all about putting the customer first, breaking down old ideas, and coming up with a fresh perspective that makes everything customer-friendly from their point of view",
    image: "63.png",
  },
];

export default WhyAttend;
