import React from "react";

import LandingPageHeader from "components/Headers/LandingPageHeader.js";

import Footer from "components/Footer.js";
import NavbarDiv from "components/Navbar";

import Events from "components/Events";
import TextGrid from "components/TextGrid";
import SpeakerGrid from "components/SpeakerCard";
import "./youtube.css";

function RegisterPage() {
  const common = {
    containment: "#bgndVideo",
    autoPlay: true,
    mute: true,
    startAt: 0,
    opacity: 1,
    loop: false,
    ratio: "4/3",
    addRaster: true,
  };
  
  let videos = [
    {
      videoURL: "vGM4NVAO30w",
      ...common,
    },
    {
      videoURL: "oacFcPy5xkE",
      ...common,
    },

    {
      videoURL: "u9k1FaMIYTs",
      ...common,
    },
  ];
  return (
    <>
      <NavbarDiv />
      <LandingPageHeader
        text="Thought provoking discussions, and unique experiences from recognized global leaders."
        videos={videos}
      />
      {/* <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/wzxvYdNOHIw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> */}
      {/* <div style={{ position: 'relative' }}>
            <iframe
              className="youtubecss"
              title="Banner"
              src="//www.youtube.com/embed/wzxvYdNOHIw?playlist=wzxvYdNOHIw&rel=0&autoplay=1&showinfo=0&controls=0&loop=1&mute=1"
              frameborder="0"
              allowfullscreen
            ></iframe>
            <div
                style={{
                  position: 'absolute',
                  top: '0px',
                  right: '0px',
                  left: '0px',
                  bottom: '0px',
                }}
            ></div>
        </div> */}
      <TextGrid />
      <Events />
      <SpeakerGrid />

      <Footer />
    </>
  );
}

export default RegisterPage;
