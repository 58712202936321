import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import Ahmad_Salama from '../assets/cx_evolve/Ahmad_Salama.jpg';

function TextGrid() {
  const size =
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth > 1024 ? true : false;
  return (
    <>
      <div
        className="section"
        style={{
          background: '#fff',
          alignItems: 'center',
          padding: size ? '2rem 0' : '3rem 0',
        }}
      >
        <Container>
          <Row>
            <Col lg={12} className="text-dark">
              <Row>
                <Col lg={8}>
                  <p>&nbsp;</p>
                  <p><em>During the CX Evolve KSA Edition, Contentsquare showcased their AI-powered Digital Experience Analytics platform. A platform that provides rich and contextual insight into customer behaviors, feelings and intent, at every touchpoint in the journey, enabling businesses to develop empathy, create lasting impact and build customer trust.<br />Website performance has always been a key factor of a good CX, last September Contentsquare also launched a Digital Experience Monitoring (DEM) capability that combines Speed Analysis Synthetic and Real User Monitoring (RUM), providing teams with the insights they need to optimize load times and speed up the customer journey. They are the first one of our category to launch a solution combining both.</em></p>
                </Col>
                <Col lg={4}>
                  <img src={Ahmad_Salama} alt="" style={{ maxWidth: '100%' }} />
                </Col>
              </Row>  
              {/* <h3 className="text-400-cx text-justify"></h3> */}
                <p>&nbsp;</p>
                <p><em>Excerpts from a meeting with Mr. Ahmad Salama, Vice President &amp; General Manager MENAT &middot; Contentsquare below:<br /><br /><strong>Can you underline the key experiences for KSA&rsquo;s changing customer behaviors?</strong></em></p>
                <p><em>Digital impacts every aspect of everyday life and the last few years have accelerated our collective reliance on online services and products. And yet customers still feel a lot of frustration when it comes to navigating websites and apps. Performance issues like slow load times, counterintuitive customer journeys and content that just isn&rsquo;t engaging enough &mdash; there are many reasons why almost half of digital customers never make it past the first page of a website. Ultimately, building a Customer Experience that will delight today&rsquo;s discerning consumer starts with understanding their intent &mdash; what they&rsquo;re trying to achieve on your website and how they want to go about it. This means factoring in all kinds of information about customer preference, including the fact that in 2023, customers are device (and even channel) agnostic, expecting seamless journeys wherever and whenever they interact with a brand.<br />Brands also need to build loyalty with existing customers AND delight and convert new ones. Ultimately the cost of keeping customers is far lower than the cost of acquiring new ones, it&rsquo;s paramount to invest in delivering great experiences beyond just the acquisition and conversion funnel. Top of mind for businesses today should be designing experiences that are seamless and fast, rewarding and consistent, trustworthy and accessible. Customers today want personalized experiences that don&rsquo;t compromise their privacy &mdash; experiences that help them achieve their goals while understanding their concerns and priorities as consumers.<br /><br />* source: Contentsquare&rsquo;s 2022 Digital Experience Benchmark<br /></em></p>
                <p><em><br /><strong>As a sponsor, can you share your experience in the CX journey and the requirements for taking KSA&rsquo;s CS to the next level?</strong></em></p>
                <p><em>To date, companies need to focus their approach on 3 key areas: to Understand customer behavior (including the revenue impact of slow speeds, technical errors, and obstacles along the customer journey), take fast and smart Action on these insights across the enterprise in a way that prioritizes revenue impact and customer happiness, and foster brand relationships based on Digital Trust, providing customers with the experiences they deserve and increase lifetime value.<br />At the end of the day, the key to a great CX is accessing a holistic understanding of customer behavior across all touchpoints and at every stage of the journey. CX is the key differentiator today for businesses &mdash; no matter the size, type or industry &mdash; and there is a pressing need for teams to contextualize behaviors in order to unlock a deeper understanding of how and why customers are interacting with the content on their site. For example, teams will be able to use AI to differentiate between a user searching for a product by clicking multiple times on a product carousel vs. a user rage clicking, &nbsp;demonstrating rising frustration. Many elements will influence whether the experience on a site or app is a positive or a negative one &mdash; demystifying these clues that customers are conveying with every touch, click, swipe, tap or scroll is key to developing an engaging experience customers will want to return to time and time again.&nbsp;</em></p>
            </Col>
          </Row>
        </Container>
      </div>
      ;
    </>
  );
}

export default TextGrid;
