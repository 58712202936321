import React from "react";
import { Col, Container, Row } from "reactstrap";

function Agenda() {

    return (
        <>
            <div style={{ backgroundColor: "#201e45" }} className="section" data-parallax={true}>
                <Container>
                    <Row>
                        <Col md="12">
                            <table className="agendaTable">
                                <tbody>
                                    <tr>
                                        <td className="pt-0">8:00 - 9:10</td>
                                        <td className="pt-0"><strong>Registration and Networking</strong></td>
                                    </tr>
                                    <tr>
                                        <td>9:10 - 9:15</td>
                                        <td><strong>Welcome Remarks</strong>
                                        <br />Michael Jabri-Pickett, Editor-in-Chief, Khaleej Times  
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>9:15 - 9:35</td>
                                        <td>
                                            <strong>Case Study & Story:</strong> How can Customer-Centric Innovation solve business 
                                            problems that traditional thinking cannot?  
                                            <ul>
                                                <li>We will explore a real-world example and show how some of the simple but incredibly powerful techniques in customer innovation solved challenges that the best-paid strategists could not.  </li>
                                                <li>How can we all take advantage of this?</li>
                                                <li>What impact and potential changes can this thinking offer an already highly competitive industry, particularly in the UAE?</li>
                                            </ul>
                                            <div>
                                                Charles Bennet, President and Co-Founder, <b>CXSA Middle East</b> 
                                                <br />&
                                                <br />Hassan Mohammad, Co-Founder & Chief Innovation Officer, <b>CXSA Middle East</b> 
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>9:40 - 10:00</td>
                                        <td>
                                            <strong>Opening Keynote:</strong> The CX Decade: Trends and Future Outlook
                                            <ul className="mt-3">
                                                <li>Crafting Empathy-driven experiences in a divided world</li>
                                                <li>Focusing on how immersive experiences and data-driven personalization are reshaping the landscape in the UAE</li>
                                                <li>Strengthening the bonds: EX and CX and impact on business goals</li>
                                            </ul>
                                            <strong>Speaker:</strong> Gagandeep Gadri, Managing Director, frog, part of Capgemini Invent
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>10:05 - 10:35</td>
                                        <td>
                                            <strong>Opening Panel:</strong> Pioneers crafting CX Evolution through Public-Private Innovations across sectors
                                            <ul className="mt-3">
                                                <li>Enhancing customer experience through AI and digital transformation</li>
                                                <li>Personalization strategies in different industries</li>
                                                <li>Cross-industry best practices for customer engagement</li>
                                            </ul>
                                            <strong>Panellist</strong>
                                            <ul className="mt-3">
                                                <li>Humaid Hassan Salem Alshamsi, Expert in CX and Government Excellence</li>
                                                <li>Himanshu Malhotra, Advisor to the Chairman, Department of Municipalities and Transport, Abu Dhabi</li>
                                                <li>Ahmed Khedr, Vice President Retail - Digital Transformation, e& UAE</li>
                                                <li>Karthik Akula, Regional Head - MENA, Freshworks</li>
                                            </ul>
                                            <strong>Moderator:</strong> Charles Bennet, President and Co-Founder, CXSA Middle East
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>10:40 - 10:55</td>
                                        <td><strong>Coffee & Networking Break</strong></td>
                                    </tr>
                                    <tr>
                                        <td>11:00 - 11:20</td>
                                        <td><strong>Dubai Drums</strong></td>
                                    </tr>
                                    <tr>
                                        <td>11:20 - 11:40</td>
                                        <td>
                                            Leveraging Technology for Enhanced Customer Experiences
                                            <br />
                                            <b>Speaker: </b>Sindhu Sreenath, Managing Director, QuestionPro MEA
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>11:45 - 12:15</td>
                                        <td>
                                            <strong>Panel Discussion:</strong> Beyond the Screen: Harnessing Technology, Nurturing Human Connections
                                            <ul className="mt-3">
                                                <li>Leadership styles within CX Spectrum</li>
                                                <li>Balancing Analytics and Empathy</li>
                                                <li>Integrating Technology with Human Touch</li>
                                                <li>Empowered Employes = Impactful CX</li>
                                            </ul>
                                            <div className="mb-3"><strong>Moderator:</strong> Raluca Berchiu, Customer Experience & Marketing Consultant</div>
                                            <strong>Panellist</strong>
                                            <ul className="mt-3 mb-0">
                                                <li>Yusra Baqi, CHRO, aafaq Islamic Finance PSC</li>
                                                <li>Eman Talaat, Head of Experience Design, Department of Government Enablement, Abu Dhabi Emirate</li>
                                                <li>Biju Nair, Assistant Director Customer Experience & Service Strategy, Ejadah Asset Management Group</li>
                                                <li>Yash Sultania, Co-Founder & CEO, Xebo.Ai</li>
                                            </ul>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>12:20 - 12:50</td>
                                        <td>
                                            <strong>Panel Discussion:</strong> Delivering seamless customer journeys across industries: Bridging Technology and Human Touch
                                            <ul className="mt-3">
                                                <li>The balance of tech and human factors in enhancing the customer experience across a range of industries and situations</li>
                                                <li>Strategies for omnichannel customer engagement</li>
                                                <li>Transformation of last-mile delivery in recent times and implications ahead</li>
                                            </ul>
                                            <strong>Panellist</strong>
                                            <ul className="mt-3">
                                                <li>Andreas Markdalen, Global Chief Creative Officer, frog, part of Capgemini Invent</li>
                                                <li>Dr. Hassa Saif Al Mazrouei, Medical Director Patient Experience, Sheikh Shakhbout Medical City - SSMC</li>
                                                <li>Ankesh Agarwal, Director, Customer Experience, Majid Al Futtaim</li>
                                            </ul>
                                            <strong>Moderator:</strong> Jihane Srour - Tehini, Co-Founder, Human2outcome
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>12:55 - 13:25</td>
                                        <td>
                                            <strong>Panel Discussion:</strong> Navigating Customer Loyalty in a VUCA World - Gen Z Leads the Charge!
                                            <ul className="mt-3">
                                                <li>Continuously personalizing customer interactions and maintaining integrity</li>
                                                <li>Striking balance of automation and human interaction</li>
                                                <li>Agility and adaptations sustaining loyalty, cutting the clutter amidst volatility, uncertainty, complexity and ambiguity</li>
                                            </ul>
                                            <strong>Panellist</strong>
                                            <ul className="mt-3">
                                                <li>Ahmed Al Zarooni, SVP, Head of Customer Services Division, Daman (National Health Insurance Company)</li>
                                                <li>Suryaveer Singh, Head Loyalty CRM and Data, ENOC</li>
                                                <li>Julia De Souza, Vice-President, Customer Experience & Operations, American Express Middle East</li>
                                                {/* <li>Mai Momani, Head of Client Experience, Invest Bank P.S.C.</li> */}
                                            </ul>
                                            <strong>Moderator:</strong> Stoyan Petrov, Middle East Lead, frog, part of Capgemini Invent
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>13:30 - 14:25</td>
                                        <td><strong>Networking Lunch</strong></td>
                                    </tr>
                                    <tr>
                                        <td>14:30 - 16:25</td>
                                        <td>
                                            <strong>Workshop:</strong> Storytelling to influence business leaders 
                                            <ul className="mt-3">
                                                <li>Build your CX influence toolkit </li>
                                                <li>Learn how to inspire using storytelling from the elevator pitch to “pumping 
                                                up” the corporate presentation </li>
                                                <li>Finding your own unique voice and style </li>
                                                <li>Tips and tricks to maximise impact </li>
                                            </ul>
                                            <strong>Workshop Leaders: </strong> Charles Bennet, President and Co-Founder, CXSA Middle East 
                                            <br />& Hassan Mohammad, Co-Founder & Chief Innovation Officer, CXSA Middle East 
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>17:00</td>
                                        <td><strong>End of the Conference</strong></td>
                                    </tr>
                                </tbody>
                            </table>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default Agenda;
