import React from "react";

import { Container, Row, Col, Modal } from "reactstrap";
import SpeakerModal from "componentsuaeIndia/SpeakerModal.js";
import ScrollAnimation from "react-animate-on-scroll";
import Slider from "react-slick";

export const chairmans = [];
function SpeakerGrid({ speakers }) {
  const size =
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024
      ? true
      : false;
  const [open, toggleOpen] = React.useState({
    open: false,
    speaker: {},
  });
  const settings = {
    dots: true,
    autoplay: true,
    autoplaySpeed: 8000,
    slidesToShow: 1,
    slidesToScroll: 1,
    className: "p-10",
  };

  const updateModal = (e, speaker) => {
    e.preventDefault();
    toggleOpen({ open: !open.open, speaker: speaker });
  };

  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    return function cleanup() {
      document.body.classList.remove("landing-page");
    };
  });

  function SpeakerCard({ speaker }) {
    return (
      <a href="/" onClick={(e) => updateModal(e, speaker)}>
        <Container className="mb-3">
          <Row>
            <Col xs={12} className="px-0">
              <div className="card-avatar px-3 pt-3 rounded-circle">
                <img
                  src={require(`assets/speakers/${speaker.image}`)}
                  width="100%"
                  className="rounded-circle"
                  alt="..."
                  style={{
                    borderRadius: 0,
                    maxWidth: "450px",
                  }}
                />
              </div>
            </Col>
            <Col xs={12} className="text-center">
              <h2 className="text-dark text-700-cx  mt-2">{speaker.name}</h2>

              <h4 className="text-400 text-muted m-0">{speaker.title}</h4>
              <h4 className="text-dark text-900-cx  m-0 d-block px-0">{speaker.company}</h4>
            </Col>
          </Row>
        </Container>
      </a>
    );
  }

  return (
    <>
      <div
        style={{
          backgroundColor: "#fff",
        }}
        className="section"
        data-parallax={true}
      >
        <div className="filter" />
        <Container>
          <Row>
            <Col lg={12} className="">
              <h1
                className="pt-3 text-meta-g meta-title text-center"
                style={{ fontSize: size ? "3rem" : "2rem" }}
              >
                <b>2022 SPEAKERS</b>
              </h1>
            </Col>
          </Row>
          <Row>
            {size ? (
              speakers.map((speaker, index) => (
                <Col className="ml-auto mr-auto px-1" lg={3} md={6} key={index}>
                  <ScrollAnimation animateIn="fadeInUp" animateOnce={true} duration={0.5}>
                    <SpeakerCard speaker={speaker} />
                  </ScrollAnimation>
                </Col>
              ))
            ) : (
              <Col xs={11} className={"mx-auto"}>
                <Slider {...settings}>
                  {speakers.map((speaker, index) => (
                    <SpeakerCard speaker={speaker} />
                  ))}
                </Slider>
              </Col>
            )}
          </Row>
          {/* <Row className='justify-content-center'>
            <Col lg={3}>
              <Button
                href='/speakers'
                className='btn d-block py-2'
                color='primary'
                size='sm'
              >
                <p className='m-0 text-700' style={{ color: "#fff" }}>
                  VIEW ALL
                </p>
              </Button>
            </Col>
          </Row> */}
        </Container>

        <Modal isOpen={open.open} toggle={(e) => updateModal(e)} style={{}}>
          <div className="section profile-content">
            <button
              aria-label="Close"
              className="close mr-2 mt-2"
              data-dismiss="modal"
              type="button"
              onClick={(e) => updateModal(e)}
            >
              <span aria-hidden={true}>×</span>
            </button>
            <SpeakerModal speaker={open.speaker} />
          </div>
        </Modal>
      </div>
    </>
  );
}

export default SpeakerGrid;
