import React from 'react';
import { Container, Row, Col } from 'reactstrap';

function TextGrid() {
  let pageHeader = React.createRef();

  return (
    <>
      <div
        style={{
          backgroundColor: '#01ffff',
          padding: '6rem 0',
        }}
        // className="py-5"
        data-parallax={true}
        ref={pageHeader}
      >
        <Container>
          <Row className="justify-content-start">
            <Col lg={12} className="my-4">
              <h1 className="section-title" style={{color: "#000b2e"}}>
                <b>Agenda at a Glance</b>
              </h1>
            </Col>
            <Col lg={10}>
              <p className="text-400 mt-4" style={{color: "#000b2e"}}>
                Forum sessions will be taking place at 9.00 AM (GMT+3) and will be an entire day of
                opportunities to capitalize on the booming trade and pave way for a brighter future
                by bringing together the most influential, dynamic and innovative figures in
                investing for in-depth explorations of the challenges and opportunities posed by a
                rapidly changing landscape
              </p>
            </Col>
            {/* <Col lg={6}>
              <img src={require('assets/images/QEF_Map_Desktop1.jpeg')} width="100%" alt="agenda" />
            </Col> */}
          </Row>
        </Container>
      </div>
    </>
  );
}

export default TextGrid;
