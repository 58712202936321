import React from "react";
import { Row, Container, Col } from "reactstrap";


// Get the current URL
const url = new URL(window.location.href);
// Parse the query string
const searchParams = new URLSearchParams(url.search);
// Example: getting a specific query parameter
const utm = searchParams.get('utm');
if (utm && utm !== '') {
  localStorage.setItem('TeamnFreshwork', utm);
}

function DemoFooter() {
  return (
    <>
      <section className="poweredSection sectionPadding">
        <Container>
          <Row id="sponsors">
            <Col lg={6}>
              <div className="poweredBox">
                <h3>Organised By</h3>
                <a href="https://khaleejtimesevents.com/" rel="noopener noreferrer" target="_blank">
                  <img src={require(`assets/images/freshworks/kt-logo.png`)} alt="" />
                </a>
              </div>
            </Col>
            <Col lg={6}>
              <div className="poweredBox">
                <h3>For more information, kindly contact</h3>
                <a href="mailto:Judel@khaleejtimes.com" rel="noopener noreferrer" className="mb-1">
                  Judel@khaleejtimes.com
                </a>
                <a href="mailto:Sanjay.kumar@freshworks.com" rel="noopener noreferrer">
                  Sanjay.kumar@freshworks.com
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="copyrightSection">
        <Container>
          <Row>
            <Col lg={3}>
              <p>
                <a href="https://www.khaleejtimes.com/privacy-notice" rel="noopener noreferrer" target="_blank">Privacy Notice</a>
                {/* |&nbsp; <a href="/sponsor/download-brochure">Brochure</a> */}
              </p>
            </Col>
            <Col lg={3}>
              <p>
                Connect With Us&nbsp;
                <a href="https://www.linkedin.com/company/khaleejtimes/mycompany/" rel="noopener noreferrer" target="_blank">
                  <i className="fa fa-linkedin" />
                </a>
              </p>
            </Col>
            <Col lg={6}>
              <p className="mb-0">
                &copy; 2024 Galadari Printing and Publishing LLC. All rights reserved.
              </p>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default DemoFooter;
