import React from "react";
import { Container, Row, Col, Card } from "reactstrap";
import ScrollAnimation from "react-animate-on-scroll";

function ContactUs() {
  const size =
    document.body.clientWidth >= 1024 ||
    document.documentElement.clientWidth > 1024
      ? true
      : false;
  return (
    <>
      <div
        className="section border-top border-bottom"
        style={{
          background: "#fbfbfb",
          alignItems: "center",
          padding: size ? "2rem 0" : "3rem 0",
        }}
      >
        <Container>
          <Row>
            <Col lg={12} className="text-center">
              <img
                src={require(`assets/images/contactus.jpeg`)}
                width="100%"
                alt="Contact us logo"
              />
            </Col>
          </Row>
          <Row className="justify-content-center">
            {content.map((data, index) => (
              <Col lg={4} xs={6} key={index} className="py-2">
                <ScrollAnimation
                  animateIn="fadeInUp"
                  animateOnce={true}
                  duration={0.5}
                >
                  <Card className="p-3 text-center">
                    <h3 className="text-700 pt-3">{data.title}</h3>
                    {data.name && <p className="text-700 pt-3">{data.name}</p>}
                    <p>
                      <i className="fa fa-envelope-o" aria-hidden="true"></i>{" "}
                      <a href={`mailto:${data.email}`}>{data.email}</a>
                    </p>
                    <p>
                      <i className="fa fa-mobile" aria-hidden="true"></i>{" "}
                      {data.phone}
                    </p>
                  </Card>
                </ScrollAnimation>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
      ;
    </>
  );
}

const content = [
  {
    title: "For Speaking opportunity and partnerships contact",
    name: "Poonam Chawla",
    email: "poonam@khaleejtimes.com",
    phone: "+971 5 0144 0703",
  },
];

export default ContactUs;
