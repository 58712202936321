import React from "react";
import Navbar from "componentsuaeIndia/Navbar.js";
import ProfilePageHeader from "componentsuaeIndia/Headers/ProfilePageHeader.js";

import Footer from "componentsuaeIndia/Footer.js";

import { Container, Row, Col } from "reactstrap";

function ThankYou({
  answer,
  match: {
    params: { id },
  },
}) {
  return (
    <>
      <Navbar />

      <ProfilePageHeader title={`THANK YOU ${answer}`} bgColor="#fff" />
      <div className="section profile-content" style={{ padding: 0 }}>
        <Container>
          <Row>
            <Col lg={12} className="text-center p-5 m-5">
              <h1>
                {(id === "REPORT" || id === "EBOOK") && (
                  <>
                    Thank you for your interest in UAE India Investment Forum. The
                    post-show report has been sent to the email address
                    provided.
                  </>
                )}
                {id === "BROCHURE" && (
                  <>
                    Thank you for your interest in UAE India Investment Forum 2022. The
                    event brochure has been sent to the email address provided,
                    please check your inbox.
                    <br />
                    In case you do not receive the brochure, please drop us a
                    line: events@khaleejtimes.com
                  </>
                )}
                {id === "REGISTER" && (
                  <>
                    Thank you for your interest in UAE India Investment Forum 2022.
                    We’ll be in touch with you for further details about the
                    event.
                  </>
                )}
                {id === "SPONSOR" && (
                  <>
                    Thank you for your interest in UAE India Investment Forum 2022.
                    We’ll be in touch with you for further details about the
                    event.
                  </>
                )}
              </h1>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </>
  );
}

export default ThankYou;
