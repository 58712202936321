import React from "react";

import { Container, Row, Col, Card } from "reactstrap";
import ScrollAnimation from "react-animate-on-scroll";

function SponsorPage({ sponsors }) {
  return (
    <>
      <div
        style={{
          backgroundColor: "#0c9fda",
        }}
        className="section section-with-space"
        data-parallax={true}
      >
        <Container>
          <Row>
            {sponsors.map((data, i) => (
              <Col lg={3} className={"ml-auto mr-auto"} key={i}>
                <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
                  <Card style={{ borderRadius: 0 }}>
                    <img
                      src={require(`assets/sponsors/${data.image}`)}
                      alt={data.name}
                      width="100%"
                    />
                  </Card>
                </ScrollAnimation>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </>
  );
}

export default SponsorPage;
