// reactstrap components
import React, { useEffect } from "react";

// import Register from "componentsBranch/Register";
import TextGrid from "componentsBranch/TextGrid";

// import NavbarDiv from "componentsBranch/Navbar";
import Header from "./Header";

function LandingPageHeader() {
  // const size = document.body.clientWidth >= 1024 || document.documentElement.clientWidth > 1024 ? true : false;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {/* <NavbarDiv /> */}
      <Header />
      <TextGrid />

      <section id="register" />
      <div
      style={{
        paddingTop: "1rem",
        backgroundColor: "#1f2852",
        // backgroundImage:
        //   "linear-gradient(90deg, rgba(234,208,241,1) 0%, rgba(205,243,248,1) 100%)",
        overflow: "hidden",
      }}
    >

      <h3 className="section-title pt-2 pb-2 text-center text-white">Registration for the event is now closed.</h3>
      </div>
      {/* <Register type="REGISTER" /> */}
    </>
  );
}

export default LandingPageHeader;
