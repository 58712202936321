import React from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { Redirect } from 'react-router-dom';
import { Alert, Button, Col, Container, Form, Input, Label, Row, Spinner } from 'reactstrap';

function Register({ type, successMsg }) {
  const urlParams = new URLSearchParams(window.location.search);
  const utm_source = urlParams.get('utm_source');
  const utm_medium = urlParams.get('utm_medium');
  const utm_campaign = urlParams.get('utm_campaign');

  const [details, setDetails] = React.useState({
    type,
    utm_source,
    utm_medium,
    utm_campaign,
  });
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [redirect, setRedirect] = React.useState(false);

  const onChangeDetails = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
  };

  const onSubmitForm = (e) => {
    e.preventDefault();
    setLoading(true);
    setError(false);
    setSuccess(false);
    // const reg = /[a-zA-Z0-9]*@[gmail|yahoo|hotmail]+\.[com|co.in]+/;
    // if (reg.test(details.email)) {
    //   setLoading(false);
    //   setError("Please enter your business email only");
    //   return null;
    // }

    fetch('https://us-central1-campaign-page.cloudfunctions.net/api/email', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(details),
    })
      .then((res) => {
        console.log(res);
        if (res.ok) return res;
        else throw new Error(res);
      })
      .then(() => {
        setLoading(false);
        setRedirect(true);
      })
      .catch((err) => {
        setLoading(false);
        setError('Something went wrong please try again');
      });
  };
  let inputs = content;
  if (redirect) {
    return <Redirect to={`/thank-you`} />;
  }

  return (
    // <Card
    //   style={{
    //     backgroundColor: "#1e16c7",
    //   }}
    // >
    <div
      style={{
        paddingTop: '1rem',
        backgroundColor: '#f3f5ff',
        // backgroundImage:
        //   "linear-gradient(90deg, rgba(234,208,241,1) 0%, rgba(205,243,248,1) 100%)",
        overflow: 'hidden',
      }}
    >
      <Container>
        <Row>
          <Col lg={12} className="align-self-center">
            <h1 className="section-title pt-4 text-center text-dark">
              <b>
                Get Notified When <br />
                Registration is Open!
              </b>
            </h1>
            <h3 className="text-center text-dark">
              Enter your details below to be notified once registration has opened. If you have any
              questions, please email us at poonam@khaleejtimes.com
            </h3>
          </Col>
          <Col className="mx-auto" lg={8}>
            <Container className="my-auto text-dark text-600 pb-5">
              {type === 'REGISTER' && <p></p>}
              <Form onSubmit={onSubmitForm} className="register-form">
                <Row className="justify-content-center text-dark">
                  <Col lg={12} className="my-2">
                    <p style={{ textTransform: 'none' }}>
                      <div className="mt-3  text-600 ">
                        <Label>Salutation*</Label>
                        <Input type="select" name="salutation" onChange={onChangeDetails} required>
                          <option value="">Select</option>
                          {salutations.map((c) => (
                            <option value={c} key={c}>
                              {c}
                            </option>
                          ))}
                        </Input>
                      </div>
                    </p>
                  </Col>
                  {inputs.map((i) =>
                    i.name !== 'phone' ? (
                      <Col lg={i.grid} className="my-2" key={i.name}>
                        <label>
                          {i.placeholder}
                          {!i.notrequired ? '*' : ''}
                        </label>
                        <Input
                          placeholder={i.placeholder}
                          type={i.type}
                          name={i.name}
                          onChange={onChangeDetails}
                          required={!i.notrequired}
                        />
                      </Col>
                    ) : (
                      <Col lg={6} className="my-2">
                        <label>Phone*</label>
                        <PhoneInput
                          country={'ae'}
                          inputProps={{
                            name: 'phone',
                            required: true,
                            autoFocus: true,
                          }}
                          inputClass="w-100"
                          dropdownClass="text-dark"
                          value={details.phone}
                          onChange={(phone) => {
                            setDetails({ ...details, phone });
                          }}
                        />
                      </Col>
                    )
                  )}

                
                  

                  <Col lg={12}>
                    {loading ? (
                      <span>
                        <Spinner className="mt-3" color="warning" size="sm" />
                      </span>
                    ) : (
                      <Button
                        block
                        className="btn text-white text-700 mt-3"
                        color="primary"
                        size="lg"
                        type="submit"
                        disabled={loading}
                      >
                        Submit
                      </Button>
                    )}
                  </Col>
                  <Col lg={12} className="text-left pt-2  text-600 ">
                    <p style={{ textTransform: 'none' }} className=" text-600 ">
                      By clicking download/submit you agree to receive further marketing information
                      from Khaleej Times Events on our series of live and digital events. Please{' '}
                      <a href="/privacy-policy" className="text-primary">
                        read our privacy policy here.
                      </a>
                    </p>
                  </Col>
                </Row>
              </Form>
            </Container>
            <CreateAlert success={success} error={error} />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

function CreateAlert({ success, error }) {
  const color = success ? 'success' : 'danger';
  const message = success ? success : error;
  if (!success && !error) {
    return '';
  } else {
    return (
      <Alert
        color={color}
        isOpen={true}
        fade={true}
        className="text-center"
        style={{ marginTop: '1rem' }}
      >
        {message}
      </Alert>
    );
  }
}

export default Register;

const content = [
  {
    id: 1,
    name: 'first_name',
    type: 'text',
    placeholder: 'First Name',
    grid: 6,
  },
  { id: 2, name: 'last_name', type: 'text', placeholder: 'Last Name', grid: 6 },
  { id: 2, name: 'phone', type: 'text', placeholder: 'Phone', grid: 6 },
  {
    id: 3,
    name: 'email',
    type: 'text',
    placeholder: 'Email',
    grid: 6,
  },
  {
    id: 8,
    name: 'company',
    type: 'text',
    placeholder: 'Company',
    grid: 6,
  },
  {
    id: 2,
    name: 'job',
    type: 'text',
    placeholder: 'Designation',
    grid: 6,
    notrequired: true,
  },
  {
    id: 6,
    name: 'questions',
    type: 'text',
    placeholder: 'Questions & Comments',
    grid: 12,
  },
];

const salutations = ['Mrs.', 'Ms.', 'Mr.', 'Dr.'];

// const countries = [
//   'Afghanistan',
//   'Albania',
//   'Algeria',
//   'Andorra',
//   'Angola',
//   'Antigua and Barbuda',
//   'Argentina',
//   'Armenia',
//   'Australia',
//   'Austria',
//   'Azerbaijan',
//   'Bahamas',
//   'Bahrain',
//   'Bangladesh',
//   'Barbados',
//   'Belarus',
//   'Belgium',
//   'Belize',
//   'Benin',
//   'Bhutan',
//   'Bolivia',
//   'Bosnia and Herzegovina',
//   'Botswana',
//   'Brazil',
//   'Brunei Darussalam',
//   'Bulgaria',
//   'Burkina Faso',
//   'Republic of Burundi',
//   'Cabo Verde',
//   'Cambodia',
//   'Cameroon',
//   'Canada',
//   'Central African Republic',
//   'Chad',
//   'Chile',
//   'China',
//   'Colombia',
//   'Comoros',
//   'Democratic Republic of Congo',
//   'Costa Rica',
//   'Croatia',
//   'Cuba',
//   'Cyprus',
//   'Czech Republic',
//   'Denmark',
//   'Djibouti',
//   'Dominica',
//   'Dominincan Republic',
//   'Egypt',
//   'El Salvador',
//   'Equatorial Guinea',
//   'Eritrea',
//   'Estonia',
//   'Eswatini',
//   'Ethiopia',
//   'Fiji',
//   'Finland',
//   'France',
//   'Gabon',
//   'Gambia',
//   'Georgia',
//   'Germany',
//   'Ghana',
//   'Greece',
//   'Grenada',
//   'Guatemala',
//   'Guinea',
//   'Guinea-Bissau',
//   'Guyana',
//   'Haiti',
//   'Holy See',
//   'Honduras',
//   'Hungary',
//   'India',
//   'Indonesia',
//   'Islamic Republic of Iran',
//   'Iraq ',
//   'Ireland',
//   'Israel',
//   'Italy',
//   "Cote d'Ivoire",
//   'Jamaica',
//   'Japan',
//   'Jordan',
//   'Kazakhstan',
//   'Kenya',
//   'Kiribati',
//   'Republic of Korea',
//   'Kosovo',
//   'Kuwait',
//   'Kyrgyzstan',
//   'Laos',
//   'Latvia',
//   'Lebanon',
//   'Lesotho',
//   'Liberia',
//   'Lithuania',
//   'Luxembourg',
//   'Madagascar',
//   'Malawi',
//   'Malaysia',
//   'Maldives',
//   'Mali',
//   'Malta',
//   'Marshall Islands',
//   'Mauritania',
//   'Mauritius',
//   'Mexico',
//   'Micronesia',
//   'Moldova',
//   'Monaco',
//   'Mongolia',
//   'Montenegro',
//   'Morocco',
//   'Mozambique',
//   'Myanmar',
//   'Namibia',
//   'Nauru',
//   'Nepal',
//   'Netherlands',
//   'New Zealand',
//   'Nicaragua',
//   'Niger',
//   'Nigeria',
//   'North Macedonia',
//   'Norway',
//   'Oman',
//   'Pakistan',
//   'Palau',
//   'Palestine',
//   'Panama',
//   'Papua New Guinea',
//   'Paraguay',
//   'Peru ',
//   'Phillipines',
//   'Poland',
//   'Portugal',
//   'Qatar',
//   'Republic of Congo',
//   'Romania',
//   'Russia',
//   'Rwanda',
//   'Saint Lucia',
//   'Samoa',
//   'San Marino',
//   'Sao Tome and Principe',
//   'Saudi Arabia ',
//   'Senegal',
//   'Serbia',
//   'Seychelles',
//   'Sierra Leone',
//   'Singapore',
//   'Slovakia',
//   'Slovenia',
//   'Solomon Islands',
//   'Somalia',
//   'South Africa',
//   'South Sudan',
//   'Spain',
//   'Sri Lanka',
//   'Saint Kitts and Nevis',
//   'St.Vincent and the Grenadines',
//   'Sudan',
//   'Suriname',
//   'Sweden',
//   'Switzerland',
//   'Syria',
//   'Tajikstan',
//   'Tanzania',
//   'Thailand',
//   'Timor-Leste',
//   'Tonga',
//   'Trinidad and Tobago',
//   'Tunisia',
//   'Turkmenistan',
//   'Tuvalu',
//   'UAE',
//   'Uganda',
//   'UK',
//   'Ukraine',
//   'Uruguay',
//   'USA',
//   'Uzbekistan',
//   'Vanuatu',
//   'Venezuela',
//   'Vietnam',
//   'Yemen',
//   'Zambia ',
//   'Zimbabwe',
// ];
