import React from "react";
import {
  // Collapse,
  Container,
  Row,
  Col,
  Card,
  CardBody,
} from "reactstrap";
import ScrollAnimation from "react-animate-on-scroll";
const size =
  document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024 ? true : false;
function Agenda() {
  function AgendaCard({ data }) {
    return data.map((d) => (
      <Col lg={6}>
        <ScrollAnimation animateIn="fadeInDown" animateOnce={true} duration={0.4}>
          <Card style={{ backgroundColor: "#373a4a", minHeight: "130px" }}>
            <CardBody>
              <Container>
                <Row>
                  <Col xs={2} className="align-self-center">
                    <h2
                      className="text-700"
                      style={{ fontSize: size ? "80px" : "60px", color: "#e13521" }}
                    >
                      {" "}
                      {d.time}
                    </h2>
                  </Col>
                  <Col xs={10} className="align-self-center text-white border-left">
                    <h2 className="text-400 text-white">{d.title}</h2>
                    {d.description && (
                      <h3
                        className="text-400 text-white"
                        dangerouslySetInnerHTML={{ __html: d.description }}
                      />
                    )}
                  </Col>
                </Row>
              </Container>
            </CardBody>
          </Card>
        </ScrollAnimation>
      </Col>
    ));
  }

  return (
    <>
      <div className="section" data-parallax={true}>
        <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
          <Container>
            <Row>
              <Col md="12">
                <h1 className="text-700 text-center mb-4 text-dark">
                  Some of the key areas of discussion during this roundtable are:
                </h1>
              </Col>
            </Row>
          </Container>
          <Container>
            <Row>
              <AgendaCard data={content1} />
            </Row>
          </Container>
        </ScrollAnimation>
      </div>
    </>
  );
}

const content1 = [
  {
    time: "1",
    title:
      "Maximizing digital customer engagements on self-service and embedded finance channels to drive growth.",
  },
  {
    time: "2",
    title:
      "Frameworks for executing business model innovation, such as Banking as a Service (BaaS), Marketplace Banking, Embedded Finance",
  },
  {
    time: "3",
    title: "Leveraging the trinity of cloud, API, and Generative AI to unlock new possibilities.",
  },
  {
    time: "4",
    title: "Building talented teams and purpose-driven culture to unlock true potential",
  },
];

export default Agenda;
