import React from "react";
import Navbar from "componentsCX/Navbar.js";
import ProfilePageHeader from "componentsCX/Headers/ProfilePageHeader.js";

import Venue from "componentsCX/Venue";

import Footer from "componentsCX/Footer.js";

function Overview() {
  return (
    <>
      <Navbar />

      <ProfilePageHeader
        title='How to get to the UAE-India Investment Forum'
        bgColor='#fff'
      />
      <Venue />

      <Footer />
    </>
  );
}

export default Overview;
