import React from 'react';
import { Row, Container, Col, Button } from 'reactstrap';
import Card from 'reactstrap/lib/Card';
function DemoFooter() {
  return (
    <>
      <div className="section border-top border-bottom" style={{ background: '#fefefe' }}>
        <Container>
          <Row>
            <Col lg={12} className="text-center">
              <h1 className="text-dark text-uppercase">
                <b>Contact Us</b>
              </h1>
              <h3 className="text-400">For all general enquiries contact:</h3>
            </Col>
          </Row>
          <Row>
            <Col lg={4} xs={12}></Col>
            {content.map((data, index) => (
              <Col lg={4} xs={12} key={index} className="py-2">
                <Card className="p-3 text-center">
                  <h3 className="text-700 pt-3">{data.title}</h3>
                  <div>
                    <p>
                      <i className="fa fa-envelope-o" aria-hidden="true"></i>{' '}
                      <a href={`mailto:${data.link}`}>{data.link}</a>
                    </p>
                  </div>
                </Card>
              </Col>
            ))}
            <Col lg={4} xs={12}></Col>
          </Row>
        </Container>
      </div>
      <footer
        className="footer pt-5 py-3"
        style={{
          backgroundColor: '#f3faff',
          backgroundImage: 'linear-gradient(to bottom, #fff, #e2f2ff)',
        }}
      >
        <Container fluid>
          <Row className="justify-content-center py-5">
            <Col lg={4} className="py-2 text-center">
              <a href="https://www.khaleejtimes.com/">
                <img
                  src={require(`assets/images/logo/khaleejdark.png`)}
                  style={{ maxWidth: '300px' }}
                  width="100%"
                  alt="KhaleejTimes"
                />
              </a>
            </Col>
            <Col lg={4} className="text-center py-2 align-self-center">
              <h3 className="d-inline">
                <a href="https://www.khaleejtimes.com/privacy-notice">Privacy Notice</a>
                &nbsp;&nbsp;|&nbsp;&nbsp;
                <a href="/cookie-policy">Cookie Policy</a>
              </h3>
            </Col>

            <Col lg={4} className="text-center align-self-center">
              <h3 className="mb-0">Connect With Us</h3>
              {social.map((s) => (
                <Button
                  className="btn-neutral btn-just-icon m-1 p-1"
                  style={{ color: '#1d1960' }}
                  href={s.link}
                >
                  <i className={`fa fa-${s.name}`} />
                </Button>
              ))}
            </Col>
          </Row>
          <hr />
          <Row>
            <Col xs={12} className={'text-center'}>
              <p>@ 2021-23 Galadari Printing and Publishing LLC. All rights reserved.</p>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
}
const content = [{ title: '', link: 'events@khaleejtimes.com' }];

export default DemoFooter;

const social = [
  {
    name: 'facebook',
    link: 'https://www.facebook.com/khaleejtimes/?locale=en_US',
  },
  {
    name: 'linkedin',
    link: 'https://www.linkedin.com/company/khaleejtimes/mycompany/?viewAsMember=true',
  },
  {
    name: 'twitter',
    link: 'https://twitter.com/khaleejtimes',
  },
  { name: 'instagram', link: 'https://www.instagram.com/khaleejtimes/' },
  {
    name: 'youtube',
    link: 'https://www.youtube.com/c/khaleejtimes',
  },
];
