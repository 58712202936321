import React from "react";
import Navbar from "componentsCX/Navbar.js";
import ProfilePageHeader from "componentsCX/Headers/ProfilePageHeader.js";
import Agenda from "componentsCX/Agenda";

import Footer from "componentsCX/Footer.js";
// import Testimonials from "componentsCX/Testimonials.js";

function AgendaPage() {
  return (
    <>
      <Navbar />

      <ProfilePageHeader title="AGENDA" bgColor="#fff" />
      <div className="section profile-content" style={{ padding: 0 }}>
        <Agenda />
      </div>
      {/* <Testimonials /> */}
      <Footer />
    </>
  );
}

export default AgendaPage;
