import React from "react";

import { Container, Row, Col } from "reactstrap";
// import ScrollAnimation from "react-animate-on-scroll";
import Slider from "react-slick";
function Testimonials() {
  const settings = {
    dots: true,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    className: "p-10",
  };

  return (
    <>
      <div
        style={{
          background: "#27368b",
          overflow: "hidden",
        }}
        className="section section-with-space"
        data-parallax={true}
      >
        <Container>
          <Row>
            <Col xs={12}>
              <div className="">
                {/* <h1 className="section-title text-center text-white">
                  <b>HEAR WHY INDUSTRY LEADERS PARTICIPATE</b>
                </h1> */}
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col lg={1} className="text-center">
              {/* <img
                src={require("assets/images/icons/quote.png")}
                style={{ maxWidth: "75px" }}
                alt="quote"
              /> */}
            </Col>
            <Col xs={10} className="text-center ">
              <Slider {...settings}>
                {content.map((c) => (
                  <>
                    <br />
                    <br />
                    <h2
                      className="text-white text-700"
                      dangerouslySetInnerHTML={{ __html: c.testimonial }}
                    />
                    <br />
                    <br />
                    <h3 className="text-white text-800">{c.by}</h3>
                  </>
                ))}
              </Slider>
            </Col>
            <Col lg={1} className="text-center align-self-end">
              {/* <img
                src={require("assets/images/icons/quoteend.png")}
                style={{ maxWidth: "75px" }}
                alt="quote"
              /> */}
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

const content = [
  {
    testimonial: `
    Who will be the winners and losers in the rapid evolution in global payments and
    banking? I am thrilled to be discussing the future of payments with an expert panel at
    Digipay 2021.
	`,
    by: `Ben Lloyd, Head of Digital Product, Banque Saudi Fransi`,
  },
  {
    testimonial: `
    Payments are key moments in the different journeys experienced by Alshaya customers.  I am
    excited to join the 2021 edition of DIGIPAY and look forward to discussing – together with a
    community of payments experts - the challenges and opportunities around payments
    acceptance and payments processing for modern retailers in a post-Covid era.   Building a
    payment proposition around the customer experience requires effecting handling of every
    payment journey; from offering relevant payment facilities to payment safety and security,
    growth from 3rd parties customers, payment related costs and finally customer insight from
    Payment data.  The DIGIPAY2021 summit aims at discussing and engaging on all those
    strategic points and I look forward to joining the event.
	`,
    by: "Francois Orhan, Director - Payment, Loyalty & Data, Alshaya Group",
  },
  {
    testimonial: `
    As more and more cashless payment options are adopted in the Middle East, as retailers we must ensure
    we’re giving our customers the fastest, and easiest methods of payment during their shopping journey.
    This means regularly evaluating and introducing new options at the checkout, online and offline.
    [eg. Apple Pay, Ali Pay and Buy Now, pay later]<br/>
    Cashless payments are ever evolving, and with the rise of alternative currencies like Crypto, and new
    innovative wallets being developed in the FinTech space, we do not anticipate our journey into
    alternative payment methods ending anytime soon.<br/>
    Attending Digipay 2021 helps us keep on top of the new innovations on the market, that can be
    leveraged in our business.
    `,
    by: "Hesham Al Amodi, Group CEO, Kamal Osman Jamjoom Group LLC",
  },
];

export default Testimonials;
