import React from "react";
import Navbar from "componentsWomen/Navbar.js";
import ProfilePageHeader from "componentsWomen/Headers/ProfilePageHeader.js";
import Register from "componentsWomen/Register";
import Footer from "componentsWomen/Footer.js";
// import Sponsor from "componentsWomen/Sponsor.js";
// import { sponsorsPage } from "./content";
import { Helmet } from "react-helmet";

function RegisterPage() {
  return (
    <>
      <Helmet>
        <meta name="description" content="Event registration form" />
      </Helmet>

      <Navbar />
      <ProfilePageHeader title="REGISTER" />
      <div className="section profile-content" style={{ padding: 0 }}>
        <Register type="REGISTER" />
      </div>

      <Footer />
    </>
  );
}

export default RegisterPage;
