// reactstrap componentsuaeIndia
// import { Container, Col, Row } from "reactstrap";

import React from "react";

function LandingPageHeader() {
  const size =
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024
      ? true
      : false;

  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (document.documentElement.scrollTop > 50 || document.body.scrollTop > 50) {
      } else if (document.documentElement.scrollTop < 300 || document.body.scrollTop < 300) {
      }
    };

    window.addEventListener("scroll", updateNavbarColor);

    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });

  return (
    <>
      <div
        className={size ? "page-header" : "py-0 my-0"}
        // ref={myRef}
        data-parallax={true}
      >
        <img
          src={require(`assets/images/${size ? "BG.png" : "BG2.png"}`)}
          width="100%"
          alt="KhaleejTimes"
        />

        <br />
      </div>
    </>
  );
}

export default LandingPageHeader;
