import React from "react";
import {
  // Collapse,
  Container,
  Row,
  Col,
  Card,
  CardBody,
} from "reactstrap";
import ScrollAnimation from "react-animate-on-scroll";

function Agenda() {
  function AgendaCard({ data }) {
    return data.map((d) => (
      <Row key={d.time}>
        <Col lg={2}></Col>
        <Col lg={8}>
          <ScrollAnimation animateIn="fadeInDown" animateOnce={true} duration={0.4}>
            <Card style={{ backgroundColor: "#0025c8" }}>
              <CardBody>
                <Container>
                  <Row>
                    <Col lg={4} className="align-self-center">
                      <h2 className="text-900 text-white mb-0">{d.time}</h2>
                    </Col>
                    <Col lg={8} className="align-self-center border-left">
                      <h2 className="text-500 text-white mb-0">{d.title}</h2>
                      {d.description && (
                        <h3
                          className="text-500"
                          dangerouslySetInnerHTML={{ __html: d.description }}
                        />
                      )}
                    </Col>
                  </Row>
                </Container>
              </CardBody>
            </Card>
          </ScrollAnimation>
        </Col>
        <Col lg={2}></Col>
      </Row>
    ));
  }

  return (
    <>
      <div
        style={{ backgroundColor: "#f3f5ff" }}
        className="section"
        data-parallax={true}
      >
        <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
          <Container>
            <Row>
              <Col md="12">
                <h1 className="text-center mb-5" style={{ color: "#0025c8", fontSize: "2.5rem" }}>
                  <b>AGENDA</b>
                </h1>
              </Col>
            </Row>
          </Container>
          <Container>
            <AgendaCard data={content1} />
          </Container>
        </ScrollAnimation>
      </div>
    </>
  );
}

const content1 = [
  {
    time: "10.30 - 11.00",
    title: "Registration and Networking",
    description: ``,
  },
  {
    time: "11.00 - 11.10",
    title: "Welcome note by Khaleej Times",
  },
  {
    time: "11.10 - 11.20",
    title: "Keynote Address by Exotel - Consumer trends & Market Overview",
  },
  {
    time: "11.20 - 12.10",
    title: "Roundtable Engagement - Building Uncompromised and connected customer engagement journeys across the enterprise.",
  },
  {
    time: "12.10 - 12.25",
    title: "Customer Session",
  },
  {
    time: "12.25 - 12.30",
    title: "Closing Note by Khaleej Times",
  },
  {
    time: "",
    title: "Networking Lunch Thereafter.",
  },
];

export default Agenda;
