import React from 'react';

import { Container, Row, Col } from 'reactstrap';

function WhyAttend() {
  return (
    <>
      <div
        style={{
          backgroundColor: '#ffffff',
        }}
        className="section section-with-space border-top border-bottom py-0 pb-3"
        data-parallax={true}
      >
        <Container className="py-4">
          <Row>
            <Col lg={12}>
              <h1 className="text-center section-title pt-3" style={{ color: '#051245' }}>
                <b>Summit At A Glance</b>
              </h1>
            </Col>
          </Row>
          <Row className="justify-content-start">
            {content3.map((data, index) => (
              <Col lg={6} xs={6} key={index} className="py-5">
                <Container fluid>
                  <Row>
                    <Col xs={3}>
                      <img
                        src={require(`assets/images/icons/${data.image}`)}
                        width="100%"
                        style={{ maxWidth: '100px' }}
                        className="mx-auto"
                        alt="icon"
                      />
                    </Col>
                    <Col xs={9}>
                      <h3>{data.title}</h3>
                      <p className='text-400'>{data.description}</p>
                    </Col>
                  </Row>
                </Container>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </>
  );
}

const content3 = [
  {
    title: 'Start-up Ecosystems',
    description:
      'India is the hub for the start-ups. UAE is a hub for expats and global businesses. The intersection of countries is a petri dish for untapped potential, unused resources, immense business opportunities. Let us draw a blueprint for a two-way start-up ecosystem that creates new jobs, promotes foreign direct investment, empowers entrepreneurs',
    image: '54.png',
  },
  {
    title: 'Bilateral Food Security',
    description:
      'The environmental, and geo-political and socio-economic effects of the pandemic and how investors are driving international and domestic investment in economic and utilities resilience. The need for food security is at its peak - Leaders will gather to highlight how to build inclusive, resilient, efficient and sustainable food ecosystems.',
    image: '53.png',
  },

  {
    title: 'Future & Infrastructure',
    description:
      'Right from Real Estate, Smart Cities, Convention centers, to Technologically driven ports and warehouses – UAE and India have witnessed an influx of investments. Thought leaders shall formulate steps towards a comprehensive strategic partnership facilitating investments and bilateral trade in the infrastructure sector.',
    image: '55.png',
  },

  {
    title: 'Digitally Transformed',
    description:
      'Last 2 years have been significant to digital transformation across the world. We take a deeper look at changes in potential collaboration, investment and benefits due to the digitally shrunk world.',
    image: '52.png',
  },
  {
    title: 'Energy Innovation Landscape',
    description:
      'Clean energy is the jargon for coming years given the rise of global warming and climate change. Net zero and government environment imperatives are set to dominate investment decisions for years to come. We explore the opportunities between the UAE and India on ways to form a mutual collaboration to pave the way for a sustainable future',
    image: '51.png',
  },
];

export default WhyAttend;
