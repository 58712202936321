import React from "react";
import {
  Col,
  Container,
  // Collapse
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
// import Button from "reactstrap/lib/Button";

function Agenda() {
  const [activeTab] = React.useState("1");
  // const toggle = (tab) => {
  //   if (activeTab !== tab) {
  //     setActiveTab(tab);
  //   }
  // };

  function AgendaCard({ data }) {
    return data.map((d, index) => (
      <Container className="p-4 border-bottom">
        <Row>
          <Col lg={2} className="align-self-center">
            <h1 className="text-400 mb-0 text-meta-g meta-title">
              <b>{d.time}</b>
            </h1>
          </Col>
          <Col lg={10} className="align-self-center text-dark">
            <h2 className="meta-title m-0">{d.title}</h2>
            {d.description && (
              <h3
                className="text-400-cx text-dark"
                dangerouslySetInnerHTML={{ __html: d.description }}
              />
            )}
          </Col>
        </Row>
      </Container>
    ));
  }

  return (
    <>
      <div
        style={{
          backgroundColor: "#fff",
        }}
        className="section"
        data-parallax={true}
      >
        <Container>
          <Row>
            <Col lg={12}>
              <h1
                className="text-meta-g meta-title section-title pt-3"
                style={{ borderTop: "5px solid #d2027e" }}
              >
                <b>AGENDA</b>
              </h1>
              <h2 className="text-700 text-white">(Gulf Standard Time)</h2>
            </Col>
            <Col md="10">
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  <Container>
                    <Row>
                      <Col lg={12} className="px-0">
                        <AgendaCard data={content1} />
                      </Col>
                    </Row>
                  </Container>
                </TabPane>
              </TabContent>
            </Col>
            {/* <Col lg={12} className="align-self-center text-center mt-3">
              <Button
                className="px-3 py-2 rounded-0"
                color="info"
                style={{ minWidth: '200px' }}
                href="/cx-evolve2022/brochure"
              >
                <p className="m-0 text-700" style={{ color: '#fff' }}>
                  Request Full Agenda
                </p>
              </Button>
            </Col> */}
          </Row>
        </Container>
      </div>
    </>
  );
}

const content1 = [
  {
    time: "09.00",
    title: "Networking & Registration",
  },
  {
    time: "09.20",
    title: "Welcome by Khaleej Times",
  },
  {
    time: "09.30",
    title: "Opening Keynote Address ",
    description: "Dr. Marwan Al Zarouni, Director of Information Services at Dubai Electronic Security Center. A Digital Dubai Entity.",
  },
  {
    time: "09.50",
    title: "PANEL: The Metaverse Explained ",
    description: `
    How do I become a part of it? <br/>
  What benefits do I get from it? <br/>
  Which Metaverse is right for me? <br/><br/>
  Panelists<br/>
Dr. Marwan Al Zarouni, Director of Information Services at Dubai Electronic Security Center. A Digital Dubai Entity.<br/>
Leila Hurstel, Chief Metaverse Officer, VerseEstate<br/>
Sebastien Borget, Co-Founder, Sandbox Metaverse Platform<br/><br/>
Moderator<br/>
Maria Vovchok, Ambassador at Blockchain Association of Ukraine.
    `,
  },
  {
    time: "10.30",
    title: "Introducing experiential commerce – Magnati MetaV ",
    description: `
    Speaker<br/>
Ramana Kumar, Chief Executive Officer, Magnati 
    `,
  },
  {
    time: "10.50",
    title: "PANEL: Masters of the Metaverse ",
    description: `
    Dubai Metaverse Strategy<br/> 
   Metaverse Technology as a regulated industry<br/>
   How should Governments prepare for the Metaverse? <br/><br/>
   Panelists<br/>
Gaurav Dubey, Chief Executive Officer, TdeFi<br/>
Musfir Khawaja, Co-Founder, nftONE<br/><br/>
Mehdi Cherif, Co-Founder & Chief Executive Officer, Pulse<br/>
Moderator<br/>
Kokila Alagh, Founder of KARM Legal Consultants<br/>
    `,
  },
  {
    time: "11.20",
    title: "Networking Break",
    description: ``,
  },
  // {
  //   time: "11.45",
  //   title: "Presentation: HoloLens 2 – Blending of physical and digital",
  //   description: `Speaker<br/>
  //   Juned Attar, Marketing Manager – Strategy, Redington Value`,
  // },
  {
    time: "11.45",
    title: "KT METAVERSE LAUNCH ",
    description: `Speaker<br/>
    Sohail Nawaz, Chief Digital Officer, Khaleej Times`,
  },
  {
    time: "12.05",
    title: "PANEL: Fight The FOMO - Retail & Metaverse",
    description: `
    What metaverse means for brands and branding<br/>
    How brands can strategize for the metaverse<br/>
    Future of fashion and retail - Metaverse? <br/><br/>
    Panelists<br/>
    Patrick Moritz, Founder, Dubai Peeps<br/>
    Luca Lamberti, Chief Executive Officer, 99starz<br/>
    Umer Farooq, MD & Head of Payment Platforms, Magnati<br/><br/>
    Moderator<br/>
    Melanie Mohr, Chief Executive Officer & Founder, WOM Protocol 
    `,
  },
  {
    time: "12.45",
    title: "Presentation: Zoom Up – Launching an NFT ",
    description: `
    Speaker<br/>
    Herve Larren, Founder and Chief Executive Officer, Airvey.io
   
    `,
  },
  {
    time: "13.00",
    title: "Networking Lunch ",
    description: `
   
    `,
  },
  {
    time: "14.00",
    title: "Fireside Chat: The (virtual) reality of Healthcare",
    description: `
    Will Metaverse in healthcare transform the industry<br/>
    Amazing possibilities of healthcare in the Metaverse<br/>
    Digital health Metaverse: Data, ethics and management <br/><br/>
    Speakers<br/>
    Latifa AbuZanjal, Acting Director of Digital Health & Performance Department, Emirates<br/>
Health Services Establishment<br/>
Jane Thomason, Section Chief Editor, Journal of Metaverse<br/><br/>
Moderator<br/>
Renata Liuzzi, Founder & CEO, Axis Partners
    `,
  },
  {
    time: "14.30",
    title: "Roundtable discussions",
    description: `
    Keep it 100 - Onboarding Gen X to meet Gen Z<br/>
    Musfir Khawaja, Co-Founder, nftONE<br/><br/>
    Don't play by the rules ! Gaming in the Metaverse<br/>
    Faisal Zaidi, Co-Founder and CMO, Crypto Oasis
    `,
  },
  {
    time: "15.00",
    title: "CLOSING KEYNOTE",
    description: `
    Learn from the master - Musings of an early adapter 
    `,
  },
  // {
  //   time: "15.20",
  //   title: "Performance by a Metaverse artist ",
  //   description: `
    
  //   `,
  // },
  {
    time: "15.30",
    title: "End of event ",
    description: `
    
    `,
  },
];

export default Agenda;
