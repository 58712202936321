import React from "react";
import Navbar from "componentsCX/Navbar.js";
import ProfilePageHeader from "componentsCX/Headers/ProfilePageHeader.js";

import Footer from "componentsCX/Footer.js";
import { Container } from "reactstrap";
// import Testimonials from "componentsCX/Testimonials.js";

function RefundCX() {
  return (
    <>
      <Navbar />
      <ProfilePageHeader title="Cancellation & Refund Policy" bgColor="#fff" />
      <div className="section profile-content" style={{ padding: 0 }}>
        <Container>
          <p>Event Cancellation &amp; Refund Policy</p>
          <p>
            <br />
          </p>
          <p>Cancellation by us</p>

          <p>
            Khaleej Times reserves the right to modify or cancel any event if unforeseen
            circumstances arise. If we cancel an event, we shall inform you as soon as possible
            using the contact details provided to us and offer you a full refund.
          </p>
          <p>
            <br />
          </p>
          <p>Cancellation by you</p>

          <p>
            Payment for registration will always be required and must be made prior to the event. If
            sufficient written notification of cancellation is received, a 50% refund will be given.
            If insufficient notice is given, there will be no refund.&nbsp;
          </p>
          <p>
            <br />
          </p>
          <p>ALL CANCELLATIONS MUST BE IN WRITING. (events@khaleejtimes.com)</p>

          <p>Prior to 15 working days &ndash; 50% refund</p>
          <p>14 &ndash;0 working days before start of event &ndash; no refunds &nbsp;</p>
          <p>(Khaleej Times Working days: - Monday &ndash; Friday)</p>
          <p>
            <br />
          </p>
          <p>
            Due to financial obligations incurred by Khaleej Times, no refunds or credits will be
            issued on cancellation requests less than 14 working days prior to the start of the
            event.&nbsp;
          </p>

          <p>
            By registering for an event, you have confirmed that you have read and understood our
            cancellation policy.
          </p>
        </Container>
      </div>
      <Footer />
    </>
  );
}

export default RefundCX;
