import React from "react";

import Footer from "components/Footer.js";
import NavbarDiv from "components/Navbar";
import Reports from "components/Reports";
import ProfilePageHeader from "components/Headers/ProfilePageHeader";
function RegisterPage() {
  return (
    <>
      <NavbarDiv />
      <ProfilePageHeader title="POST SHOW REPORTS"/>
      <Reports />

      <Footer />
    </>
  );
}

export default RegisterPage;
