import React from "react";
import Navbar from "componentsCX/Navbar.js";
import ProfilePageHeader from "componentsCX/Headers/ProfilePageHeader.js";
import Register from "componentsCX/Register";
import Footer from "componentsCX/Footer.js";
// import Sponsor from "componentsCX/Sponsor";
// import { sponsorsPage } from "./content";

function RegisterPage() {
  return (
    <>
      <Navbar />
      <ProfilePageHeader title="Sponsorship Enquiry" bgColor="#fff" />
      
      <div className="section profile-content" style={{ padding: 0 }}>
        <Register type="SPONSOR" />
      </div>
      <Footer />
    </>
  );
}

export default RegisterPage;
