import React from "react";
import { Container, Row, Col, Button } from "reactstrap";
import SpeakerNew from 'componentsWomen/SpeakerNew.js';
import SpeakerPast from 'componentsWomen/SpeakerPast.js';
import { speakers, pastspeakers } from '../viewsWomen/content';

function NewGrid() {
    let pageHeader = React.createRef();
    return (
        <>
            <div style={{}} data-parallax={true} ref={pageHeader}>
                {/* <Section01 /> */}
                <Container fluid className="p-0">
                    <Row noGutters className="align-items-center">
                        <Col lg={12}>
                            <img
                                src={require("assets/images/new/slide-1.jpg")}
                                width="100%"
                                alt="Woman Board of Directors"
                                className="sliderDiv"
                            />
                        </Col>
                    </Row>
                </Container>
                {/* <Section02 /> */}
                <section className="possibilitiSec sectionPadding" id="about">
                    <Container fluid>
                        <Row className="align-items-center">
                            <Col lg={12}>
                                <h1 className="sectionTitle">The Possibilities Are Endless</h1>
                            </Col>
                            <Col lg={6}>
                                <p><b>The Middle East Women Board of Directors Summit takes place in Dubai on the April 2023. It is not a gender-specific platform - the focus is on network building, information sharing and personal development of business leadership ecosystems.</b></p>
                                <p>While the number of senior leadership positions occupied by women in the GCC is increasing rapidly, much more needs to be done.</p>
                                <p>What holistic and sustainable steps and policies can increase the number of women on regional boards? How best can public and private sectors facilitate and support women on this journey? What approaches are needed to accelerate diversity and identify and develop female leaders?</p>
                                <p>Board appointments are the result of being public about accomplishments and networks, therefore initiatives that support candidacy for a board seat are essential. It means going beyond mentoring programs for women to investing in on-going career development.</p>
                                <p>The Middle East Women Board of Directors Summit not only provides practical, easily implementable solutions to these challenges, it also enables networking with professionals from a variety of disciplines including manufacturing, banking, pharma, engineering, media, sales, and government.</p>
                            </Col>
                            <Col lg={6}>
                                <div class="embed-responsive embed-responsive-4by3">
                                    <iframe
                                        class="embed-responsive-item"
                                        width="560"
                                        height="315"
                                        src="https://www.youtube.com/embed/74nZ00wIHOQ?autoplay=1"
                                        oldsrc="https://www.youtube.com/embed/_79AJ3vm46Y?autoplay=1"
                                        title="YouTube video player"
                                        frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowfullscreen>
                                    </iframe>
                                </div>
                            </Col>
                            <Col lg={12} className="text-center mt-5">
                                <Button href="/women-directors/register" className="btnNew mr-3">
                                    Register Interest To Attend
                                </Button>
                                <Button href="/women-directors/sponsor/download-brochure" className="btnNew">
                                    Download Brochure
                                </Button>
                            </Col>
                        </Row>
                    </Container>
                    <div className="shapeOne">
                        <img src={require("assets/images/new/shape1.png")} alt="" />
                    </div>
                </section>
                {/* <Section03 /> */}
                <section className="objectivSec sectionPadding">
                    <Container>
                        <Row className="align-items-center">
                            <Col lg={12}>
                                <h1 className="sectionTitle text-white">Objectives Of The Summit</h1>
                            </Col>
                            <Col lg={6}>
                                <div className="innerBox">
                                    <h3>01</h3>
                                    <p className="mb-0"><b>To increase the representation of women on boards of directors</b> in local entities across the public and private sectors.</p>
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="innerBox">
                                    <h3>02</h3>
                                    <p className="mb-0"><b>To increase female representation</b> on boards of directors by 2026.</p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
                {/* <Section04 /> */}
                <section className="diplomatSec sectionPadding">
                    <Container fluid>
                        <Row className="align-items-center">
                            <Col lg={12}>
                                <h1 className="sectionTitle">Uae Female Diplomats</h1>
                                <p>Women make up 30% of the diplomatic corps and occupy 234 positions.<br />
                                    42 of these women work on missions abroad and 7 of them are ambassadors.</p>
                            </Col>
                            <Col lg={4}>
                                <div className="innerBox">
                                    <img src={require("assets/images/new/diplomat-img1.png")} alt="" />
                                    <div>
                                        <h3>He Lana Nusseibeh</h3>
                                        <p>UAE Permanent Representative to the UN</p>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="innerBox">
                                    <img src={require("assets/images/new/diplomat-img2.png")} alt="" />
                                    <div>
                                        <h3>He Hafsa Al Ulama</h3>
                                        <p>UAE Ambassador to Brazil</p>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="innerBox">
                                    <img src={require("assets/images/new/diplomat-img3.png")} alt="" />
                                    <div>
                                        <h3>He Noora Mohammed Juma</h3>
                                        <p>UAE Ambassador to Finland</p>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="innerBox">
                                    <img src={require("assets/images/new/diplomat-img4.png")} alt="" />
                                    <div>
                                        <h3>He Fatima Khamis Al Mazrouei</h3>
                                        <p>He Fatima Khamis Al Mazrouei</p>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="innerBox">
                                    <img src={require("assets/images/new/diplomat-img5.png")} alt="" />
                                    <div>
                                        <h3>He Hanan Al Alili</h3>
                                        <p>UAE Ambassador to Latvia</p>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="innerBox">
                                    <img src={require("assets/images/new/diplomat-img6.png")} alt="" />
                                    <div>
                                        <h3>He Nabila Al Shamsi</h3>
                                        <p>Consul General of the UAE to Hong Kong</p>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4}></Col>
                            <Col lg={4}>
                                <div className="innerBox">
                                    <img src={require("assets/images/new/diplomat-img7.png")} alt="" />
                                    <div>
                                        <h3>He Dr. Hessa Abdullah Al Otaiba</h3>
                                        <p>UAE Ambassador to Spain</p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                    <div className="shapeTwo">
                        <img src={require("assets/images/new/shape2.png")} alt="" />
                    </div>
                </section>
                {/* <Section05 /> */}
                <section className="steplightSec sectionPadding">
                    <Container>
                        <Row className="align-items-center">
                            <Col lg={12}>
                                <h1 className="sectionTitle text-white">Step Into The Light</h1>
                                <p>Khaleej Times passionately believes in developing outstanding female leaders and promoting gender diversity, both at our firm and in the Middle East at large. We invite you to take the next step in your own leadership journey through our Middle East Women Board of Directors initiative.</p>
                                <p>Are you searching for advice to help you determine the next steps in your career, expand your network, grow personally, get involved in the community, and balance it all successfully? Join us for empowering sessions led by Khaleej Times and partner top female executives who will provide you with actionable steps and tools to achieve success in all areas of your life - professionally, personally, and civically.</p>
                            </Col>
                            <Col lg={4}>
                                <div className="innerBox">
                                    <div className="imgBox">
                                        <img src={require("assets/images/new/steplight-icon1.png")} alt="" />
                                    </div>
                                    <p>Chief Diversity Officers, VPs, Directors, and Managers</p>
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="innerBox Padd100">
                                    <div className="imgBox">
                                        <img src={require("assets/images/new/steplight-icon2.png")} alt="" />
                                    </div>
                                    <p>Diversity Practitioners</p>
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="innerBox">
                                    <div className="imgBox">
                                        <img src={require("assets/images/new/steplight-icon3.png")} alt="" />
                                    </div>
                                    <p>Corporate Diversity Council Members</p>
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="innerBox Padd100">
                                    <div className="imgBox">
                                        <img src={require("assets/images/new/steplight-icon4.png")} alt="" />
                                    </div>
                                    <p>HR VPs, Directors and Managers</p>
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="innerBox">
                                    <div className="imgBox">
                                        <img src={require("assets/images/new/steplight-icon5.png")} alt="" />
                                    </div>
                                    <p>Diversity Change Management Agents</p>
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="innerBox">
                                    <div className="imgBox">
                                        <img src={require("assets/images/new/steplight-icon6.png")} alt="" />
                                    </div>
                                    <p>People Managers, Line Managers and Business Unit Leaders</p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                    <div className="shapeThree">
                        <img src={require("assets/images/new/shape3.png")} alt="" />
                    </div>
                </section>
                {/* <Section06 /> */}
                <section className="steplightSecTwo sectionPadding">
                    <Container>
                        <Row className="align-items-center">
                            <Col lg={4}>
                                <div className="innerBox">
                                    <h3>Female leaders</h3>
                                    <p className="mb-0">in public and private sectors</p>
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="innerBox">
                                    <h3>C-suite leaders</h3>
                                    <p className="mb-0">in the private sector</p>
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="innerBox">
                                    <h3>Government officials</h3>
                                    <p className="mb-0">and decision makers from leading organizations</p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
                {/* <Section07 /> */}
                <section className="speakersSec sectionPadding" id="speakers">
                    <Container>
                        <Row className="align-items-center">
                            <Col lg={12}>
                                <h1 className="sectionTitle d-none">This 2022 speakers Speakers</h1>
                                <h1 className="sectionTitle">2022 speakers</h1>
                            </Col>
                        </Row>
                        <Row className="align-items-center">
                            <SpeakerNew speakers={speakers} />
                        </Row>
                    </Container>
                </section>
                {/* <Section08 /> */}
                <section className="speakersSec pastSpeakers sectionPadding">
                    <Container>
                        <Row className="align-items-center">
                            <Col lg={12}>
                                <h1 className="sectionTitle">Past Speakers</h1>
                            </Col>
                        </Row>
                        <Row className="align-items-center">
                            <SpeakerPast pastspeakers={pastspeakers} />
                        </Row>
                    </Container>
                </section>
                {/* <Section09 /> */}
                <section className="agendaSec sectionPadding">
                    <Container>
                        <Row className="align-items-center">
                            <Col lg={12}>
                                <h1 className="sectionTitle text-white">An Inspiring Agenda</h1>
                            </Col>
                            <Col lg={12}>
                                <div className="agendaBox">
                                    <h3>Registration and Networking</h3>
                                </div>
                                <div className="agendaBox">
                                    <h3>Welcome Note</h3>
                                    <p>Poonam Chawla, Co-founder Middle East Women Board of Directors, Head of Events and Conferences, Khaleej Times</p>
                                </div>
                                <div className="agendaBox">
                                    <h3>Opening Note</h3>
                                    <p>Ebru Tuygun, Chairwoman of the Advisory Board, Middle East Women Board of Directors, Accenture Regional CMO</p>
                                </div>
                                <div className="agendaBox">
                                    <h3>Opening Keynote Presentation</h3>
                                    <p>Empowering women by increasing their representation on the Boards of Directors</p>
                                </div>
                                <div className="agendaBox">
                                    <h3>Panel Discussion</h3>
                                    <p>Women Power Talk: Achieving gender balance in the boardrooms</p>
                                </div>
                                <div className="agendaBox">
                                    <h3>Networking and Coffee Break</h3>
                                </div>
                                <div className="agendaBox">
                                    <h3>Panel Discussion</h3>
                                    <p>How Executive Headhunters play a key role in increasing the number of women in the boardrooms</p>
                                </div>
                                <div className="agendaBox">
                                    <h3>Panel Discussion - Ceo Panel</h3>
                                    <p>Getting on Board and Empowering Women in Leadership</p>
                                </div>
                                <div className="agendaBox">
                                    <h3>Inspirational Keynote by Stanford Graduate School of Business Executive Education</h3>
                                    <p>Prof. Jennifer Aaker - Why humor is a secret weapon in business and life</p>
                                </div>
                                <div className="agendaBox">
                                    <h3>Inspiring Women, Lasting Impressions</h3>
                                </div>
                                <div className="agendaBox">
                                    <h3>Recognition And Celebrations - 'Getting On Board' Excellence Award Ceremony</h3>
                                </div>
                                <div className="agendaBox">
                                    <h3>Media Interviews And Networking Dinner</h3>
                                </div>
                            </Col>
                            <Col lg={12} className="text-center mt-5">
                                <Button href="/women-directors/sponsor/download-brochure" className="btnNew">
                                    Request Full Agenda
                                </Button>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </div>
        </>
    );
}

export default NewGrid;
