// reactstrap components
import React, { useEffect } from "react";

// import Register from "componentscloud4c/Register";
import TextGrid from "componentscloud4c/TextGrid";
import Agenda from "componentscloud4c/Agenda";
// import Sponsor from "components/Sponsor";
// import SpeakerGrid from "componentscloud4c/SpeakerCard";
import NavbarDiv from "componentscloud4c/Navbar";
import Header from "./Header";

function LandingPageHeader() {
  // const size = document.body.clientWidth >= 1024 || document.documentElement.clientWidth > 1024 ? true : false;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <NavbarDiv />
      <Header />
      <TextGrid />
      <section id="agenda" />
      <Agenda />
      {/* <section id="speakers" />
      <SpeakerGrid /> */}
      <section id="register" />
      <div
      style={{
        paddingTop: "1rem",
        backgroundColor: "#1f2852",
        height:'20vh',
        // backgroundImage:
        //   "linear-gradient(90deg, rgba(234,208,241,1) 0%, rgba(205,243,248,1) 100%)",
        overflow: "hidden",
      }} className="d-flex justify-content-center align-items-center text-white"
    >

      <h3 className="section-title pt-2 pb-2 text-center text-white">Registration for the event is now closed.</h3>
      </div>
      {/* <Register type="REGISTER" /> */}
    </>
  );
}

export default LandingPageHeader;
