import React from "react";
import Navbar from "componentsInfosysNew/Navbar.js";
import Register from "componentsInfosysNew/Register";
import Footer from "componentsInfosysNew/Footer.js";
import { Helmet } from "react-helmet";
import { Container, Row, Col } from "reactstrap";

function RegisterPage() {
  return (
    <>
      <Helmet>
        <meta name="description" content="Event registration form" />
      </Helmet>

      <div className="freshworks">
        <div className="inner">
          <Navbar />
          <section className="aboutSection" style={{ marginTop: '120px' }}>
            <Container>
              <Row className="align-items-center">
                <Col lg={12}>
                  <h1 className="sectionTitle">REGISTER</h1>
                </Col>
              </Row>
            </Container>
          </section>
          <div className="section profile-content" style={{ padding: 0 }}>
            <Register type="REGISTER" />
          </div>

          <Footer />
        </div>
      </div>
    </>
  );
}

export default RegisterPage;
