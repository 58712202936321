import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { Container, Row, Col } from "reactstrap";

const content1 = [
  { title: "Networking with peers and industry leaders", image: "iftaar1.png" },
  { title: "Breaking the fast with a thoughtfully curated Iftar meal", image: "iftaar3.png" },
  { title: "A prayer break, respecting the traditions of Ramadan.", image: "iftaar2.png" },
  {
    title: "An opportunity to discuss the latest trends in technology and marketing.",
    image: "iftaar4.png",
  },
];

function TextGrid() {
  let pageHeader = React.createRef();
  const size =
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024
      ? true
      : false;
  // const size = document.body.clientWidth >= 1024 || document.documentElement.clientWidth > 1024 ? true : false;
  return (
    <>
      <div
        style={{
          background: "#1f2852",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundAttachment: "fixed",
        }}
        className="section py-4"
        data-parallax={true}
        ref={pageHeader}
      >
        <Container>
          <Row>
            <Col lg={12}>
              <h1
                className="text-700  text-white text-center"
                style={{ fontSize: size ? "2rem" : "2rem" }}
              >
                27 March 2024
                <br />
                Fairmont Riyadh, Saudi Arabia
              </h1>
            </Col>
          </Row>
        </Container>
      </div>
      <div
        style={{
          backgroundImage: "url(" + require("assets/images/23873.jpg") + ")",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundAttachment: "fixed",
        }}
        className="section"
        data-parallax={true}
        ref={pageHeader}
      >
        <Container>
          <Row>
            <Col lg={12}>
              <h1
                className="text-center section-title text-900"
                style={{ color: "#373a4a", fontSize: size ? "30px" : "20px" }}
              >
                Join us for an evening of spirituality and networking, bringing together senior
                decision-makers across mobile and app marketing, growth, product management,
                performance marketing, CRM, and digital transformation. This intimate gathering aims
                to foster meaningful connections and insights among the leaders shaping our
                industry.
              </h1>
            </Col>
          </Row>
        </Container>

        <Container className="mt-5">
          <Row className="mt-5">
            <Col lg={12}>
              <h1
                className="text-center section-title text-900"
                style={{ color: "#373a4a", fontSize: "50px" }}
              >
                <b>Event Highlights</b>
              </h1>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row className="text-700 justify-content-center" style={{ marginTop: "1rem" }}>
            {content1.map((s, index) => (
              <Col key={index} lg={3} xs={6} className="text-center p-4">
                <ScrollAnimation animateIn={"fadeInDown"} animateOnce={true} duration={1.5}>
                  <img src={require(`assets/images/icons/${s.image}`)} width="80" alt="icon" />

                  <h3 className="text-dark text-700 mt-2">{s.title}</h3>
                </ScrollAnimation>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </>
  );
}

export default TextGrid;
// const content = [
//   {
//     title: "Overview of the current landscape of trade finance in the UAE",
//     description: "",
//   },
//   {
//     title: "Adoption rates of digital trade finance",
//     description: "",
//   },
//   {
//     title: "Challenges and opportunities for digital trade finance growth in the UAE",
//     description: "",
//   },
// ];

