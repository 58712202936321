// reactstrap components
import { Container, Col, Row, Button } from 'reactstrap';
import React from 'react';

function LandingPageHeader({ home }) {
  // const size = document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024 ? true : false;

  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (document.documentElement.scrollTop > 50 || document.body.scrollTop > 50) {
      } else if (document.documentElement.scrollTop < 300 || document.body.scrollTop < 300) {
      }
    };

    window.addEventListener('scroll', updateNavbarColor);

    return function cleanup() {
      window.removeEventListener('scroll', updateNavbarColor);
    };
  });

  return (
    <div
      style={{
        backgroundImage: 'url(' + require('assets/images/header-bg.jpg') + ')',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        overflow: 'hidden',
      }}
      className="page-header"
    >
      <div
        style={{
          background:
            'linear-gradient(rgba(232, 237, 255, 0.9), rgba(239, 254, 255, 0.9) 50%, rgba(254, 255, 244, 0.3) 68%, rgba(255, 255, 255, 0.12))',
        }}
        className="page-header"
        data-parallax={true}
      >
        {/* <Anime /> */}
        <Container>
          <Row className="justify-content-center">
            <Col md={8} xs={12} className="text-center">
              <h1
                style={{
                  fontSize: '35px',
                  lineHeight: '50px',
                  color: '#1e295d',
                  fontWeight: '600',
                }}
              >
                Changing Landscape of Tax <br />
                Compliances & Audit Framework in UAE
              </h1>
              <br/>
              <h3
                style={{
                  color: '#121212',
                  fontWeight: '400',
                }}
              >
                MBG Corporate Services and Khaleej Times are organizing a Webinar on "Changing
                Landscape of Tax Compliances & Audit frameworks in UAE".
              </h3>
              <br/>
              <h2
                style={{
                  color: '#6c6c6c',
                  fontWeight: '700',
                }}
              >
                January 26, 2022 <b>11:00 AM</b> in Dubai
              </h2>
            </Col>

            <Col md={7} xs={12} className="mb-1 mt-3" style={{ zIndex: 1000 }}>
              <Container fluid>
                <Row className="justify-content-center">
                  <Col lg={12} className="text-center">
                    <Button
                      className=" px-5 py-3"
                      color="info"
                      style={{ 
                        borderRadius: '50px',
                        backgroundColor: '#1e2a5e',
                        color: "#fff",
                       }}
                      href="/"
                    >
                      <p className="m-0 text-700" style={{ color: '#fff' }}>
                        Register Now
                      </p>
                    </Button>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default LandingPageHeader;
