import React from "react";

function SpeakerModal({ speaker }) {
  if (speaker && speaker.image) {
    return (
      <>
        <div className="boxHead">
          <img src={require("assets/images/freshworks/popupHead.jpg")} width="100%" alt="" />
        </div>
        <div className="boxOne">
          <img src={require(`assets/speakers/freshworks/${speaker.image}`)} width="100%" alt="" />
          <div className="textBox">
            <h3>{speaker.name}</h3>
            <h4>{speaker.title}</h4>
            <h5>{speaker.company}</h5>
          </div>
        </div>
        <div className="boxTwo">
          {speaker.text.map((t) => (
            <>
              <p
                key={t}
                dangerouslySetInnerHTML={{ __html: t }}
                className="mb-0"
              />
            </>
          ))}
        </div>
      </>
    );
  } else return "";
}

export default SpeakerModal;
