import React from 'react';
import Navbar from 'componentsFreshworks/Navbar.js';
import Footer from 'componentsFreshworks/Footer.js';
import NewGrid from 'componentsFreshworks/NewGrid.js';

function Home() {
  document.documentElement.classList.remove('nav-open');
  React.useEffect(() => {
    document.body.classList.add('profile-page');
    return function cleanup() {
      document.body.classList.remove('profile-page');
    };
  });

  return (
    <>
      <div id="outer-container" className="freshworks">
        <Navbar home={true} />
        <section id="home" />
        <main id="page-wrap">
          <NewGrid />
          <Footer />
        </main>
      </div>
    </>
  );
}

export default Home;
