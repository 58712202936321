export const speakers = [
  {
    name: 'Girish Mathrubootham',
    title: 'Founder & Executive Chairman - Freshworks',
    company: 'Freshworks',
    image: 'girish-mathrubootham.jpg',
    text: [
      `https://www.linkedin.com/in/girish1/`
    ],
  },
  {
    name: 'Sandie Overtveld',
    title: 'Sr Vice President & General Manager - APJ & MEA',
    company: 'Freshworks',
    image: 'sandie-overtveld.jpg',
    text: [
      `https://www.linkedin.com/in/sandie/`
    ],
  },
  {
    name: 'Phil Leonard',
    title: 'Director Tech Operations',
    company: 'Chalhoub Group',
    image: 'phil-leonard.jpg',
    text: [
      `https://www.linkedin.com/in/phil-leonard-7492099/`
    ],
  },
  {
    name: 'Angad Singh',
    title: 'Global Director, Innovation',
    company: 'Aramex',
    image: 'angad-singh.jpg',
    text: [
      `https://www.linkedin.com/in/angadsinghprofile/`
    ],
  },
  {
    name: 'Mustehsan Siddique',
    title: 'Sr Manager-CX',
    company: 'Landmark Group',
    image: 'mustehsan-siddique.jpg',
    text: [
      `https://www.linkedin.com/in/mustehsan-siddiqui-71a6a750/`
    ],
  },
  {
    name: 'Mohamed Tolba',
    title: 'SVP- Global Solutions',
    company: 'Majid Al Futtaim',
    image: 'Mohamed-Tolba.jpg',
    text: [ `https://www.linkedin.com/in/mohamed-tolba-cpa-47b99745/` ],
  },

  
  {
    name: 'Sundar Raman',
    title: 'Director Technology',
    company: 'Museum of the Future',
    image: 'Sundar-Raman.jpg',
    text: [ `https://www.linkedin.com/in/cybertoast/` ],
  },
  {
    name: 'Peter Ayad',
    title: 'Digital Product Manager',
    company: 'MAGRABi',
    image: 'Peter-Ayad.png',
    text: [ `https://www.linkedin.com/in/peter-ayad-377625104/` ],
  },
  {
    name: 'Ann Erhahon',
    title: 'Digital Contact Center',
    company: 'Atlantis Resorts',
    image: 'Ann-Erhahon.jpeg',
    text: [ `https://www.linkedin.com/in/ann-erhahon-16545768/` ],
  },
  {
    name: 'Wojciech Bajda',
    title: 'Managing Director Public Sector MEA',
    company: 'Amazon Web Services',
    image: 'Wojciech-Bajda.png',
    text: [ `https://www.linkedin.com/in/wojciech-bajda-06ab30/` ],
  },
  // {
  //   name: 'Ben Crampton',
  //   title: 'Technology Portfolio Lead',
  //   company: 'Commercial Bank of Dubai',
  //   image: 'ben-crampton.jpg',
  //   text: [
  //     `https://www.linkedin.com/in/ben-crampton-5421b33b/`
  //   ],
  // },
];

export const news = [];
