import React from "react";
import { Container, Row, Col } from "reactstrap";

function GALLERYPhoto() {
  // const all_photo1 = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  // const all_photo2 = [13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];
  const size =
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth > 1024 ? true : false;
  return (
    <>
      <div
        className="section"
        style={{
          background: "#fff",
          alignItems: "center",
          padding: size ? "2rem 0" : "3rem 0",
        }}
      >
        <Container>
          <Row>
            {content.map((single) => (
              <Col md={3}>
                <img
                  className="mb-4"
                  key={single}
                  alt=""
                  src={require(`assets/cxPhotos/${single}`)}
                  style={{ width: "100%", objectFit: "contain" }}
                />
              </Col>
            ))}
          </Row>
        </Container>
      </div>
      ;
    </>
  );
}

export default GALLERYPhoto;
const content = [
  "BO2A4371.jpg",
  "BO2A4395.jpg",
  "BO2A4419.jpg",
  "BO2A4440.jpg",
  "BO2A4477.jpg",
  "BO2A4506.jpg",
  "BO2A4546.jpg",
  "BO2A4600.jpg",
  "BO2A4613.jpg",
  "BO2A4614.jpg",
  "BO2A5736.jpg",
  "BO2A5754.jpg",
  "BO2A5830.jpg",
  "BO2A5860.jpg",
  "BO2A5884.jpg",
  "BO2A5890.jpg",
  "BO2A5931.jpg",
  "BO2A5942.jpg",
  "BO2A6013.jpg",
  "BO2A6036.jpg",
  "BO2A6087.jpg",
  "BO2A6090.jpg",
  "BO2A6108.jpg",
  "BO2A6140.jpg",
  "BO2A6223.jpg",
  "BO2A6266.jpg",
  "BO2A6351.jpg",
  "BO2A6394.jpg",
  "DSC_5752.jpg",
  "DSC_5797.jpg",
  "DSC_5874.jpg",
];
