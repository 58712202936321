import React from "react";

import { Container, Row, Col, Card } from "reactstrap";

import ScrollAnimation from "react-animate-on-scroll";
import Slider from "react-slick";

const speakers = [
  {
    name: "Vinod Kumar",
    title: "Director Of Technology",
    company: "Eltizam Asset Management Group",
    image: "VinodKumar.jpg",
    text: [`A proptech industry leader with 17 years of experience in spearheading Digital Transformation, Business Applications & Change Management projects across real estate organizations.  He is educated as a software engineer with a global experience as a program manager topped with an MBA from University of Wollongong, cumulatively. This equips him with a set of niche skills and competencies. He likes driving change through research based technology planning, development of digital platforms and delivery of insights to enable data-driven decision making. He is passionate about developing innovative solutions enabled by cutting edge technology to help both startups as well as mature enterprises in the real estate industry.`],
  },
  {
    name: "Sachin Bhatia",
    title: "Co-founder",
    company: "Exotel ",
    image: "SachinBhatia.jpg",
    text: [`As the Chief Growth Officer at Exotel, Sachin Bhatia has been leading enterprise sales and marketing that pursues and cultivates new business opportunities and has led the company to acquire a global footprint with a presence in over 60 countries.`],
  },
  {
    name: "Tharanath Rai",
    title: "COO",
    company: "Al-Fardan Exchange ",
    image: "Tharanath_Rai.jpg",
    text: [`With an MBA degree from SMU Manipal and with senior management accountabilities, Tharanath aims to significantly increase growth, profitability, efficiencies and customer value in his current role as Chief Operating Officer at Al-Fardan Exchange.`],
  },
  {
    name: "Pankaj Kewlani",
    title: "CEO",
    company: "The Golden Crown",
    image: "Pankaj_Kewlani.jpg",
    text: [`Result-oriented professional with dynamic growth and demonstrated history of working in the e-learning industry. Skilled in Sales & Marketing, City Marketing, Territory expansion, Data Analysis, Customer Relationship Management (CRM), and Sales Management. A strong business development professional with a Bachelor of Engineering (BE) in Electronics and Telecommunication Engineering.`],
  },
  {
    name: "Zaid Mubarak",
    title: "Marketing and Digital Products Manager",
    company: "Serve U LLC",
    image: "Zaid_Mubarak.jpg",
    text: [``],
  },
  {
    name: "Dominick Keenaghan",
    title: "Owner",
    company: "Insights Middle East",
    image: "Dominick_Keenaghan.jpg",
    text: [`RIn 1994, founded a Dubai-based company called INSIGHTS focused on helping businesses to dramatically improve their results through better customer interactions and service.`],
  },
  {
    name: "Maya Omeiri",
    title: "Partner, Head of Experience Management Advisory",
    company: "New Metrics",
    image: "Maya_Omeiri.jpg",
    text: [`Accomplished Executive with 17 years of international experience in transaction banking sales and business strategy.

    Talented in developing partnerships, overseeing personnel, and growing new markets to meet strategic goals across financial institutions and governments public sector.
    
    Proven leader with significant background in delivering growth through relationship cultivation, innovation and building scale and efficiency.
    `],
  },
  {
    name: "Binzad Aliar",
    title: "Global Sales Head",
    company: "Exotel",
    image: "Binzad_Aliar.jpg",
    text: [`Binzad is VP of global sales at Exotel. He has been with Exotel for over 9 years and has handled new and emerging markets in the Middle East, APAC, and European market. He has over 20 years of experience across consulting, sales in customer experience, and the contact center domain. Prior to joining Ameyo, he has worked with some of the leading brands like Avaya, Dell Cisco and Aspect.`],
  },
];

function SpeakerGrid() {
  const size =
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024
      ? true
      : false;
  const [open, toggleOpen] = React.useState({
    open: false,
    speaker: {},
  });
  const settings = {
    dots: true,
    autoplay: true,
    autoplaySpeed: 8000,
    slidesToShow: 1,
    slidesToScroll: 1,
    className: "p-10",
  };

  const updateModal = (e, speaker) => {
    e.preventDefault();
    toggleOpen({ open: !open.open, speaker: speaker });
  };

  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    return function cleanup() {
      document.body.classList.remove("landing-page");
    };
  });

  function SpeakerCard({ speaker }) {
    return (
      <a href="/" onClick={(e) => updateModal(e, speaker)}>
        <Card
          className="card-background-image mx-auto rounded-0"
          style={{
            borderRadius: 0,
            boxShadow: "none",
            // background: "transparent",
          }}
        >
          <Container fluid>
            <Row>
              <Col xs={12} className="px-0">
                <img
                  src={require(`assets/speakers/exotel2023/${speaker.image}`)}
                  alt="speaker"
                  width="100%"
                  className="rounded-0"
                />
              </Col>

              <Col xs={12} className="px-0">
                <div className="text-center">
                  <p className="text-800 mt-3 mb-0" style={{ fontSize: "18px" }}>
                    {speaker.name}
                  </p>
                  <p className="text-400 mt-1 mb-0" style={{ fontSize: "14px" }}>
                    {speaker.title}
                  </p>
                  <p className="text-600 mt-2 mb-0 d-block" style={{ fontSize: "14px" }}>
                    {speaker.company}
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </Card>
      </a>
    );
  }

  return (
    <>
      <div
        style={{
          backgroundColor: "#fff",
        }}
        className="section"
        data-parallax={true}
      >
        <div className="filter" />
        <Container>
          <Row>
            <Col lg={12} className="">
              <h1 className="text-center mb-5" style={{ color: "#0025c8", fontSize: "2.5rem" }}>
                <b>SPEAKERS</b>
              </h1>
            </Col>
            {size ? (
              speakers.map((speaker, index) => (
                <Col className="ml-auto mr-auto px-1" lg={3} md={6} key={index}>
                  <ScrollAnimation animateIn="fadeInUp" animateOnce={true} duration={0.5}>
                    <SpeakerCard speaker={speaker} />
                  </ScrollAnimation>
                </Col>
              ))
            ) : (
              <Col xs={11} className={"mx-auto"}>
                <Slider {...settings}>
                  {speakers.map((speaker, index) => (
                    <SpeakerCard key={index} speaker={speaker} />
                  ))}
                </Slider>
              </Col>
            )}
          </Row>
        </Container>
      </div>
    </>
  );
}

export default SpeakerGrid;
