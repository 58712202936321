// reactstrap components
import React, { useEffect } from "react";

import Register from "componentsExotel/Register";
import TextGrid from "componentsExotel/TextGrid";
import Agenda from "componentsExotel/Agenda";
// import Sponsor from "components/Sponsor";
import SpeakerGrid from "componentsExotel/SpeakerCard";
import NavbarDiv from "componentsExotel/Navbar";
import Header from "./Header";

function LandingPageHeader() {
  // const size = document.body.clientWidth >= 1024 || document.documentElement.clientWidth > 1024 ? true : false;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <NavbarDiv />
      <Header />
      <TextGrid />
      <section id="agenda" />
      <Agenda />
      <section id="speakers" />
      <SpeakerGrid />
      <section id="register" />
      <Register type="REGISTER" />
    </>
  );
}

export default LandingPageHeader;
