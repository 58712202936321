import React from 'react';
import { Container, Row, Col } from 'reactstrap';

function TextGrid() {
  let pageHeader = React.createRef();

  return (
    <>
      <div
        style={{
          backgroundColor: '#000b2e',
          padding: '6rem 0',
        }}
        // className="py-5"
        data-parallax={true}
        ref={pageHeader}
      >
        <Container>
          <Row className="justify-content-start">
            <Col lg={12} className="my-4">
              <h1 className="section-title text-white" style={{ color: '#051245' }}>
                <b>Attendees</b>
              </h1>
              <h3 className="text-400 text-white">
                UIIF is definitive platform to discuss and evaluate the India & UAE growth and
                economic story
              </h3>
            </Col>
            <Col lg={8}>
              <h1 className="text-white text-400" style={{ fontSize: '4rem' }}>
                100%{' '}
              </h1>
              <h3
                className="text-500 text-white pb-4"
                style={{
                  borderBottom: '12px solid transparent',
                  borderImage: 'linear-gradient(106deg, rgba(1,255,255,1) 0%, rgba(0,11,46,1) 59%)',
                  borderImageSlice: 1,
                }}
              >
                top decision makers in attendance
              </h3>

              <p className="text-400 mt-4 text-white">
                The event brings together over 250+ delegates from India, United Arab Emirates and
                other key markets. The delegate profile includes Ministers of Economy, Industry, and
                Finance; heads of Investment in SWF; top banks and financial institutions; CXOs of
                top companies and family owned businesses.
                <br />
                <br />
                30+ Speakers
                <br />
                <ul>
                  <li>Ministers</li>
                  <li>Heads of state</li>
                  <li>Global CEOs </li>
                  <li>Leading investors</li>
                  <li>Unicorns & entrepreneurs</li>
                  <li>C-level executives, corporate decision makers from across industries</li>
                  <li>Investors and analysts from leading banks</li>
                </ul>
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default TextGrid;
